import React from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Badge,
  Divider,
  Image,
  Indicator,
  NumberFormatter,
  Skeleton
} from '@mantine/core';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import ProductLineItemAddonsDisplay from '../products/ProductLineItemAddonsDisplay';
import { ECOM_ORDER_PRODUCT_STATUS_ENUM } from '../../../config/constants';
import { formatUtcDate } from '../../../helpers/format';

const VendorOrderProductPreview = ({
  product,
  loading,
  quantityIndicatorAmount,
  showVendorName,
  showPrice,
  showTransferInfo,
  showParentOrder,
  hideStatus,
  hideInputAddons,
  warningMessage,
  showCancelWarnings,
  showCustomerInfo
}) => {
  const isFulfilled = product && product.shippedCount >= product.quantity;

  return !loading && product ? (
    <AppFlexbox style={{ gap: 8 }}>
      <Indicator
        disabled={!quantityIndicatorAmount}
        label={quantityIndicatorAmount}
        radius={100}
        size={15}
        style={{ zIndex: 1 }}
      >
        <AppStack
          component={product.to ? Link : null}
          style={{
            width: 50,
            height: 50,
            overflow: 'hidden',
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #e1e1e1'
          }}
          to={product.to ? product.to : null}
        >
          <Image fit="contain" h="100%" src={product.previewImage} w="100%" />
        </AppStack>
      </Indicator>
      <AppStack style={{ gap: 0 }}>
        <AppStack style={{ gap: 0 }}>
          <AppFlexbox
            component={product.to ? Link : null}
            style={{ color: '#000', textDecoration: 'none' }}
            to={product.to ? product.to : null}
          >
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox style={{ gap: 8 }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {product.name}
                </AppText>
                {!hideStatus && (
                  <Badge
                    color={isFulfilled ? 'green' : 'grey'}
                    variant={isFulfilled ? 'filled' : 'light'}
                  >
                    {product.shippedCount} /{' '}
                    {product.quantity - product.cancelledCount}
                  </Badge>
                )}
              </AppFlexbox>

              {showVendorName && (
                <AppText
                  style={{ fontSize: 12, color: '#666', fontWeight: 500 }}
                >
                  {product.vendorName}
                </AppText>
              )}

              <ProductLineItemAddonsDisplay
                addOns={product.addOns.filter(
                  (f) => !hideInputAddons || !f.isInputAddon
                )}
              />

              {(product.sku || product.barcode) && (
                <>
                  <Divider style={{ margin: '4px 0px' }} />
                  {product.sku && (
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      SKU: {product.sku}
                    </AppText>
                  )}
                  {product.barcode && (
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      BARCODE: {product.barcode}
                    </AppText>
                  )}
                </>
              )}

              {showPrice && (
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  style={{ fontSize: 14 }}
                  thousandSeparator
                  value={product.unitCost / 100}
                />
              )}
            </AppStack>
          </AppFlexbox>
          {product.ecomOrderProductBulk &&
            product.ecomOrderProductBulk.length > 0 && (
              <>
                <Divider style={{ margin: '4px 0px' }} />
                <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
                  <AppText style={{ fontSize: 12, color: '#666' }}>
                    Group order:
                  </AppText>
                  {product.ecomOrderProductBulk
                    .reduce((r, c) => {
                      if (
                        r.every((s) => s.fkEcomOrder !== c.fkEcomOrder) &&
                        c.fkEcomOrderProductStatus.toString() !==
                          ECOM_ORDER_PRODUCT_STATUS_ENUM.TRANSFERRED
                      ) {
                        r.push(c);
                      }
                      return r;
                    }, [])
                    .map((b) => (
                      <Anchor
                        key={b.pkEcomOrderProductBulk}
                        c="dodgerblue"
                        component={Link}
                        style={{ fontSize: 14 }}
                        to={`${b.to}?from=${
                          product.ecomOrder.isBulkShipment
                            ? 'group-orders'
                            : 'orders'
                        }&fromId=${product.ecomOrder.pkEcomOrder}`}
                      >
                        #{b.fkEcomOrder}
                      </Anchor>
                    ))}
                </AppFlexbox>
              </>
            )}

          {showTransferInfo &&
            product.ecomOrderProductBulkTransfers &&
            product.ecomOrderProductBulkTransfers.length > 0 && (
              <>
                <Divider style={{ margin: '4px 0px' }} />
                <AppFlexbox style={{ gap: 5 }}>
                  <AppText style={{ fontSize: 12, color: '#666' }}>
                    Transfer{' '}
                    {product.ecomOrderProductBulkTransfers.length === 1
                      ? 'order'
                      : 'orders'}
                    :
                  </AppText>
                  <AppStack style={{ gap: 0 }}>
                    {product.ecomOrderProductBulkTransfers.map((b) => (
                      <AppText
                        key={b.pkEcomOrderProductBulk}
                        style={{ fontSize: 12, color: '#666' }}
                      >
                        <Anchor
                          c="dodgerblue"
                          component={Link}
                          style={{ fontSize: 14 }}
                          to={`/merchant/group-orders/${b.fkEcomOrder}?from=group-orders&fromId=${product.ecomOrder.pkEcomOrder}`}
                        >
                          #{b.fkEcomOrder}
                        </Anchor>{' '}
                        ({b.ecomStore.name})
                      </AppText>
                    ))}
                  </AppStack>
                </AppFlexbox>
              </>
            )}

          {showParentOrder && (
            <>
              <Divider style={{ margin: '4px 0px' }} />
              <AppFlexbox style={{ gap: 5 }}>
                <AppText style={{ fontSize: 12, color: '#666' }}>
                  Original{' '}
                  {product.ecomOrderProducts.length === 1 ? 'order' : 'orders'}:
                </AppText>
                <AppStack style={{ gap: 0 }}>
                  {product.ecomOrderProducts.map((p) => (
                    <Anchor
                      key={p.fkEcomOrder}
                      c="dodgerblue"
                      component={Link}
                      style={{ fontSize: 14 }}
                      to={`/merchant/orders/${p.fkEcomOrder}?from=group-orders&fromId=${product.ecomOrder.pkEcomOrder}`}
                    >
                      #{p.fkEcomOrder}
                    </Anchor>
                  ))}
                </AppStack>
              </AppFlexbox>
            </>
          )}

          {showCustomerInfo && (
            <AppStack style={{ gap: 0 }}>
              <Divider style={{ margin: '4px 0px' }} />
              {product.ecomCustomer.user && (
                <AppText style={{ fontSize: 14 }}>
                  {product.ecomCustomer.user.name}
                </AppText>
              )}
              <Anchor
                c="dodgerblue"
                component="a"
                href={`mailto:${product.ecomCustomer.email}`}
                style={{ fontSize: 14 }}
              >
                {product.ecomCustomer.email}
              </Anchor>
              <AppText
                style={{
                  fontSize: 12,
                  color: '#666'
                }}
              >
                Purchased{' '}
                {dayjs(
                  formatUtcDate(product.ecomOrderProduct.createdAt)
                ).format('MMMM D, YYYY h:mm A')}
              </AppText>
            </AppStack>
          )}
        </AppStack>
        {warningMessage && (
          <AppText
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: '#C40000',
              marginTop: 5
            }}
          >
            {warningMessage}
          </AppText>
        )}
        {showCancelWarnings && product.isBulkShipment && (
          <AppText
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: '#C40000',
              marginTop: 5
            }}
          >
            Cancelling this product may impact the fulfillment of the group
            order.
          </AppText>
        )}
      </AppStack>
    </AppFlexbox>
  ) : (
    <AppFlexbox style={{ width: '100%', flex: 1, gap: 8 }}>
      <AppStack
        style={{
          width: 50,
          height: 50,
          overflow: 'hidden',
          borderRadius: 8,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Skeleton height="100%" width="100%" />
      </AppStack>
      <AppStack style={{ width: '100%', gap: 5 }}>
        <Skeleton height={18} width="40%" />

        <Skeleton height={16} width="30%" />
        <Skeleton height={16} width="30%" />
      </AppStack>
    </AppFlexbox>
  );
};

VendorOrderProductPreview.propTypes = {
  bulkOrdersUrlBase: PropTypes.string,
  hideInputAddons: PropTypes.bool,
  hideStatus: PropTypes.bool,
  loading: PropTypes.bool,
  product: PropTypes.object,
  quantityIndicatorAmount: PropTypes.number,
  showCancelWarnings: PropTypes.bool,
  showCustomerInfo: PropTypes.bool,
  showParentOrder: PropTypes.bool,
  showPrice: PropTypes.bool,
  showTransferInfo: PropTypes.bool,
  showVendorName: PropTypes.bool,
  warningMessage: PropTypes.string
};

export default VendorOrderProductPreview;
