import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Checkbox, Radio } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { downloadPapaCsv } from '../../../helpers/csvHelper';
import AppFlexbox from '../../common/AppFlexbox';
import { triggerNotification } from '../../../helpers/notification';
import { singularPluralFormat } from '../../../helpers/format';

const EXPORT_OPTIONS_ENUM = {
  ALL: 'all',
  SELECTED: 'selected'
};

const ExportCsvModal = ({
  isOpen,
  onClose,
  selectedItems,
  itemType,
  itemTypePlural,
  descriptor,
  disableFieldSelect,
  exportConfig,
  onExport,
  showImportInfo,
  confirmMessage
}) => {
  const [formState, setFormState] = useState({
    exportType: '',
    selectedFields: exportConfig.fieldSections.reduce(
      (r, c) => [...r, ...c.fields],
      []
    ),
    showFieldSelect: false,
    excludedFieldSections: [],
    loading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        exportType:
          selectedItems?.length > 0
            ? EXPORT_OPTIONS_ENUM.SELECTED
            : EXPORT_OPTIONS_ENUM.ALL,
        selectedFields: exportConfig.fieldSections.reduce(
          (r, c) => [...r, ...c.fields],
          []
        ),
        showFieldSelect: false,
        excludedFieldSections: [],
        loading: false
      });
    }
  }, [isOpen]);

  const onDownloadCsvCallback = (csvData = []) => {
    const excludedFieldSections = exportConfig.fieldSections.filter((section) =>
      formState.excludedFieldSections.includes(section.value)
    );

    const exportFields = formState.selectedFields
      .filter(
        (field) =>
          !excludedFieldSections.some((s) =>
            s.fields.find((f) => f.label === field.label)
          )
      )
      .reduce(
        (r, c) => (r.find((f) => f.label === c.label) ? r : [...r, c]),
        []
      )
      .sort((a, b) => (!a.sort ? 0 : a.sort - b.sort));

    downloadPapaCsv(
      `SportsHeadz_${descriptor}_export_${new Date().toISOString()}`,
      exportFields.reduce(
        (r, c) => [...r, ...c.exportColumns.map((m) => m.label || c.label)],
        []
      ),
      csvData
        .map((d) =>
          exportFields.reduce(
            (acc, f) => ({
              ...acc,
              ...f.getExportValue(...d)
            }),
            {}
          )
        )
        .map((p) => Object.keys(p).reduce((r, c) => [...r, p[c]], []))
    );

    onClose();
    triggerNotification(
      `${descriptor[0].toUpperCase()}${descriptor.slice(
        1,
        descriptor.length
      )} exported!`,
      'success'
    );
  };

  const onErrorCallback = (errorMessage) => {
    setFormState({
      ...formState,
      loading: false
    });
    triggerNotification(errorMessage);
  };

  return (
    <ResponsiveModal
      {...(formState.showFieldSelect
        ? {
            title: 'Select export fields',
            formSectionProps: {
              onCancel: () =>
                setFormState({ ...formState, showFieldSelect: false }),
              onSubmit: () =>
                setFormState({ ...formState, showFieldSelect: false }),
              submitTitle: 'Confirm',
              isCancelHidden: true
            }
          }
        : formState.loading
        ? {
            title: `Exporting ${descriptor}`,
            formSectionProps: {
              onSubmit: onClose,
              isCancelHidden: true,
              submitTitle: 'Close'
            }
          }
        : {
            title: `Export ${descriptor}`,
            formSectionProps: {
              onCancel: onClose,
              onSubmit: () => {
                setFormState({
                  ...formState,
                  loading: true
                });
                onExport(
                  {
                    items:
                      formState.exportType === EXPORT_OPTIONS_ENUM.SELECTED
                        ? selectedItems
                        : [],
                    excludedFieldSections: formState.excludedFieldSections
                  },
                  onDownloadCsvCallback,
                  onErrorCallback
                );
              },
              submitTitle: `Export ${descriptor}`,
              leftSection: !disableFieldSelect && (
                <Anchor
                  onClick={() =>
                    setFormState({
                      ...formState,
                      showFieldSelect: true
                    })
                  }
                  style={{ fontSize: 14, color: 'dodgerblue' }}
                >
                  Select export fields
                </Anchor>
              )
            }
          })}
      isOpen={isOpen}
      onClose={onClose}
    >
      {formState.loading ? (
        <AppStack style={{ gap: 10 }}>
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            Your {itemTypePlural} are being exported.
          </AppText>
          <AppText style={{ fontSize: 14 }}>
            You can close this dialog. You will be notified when the import is
            complete.
          </AppText>
        </AppStack>
      ) : formState.showFieldSelect ? (
        <AppStack style={{ gap: 16 }}>
          <AppText
            style={{
              fontSize: 14,
              textAlign: 'center',
              fontStyle: 'italic',
              color: '#666'
            }}
          >
            Select the fields you want to include in the CSV file.
          </AppText>
          {exportConfig.fieldSections.map((section) => {
            const allFieldsSelected = section.fields.every((f) =>
              formState.selectedFields.find((sf) => sf.label === f.label)
            );

            const isExcluded = formState.excludedFieldSections.includes(
              section.value
            );

            return (
              <AppStack key={section.name} style={{ gap: 2 }}>
                {section.canExclude && !isExcluded && (
                  <AppFlexbox style={{ flex: 1, justifyContent: 'end' }}>
                    <Anchor
                      onClick={() => {
                        setFormState({
                          ...formState,
                          excludedFieldSections: [
                            ...formState.excludedFieldSections,
                            section.value
                          ]
                        });
                      }}
                      style={{ fontSize: 14, color: 'dodgerblue' }}
                    >
                      Exclude {section.name.toLowerCase()} fields
                    </Anchor>
                  </AppFlexbox>
                )}
                <AppStack style={{ gap: 0, border: 'solid 1px #dee2e6' }}>
                  <AppFlexbox
                    style={{
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      backgroundColor: 'rgba(240,240,240)',
                      padding: 8
                    }}
                  >
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      {section.name}
                    </AppText>

                    {!isExcluded && (
                      <AppFlexbox
                        onClick={() => {
                          setFormState({
                            ...formState,
                            selectedFields: allFieldsSelected
                              ? formState.selectedFields.filter(
                                  (f) =>
                                    !section.fields.find(
                                      (pf) => pf.label === f.label
                                    ) ||
                                    f.required ||
                                    f.selectDisabled
                                )
                              : [
                                  ...formState.selectedFields.filter(
                                    (f) =>
                                      !section.fields.find(
                                        (pf) => pf.label === f.label
                                      )
                                  ),
                                  ...section.fields
                                ]
                          });
                        }}
                        style={{ cursor: 'pointer', gap: 8 }}
                      >
                        <Checkbox
                          checked={allFieldsSelected}
                          onChange={() => {}}
                        />
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          Select all
                        </AppText>
                      </AppFlexbox>
                    )}
                  </AppFlexbox>
                  {isExcluded ? (
                    <AppStack
                      style={{
                        borderTop: 'solid 1px #dee2e6',
                        padding: 20,
                        gap: 5,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        {section.name} fields will not be included in the
                        export.
                      </AppText>
                      <Anchor
                        onClick={() => {
                          setFormState({
                            ...formState,
                            excludedFieldSections: formState.excludedFieldSections.filter(
                              (f) => f !== section.value
                            )
                          });
                        }}
                        style={{ fontSize: 14, color: 'dodgerblue' }}
                      >
                        Undo.
                      </Anchor>
                    </AppStack>
                  ) : (
                    section.fields
                      .filter((f) => !f.hiddenDisplay)
                      .map((field) => (
                        <AppFlexbox
                          key={field.label}
                          onClick={() => {
                            if (!field.required && !field.selectDisabled) {
                              setFormState({
                                ...formState,
                                selectedFields: formState.selectedFields.find(
                                  (f) => f.label === field.label
                                )
                                  ? formState.selectedFields.filter(
                                      (f) => f.label !== field.label
                                    )
                                  : [...formState.selectedFields, field]
                              });
                            }
                          }}
                          style={{
                            flex: 1,
                            alignItems: 'center',
                            borderTop: 'solid 1px #dee2e6',
                            gap: 8,
                            padding: '12px 8px',
                            cursor:
                              field.required || field.selectDisabled
                                ? 'normal'
                                : 'pointer'
                          }}
                        >
                          <Checkbox
                            checked={
                              formState.selectedFields.find(
                                (f) => f.label === field.label
                              ) ?? false
                            }
                            disabled={field.required || field.selectDisabled}
                            onChange={() => {}}
                          />
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            {field.label}
                          </AppText>
                        </AppFlexbox>
                      ))
                  )}
                </AppStack>
              </AppStack>
            );
          })}
        </AppStack>
      ) : (
        confirmMessage || (
          <AppStack style={{ gap: 5 }}>
            {showImportInfo && (
              <AppText style={{ fontSize: 14 }}>
                You can use this CSV file to update your {descriptor} in bulk.
                It contains all the fields needed, allowing you to edit the file
                and import it to update your {descriptor}.
              </AppText>
            )}

            <Radio.Group
              description={`Select the ${itemTypePlural} to export`}
              label="Export"
              onChange={(v) => {
                setFormState({
                  ...formState,
                  exportType: v
                });
              }}
              value={formState.exportType}
            >
              <AppStack style={{ gap: 10, marginTop: 10 }}>
                <Radio
                  label={`All ${itemTypePlural}`}
                  value={EXPORT_OPTIONS_ENUM.ALL}
                />
                <Radio
                  disabled={!selectedItems || selectedItems.length === 0}
                  label={`Selected ${singularPluralFormat(
                    selectedItems?.length ?? 0,
                    itemType,
                    itemTypePlural
                  )}`}
                  value={EXPORT_OPTIONS_ENUM.SELECTED}
                />
              </AppStack>
            </Radio.Group>
          </AppStack>
        )
      )}
    </ResponsiveModal>
  );
};

ExportCsvModal.propTypes = {
  confirmMessage: PropTypes.any,
  descriptor: PropTypes.string,
  disableFieldSelect: PropTypes.bool,
  exportConfig: PropTypes.object,
  isOpen: PropTypes.bool,
  itemType: PropTypes.string,
  itemTypePlural: PropTypes.string,
  onClose: PropTypes.func,
  onExport: PropTypes.func,
  selectedItems: PropTypes.array,
  showImportInfo: PropTypes.bool
};

export default ExportCsvModal;
