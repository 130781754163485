import React, { useState } from 'react';
import {
  Accordion,
  Anchor,
  Button,
  Checkbox,
  Divider,
  NumberFormatter,
  NumberInput,
  Select,
  TextInput
} from '@mantine/core';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import VendorOrderProductPreview from './VendorOrderProductPreview';
import { formatUtcDate } from '../../../helpers/format';

const GROUP_BY_ENUM = {
  CUSTOMER: 'CUSTOMER',
  PRODUCT: 'PRODUCT'
};

const ORDER_BY_ENUM = {
  PURCHASE_DATE: 'PURCHASE_DATE',
  CUSTOMER_NAME: 'CUSTOMER_NAME'
};

const OrderProductSelect = ({
  productData,
  selectedProducts,
  onChange,
  disabled,
  isBulkShipment
}) => {
  const [filterState, setFilterState] = useState({
    search: '',
    groupBy: '',
    orderBy: ORDER_BY_ENUM.PURCHASE_DATE
  });

  const products = productData.filter(
    (f) => f.returnedCount + f.cancelledCount < f.quantity
  );
  const filteredProducts = isBulkShipment
    ? products
        .filter(
          (p) =>
            !filterState.search ||
            (p.ecomCustomer.user?.name || p.ecomCustomer.email)
              .toLowerCase()
              .includes(filterState.search.toLowerCase())
        )
        .sort((a, b) =>
          filterState.orderBy === ORDER_BY_ENUM.PURCHASE_DATE
            ? new Date(b.ecomOrderProduct.createdAt) -
              new Date(a.ecomOrderProduct.createdAt)
            : (a.ecomCustomer.user?.name || a.ecomCustomer.email).localeCompare(
                b.ecomCustomer.user?.name || b.ecomCustomer.email
              )
        )
    : products;

  const renderProductSelect = (p, index) => {
    const selectedProduct = selectedProducts.find(
      (pr) => pr.pkEcomOrderProduct === p.pkEcomOrderProduct
    );
    const selectedCount = selectedProduct?.count ?? 0;
    const count = selectedCount
      ? selectedCount > p.amountRemaining
        ? p.amountRemaining
        : selectedCount
      : 0;

    return (
      <React.Fragment key={p.key}>
        {index > 0 && <Divider />}

        <AppFlexbox
          style={{
            gap: 16,
            justifyContent: 'space-between'
          }}
        >
          <AppFlexbox style={{ flex: 1 }}>
            <VendorOrderProductPreview
              isBulkShipment={isBulkShipment}
              product={p}
              showCancelWarnings={!isBulkShipment}
              showCustomerInfo={isBulkShipment}
              showPrice
            />
          </AppFlexbox>
          <AppFlexbox style={{ alignItems: 'start' }}>
            <AppFlexbox
              style={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <NumberInput
                disabled={disabled}
                max={p.amountRemaining}
                min={0}
                onChange={(v) =>
                  onChange([
                    selectedProduct
                      ? {
                          ...selectedProduct,
                          count: v
                        }
                      : {
                          pkEcomOrderProduct: p.pkEcomOrderProduct,
                          count: v,
                          unitCost: p.unitCost
                        }
                  ])
                }
                rightSection={
                  <AppFlexbox>
                    <AppText style={{ fontSize: 15, color: '#999' }}>
                      of {p.amountRemaining}
                    </AppText>
                  </AppFlexbox>
                }
                rightSectionWidth="40%"
                style={{ width: '100%', maxWidth: 100 }}
                value={selectedCount}
              />

              <AppFlexbox
                style={{
                  alignItems: 'center',
                  justifyContent: 'end',
                  gap: 24,
                  minWidth: 100
                }}
              >
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  thousandSeparator
                  value={(p.unitCost * count) / 100}
                />
              </AppFlexbox>
            </AppFlexbox>
          </AppFlexbox>
        </AppFlexbox>
      </React.Fragment>
    );
  };

  return (
    <>
      {isBulkShipment && (
        <>
          <AppStack style={{ gap: 8 }}>
            <TextInput
              onChange={(e) => {
                setFilterState({
                  ...filterState,
                  search: e.currentTarget.value
                });
              }}
              placeholder="Search by customer"
              value={filterState.search}
            />
            <AppFlexbox style={{ gap: 8 }}>
              <Select
                data={[
                  { label: 'Group by customer', value: GROUP_BY_ENUM.CUSTOMER },
                  {
                    label: 'Group by product variant',
                    value: GROUP_BY_ENUM.PRODUCT
                  }
                ]}
                onChange={(value) => {
                  setFilterState({ ...filterState, groupBy: value });
                }}
                placeholder="Group by"
                style={{ flex: 1 }}
                value={filterState.groupBy}
              />
              <Select
                data={[
                  {
                    label: 'Order by purchase date',
                    value: ORDER_BY_ENUM.PURCHASE_DATE
                  },
                  {
                    label: 'Order by customer name',
                    value: ORDER_BY_ENUM.CUSTOMER_NAME
                  }
                ]}
                onChange={(value) => {
                  if (value) {
                    setFilterState({ ...filterState, orderBy: value });
                  }
                }}
                placeholder="Order by"
                style={{ flex: 1 }}
                value={filterState.orderBy}
              />
            </AppFlexbox>
          </AppStack>
          <Divider />
        </>
      )}

      <AppStack style={{ gap: 16 }}>
        {filteredProducts.length === 0 ? (
          <AppStack style={{ padding: 40, gap: 16, alignItems: 'center' }}>
            <AppText
              style={{
                textAlign: 'center',
                fontSize: 14,
                fontWeight: 500
              }}
            >
              No products found
            </AppText>
            <Button
              color="dark"
              onClick={() => {
                setFilterState({ ...filterState, search: '' });
              }}
              variant="outline"
            >
              Clear search
            </Button>
          </AppStack>
        ) : !isBulkShipment || !filterState.groupBy ? (
          filteredProducts.map(renderProductSelect)
        ) : (
          filteredProducts
            .reduce((r, c) => {
              const title =
                filterState.groupBy === GROUP_BY_ENUM.CUSTOMER
                  ? c.ecomCustomer.email
                  : c.uuid;

              const existing = r.find((e) => e.title === title);
              if (existing) {
                existing.products.push(c);
              }
              else {
                r.push({
                  title,
                  products: [c]
                });
              }
              return r;
            }, [])
            .map((g) => {
              const totalGroupCount = g.products.reduce(
                (r, c) => r + c.amountRemaining,
                0
              );
              const selectedGroupProducts = selectedProducts.filter((sp) =>
                g.products.some(
                  (p) => p.pkEcomOrderProduct === sp.pkEcomOrderProduct
                )
              );
              const totalSelectedCount = selectedGroupProducts.reduce(
                (r, c) => r + c.count,
                0
              );
              const isEntireGroupSelected =
                totalGroupCount === totalSelectedCount;

              return (
                <Accordion
                  key={g.title}
                  styles={{
                    content: { padding: 0 },
                    item: { borderBottom: 'none' }
                  }}
                >
                  <Accordion.Item value={g.title}>
                    <Accordion.Control>
                      <AppFlexbox
                        style={{
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <AppStack>
                          <AppFlexbox
                            onClick={(e) => {
                              e.stopPropagation();
                              onChange(
                                g.products.map((p) => ({
                                  pkEcomOrderProduct: p.pkEcomOrderProduct,
                                  count: isEntireGroupSelected
                                    ? 0
                                    : p.amountRemaining,
                                  unitCost: p.unitCost
                                }))
                              );
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              checked={isEntireGroupSelected}
                              disabled={disabled}
                              indeterminate={
                                !isEntireGroupSelected && totalSelectedCount > 0
                              }
                              onChange={() => {}}
                              style={{ marginTop: 3 }}
                            />
                            {filterState.groupBy === GROUP_BY_ENUM.CUSTOMER ? (
                              <AppStack style={{ gap: 0 }}>
                                <AppFlexbox>
                                  <AppStack style={{ gap: 0 }}>
                                    {g.products[0].ecomCustomer.user && (
                                      <AppText style={{ fontSize: 14 }}>
                                        {g.products[0].ecomCustomer.user.name}
                                      </AppText>
                                    )}
                                    <Anchor
                                      c="dodgerblue"
                                      component="a"
                                      href={`mailto:${g.products[0].ecomCustomer.email}`}
                                      style={{ fontSize: 14 }}
                                    >
                                      {g.products[0].ecomCustomer.email}
                                    </Anchor>
                                    <AppText
                                      style={{
                                        fontSize: 12,
                                        color: '#666'
                                      }}
                                    >
                                      Purchased{' '}
                                      {dayjs(
                                        formatUtcDate(
                                          g.products.sort(
                                            (a, b) =>
                                              new Date(
                                                a.ecomOrderProduct.createdAt
                                              ) -
                                              new Date(
                                                b.ecomOrderProduct.createdAt
                                              )
                                          )[0].ecomOrderProduct.createdAt
                                        )
                                      ).format('MMMM D, YYYY h:mm A')}
                                    </AppText>
                                  </AppStack>
                                </AppFlexbox>
                              </AppStack>
                            ) : (
                              <AppStack style={{ gap: 0 }}>
                                <VendorOrderProductPreview
                                  hideInputAddons
                                  hideStatus
                                  isBulkShipment={isBulkShipment}
                                  product={g.products[0]}
                                  showCancelWarnings={!isBulkShipment}
                                />
                              </AppStack>
                            )}
                          </AppFlexbox>
                        </AppStack>
                        <AppText
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            paddingRight: 8
                          }}
                        >
                          {totalSelectedCount} / {totalGroupCount} selected
                        </AppText>
                      </AppFlexbox>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <AppStack style={{ gap: 0 }}>
                        {g.products.map((p, index) => (
                          <AppStack
                            key={p.key}
                            style={{ gap: 16, padding: '10px 0px' }}
                          >
                            {renderProductSelect(p, index)}
                          </AppStack>
                        ))}
                      </AppStack>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              );
            })
        )}
      </AppStack>
    </>
  );
};

OrderProductSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  productData: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  isBulkShipment: PropTypes.bool,
  selectedProducts: PropTypes.array
};

export default OrderProductSelect;
