import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ArrowLeft,
  ChevronDown,
  CreditCard,
  Dots,
  Download,
  X
} from 'tabler-icons-react';
import {
  ActionIcon,
  Badge,
  Button,
  Menu,
  Skeleton,
  Tooltip
} from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import {
  useBackPath,
  useMediaQueryIndex,
  useModalState
} from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import OrderMerchantInfoCard from './OrderMerchantInfoCard';
import { translateVendorOrderData } from '../../../helpers/vendorHelper';
import VendorOrderShippingNotifyModal from './VendorOrderShippingNotifyModal';
import OrderProductsInfoCard from './OrderProductsInfoCard';
import OrderPaymentInfoCard from './OrderPaymentInfoCard';
import OrderTrackingInfoCard from './OrderTrackingInfoCard';
import EditShippingDetailsModal from './EditShippingDetailsModal';
import {
  ECOM_ORDER_PRODUCT_STATUS_ENUM,
  PAYMENT_TYPE_ENUM
} from '../../../config/constants';
import AppMenu from '../../common/AppMenu';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import VendorOrderAcceptModal from './VendorOrderAcceptModal';
import OrderCancelModal from './OrderCancelModal';

const VendorOrderDetailsView = ({ ecomOrder, loading, onExportOrder }) => {
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const { search } = useLocation();
  const modalAction = new URLSearchParams(search).get('modal');
  const { state: authState } = useContext(AuthContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { fetchVendorOrder } = useContext(VendorContext);
  const { ecomOrderProducts: productData } = translateVendorOrderData(
    ecomOrder
  );
  const { backPath } = useBackPath({
    baseUrl: ecomOrder?.isBulkShipment
      ? `/vendor/bulk-orders`
      : `/vendor/orders`
  });

  const totalUnitCost =
    productData?.reduce((acc, p) => acc + p.totalCost, 0) ?? 0;

  const isFulfilled = productData.every(
    (p) =>
      p.fkEcomOrderProductStatus?.toString() !==
        ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING &&
      p.fkEcomOrderProductStatus?.toString() !==
        ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
  );
  const ecomOrderShipping =
    ecomOrder?.ecomOrderShipping.filter(
      (s) => s.fkEcomVendor.toString() === authState.pkEcomVendor?.toString()
    ) ?? [];

  const allShippingNotified = ecomOrderShipping.every((s) => s.emailSent);
  const shippedProductCount = productData.reduce(
    (acc, p) => acc + p.shippedCount,
    0
  );
  const haveAllProductsBeenShipped = productData.every(
    (p) => p.shippedCount >= p.quantity
  );

  const orderProducts = productData.filter(
    (f) => !f.isBulkShipment && f.returnedCount + f.cancelledCount < f.quantity
  );
  const returnedProducts = productData.filter((f) => f.cancelledCount > 0);

  const refundAmount =
    ecomOrder?.ecomOrderPayments
      .filter(
        (p) =>
          p.fkRegFormSubmissionPaymentType.toString() ===
          PAYMENT_TYPE_ENUM.CREDIT
      )
      .reduce((r, c) => r + c.amount, 0) ?? 0;
  const payoutAmountRefunded = 0;
  const { payments = [] } =
    (ecomOrder
      ? [
          ...ecomOrder.ecomOrderProducts.map((p) => p.ecomOrderProductPayments),
          ...ecomOrder.ecomOrderProductBulk.map(
            (p) => p.ecomOrderProduct.ecomOrderProductPayments
          )
        ]
      : []
    )
      .flat()
      .sort((a, b) => new Date(b.created) - new Date(a.created))
      .reduce(
        (r, c) => {
          if (
            c.fkRegFormSubmissionPaymentType.toString() !==
            PAYMENT_TYPE_ENUM.CREDIT.toString()
          ) {
            r.payments.push(c);
          }

          return r;
        },
        { payments: [] }
      ) || {};

  const paidAmount = payments.reduce((r, c) => r + c.amount, 0);
  const payoutAmount = payments.reduce((r, c) => r + c.payoutAmount, 0);
  const isFullyPaid =
    payoutAmount - payoutAmountRefunded >= paidAmount - refundAmount;

  const isFullyCancelled =
    productData.length > 0 &&
    productData.every((p) => p.cancelledCount >= p.quantity);
  const isFullyRefunded = refundAmount >= paidAmount;
  const isPendingFulfillment = productData.some((p) => p.amountRemaining > 0);

  useEffect(() => {
    if (modalAction) {
      switch (modalAction) {
        case 'cancel':
          onOpenModal('cancel_order');
          break;
        default:
          break;
      }
    }
  }, [modalAction]);

  const renderStatusBadges = () => (
    <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
      {isFullyCancelled ? (
        <Badge color="#C40000" variant="filled">
          Cancelled
        </Badge>
      ) : isFulfilled ? (
        <Badge color="green" variant="filled">
          Fulfilled
        </Badge>
      ) : (
        <Badge color="dark" variant="light">
          Unfulfilled
        </Badge>
      )}
      {refundAmount > 0 ? (
        refundAmount >= paidAmount ? (
          <Badge color="#C40000" variant="filled">
            Refunded
          </Badge>
        ) : (
          <Badge color="grey" variant="filled">
            Partially refunded
          </Badge>
        )
      ) : isFullyPaid ? (
        <Badge color="blue" variant="filled">
          Paid
        </Badge>
      ) : (
        <Badge color="dark" variant="light">
          Unpaid
        </Badge>
      )}
      {ecomOrderShipping.length > 0 &&
        (allShippingNotified ? (
          <Badge color="blue" variant="filled">
            Customer Notified
          </Badge>
        ) : (
          <Tooltip label="Customer has not been notified about all shipments.">
            <Badge color="red" variant="filled">
              Action Required
            </Badge>
          </Tooltip>
        ))}
    </AppFlexbox>
  );

  return !loading && ecomOrder ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <VendorOrderShippingNotifyModal
        ecomOrder={ecomOrder}
        isOpen={modalState.isOpen && modalState.action === 'notify'}
        onClose={onCloseModal}
        pkEcomOrderShipping={
          modalState.action === 'notify'
            ? modalState.item?.pkEcomOrderShipping
            : null
        }
      />

      <EditShippingDetailsModal
        ecomOrderShipping={
          modalState.action === 'edit-shipping' ? modalState.item : null
        }
        isOpen={modalState.isOpen && modalState.action === 'edit-shipping'}
        onClose={onCloseModal}
        pkEcomOrder={ecomOrder.pkEcomOrder}
      />

      <VendorOrderAcceptModal
        ecomOrder={ecomOrder}
        isOpen={modalState.isOpen && modalState.action === 'accept-order'}
        onClose={onCloseModal}
        onSuccess={() =>
          fetchVendorOrder(authState.pkEcomVendor, {
            pkEcomOrder: ecomOrder.pkEcomOrder
          })
        }
      />

      {!ecomOrder.isBulkShipment && (
        <OrderCancelModal
          ecomOrder={ecomOrder}
          isOpen={
            modalState.isOpen &&
            (modalState.action === 'cancel_order' ||
              modalState.action === 'cancel_products')
          }
          isProductCancel={modalState.action === 'cancel_products'}
          isVendorView
          onClose={onCloseModal}
        />
      )}

      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={backPath}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 10 }}>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                <AppFlexbox>
                  <AppText
                    style={{
                      flex: 1,
                      fontSize: 24,
                      fontWeight: 700,
                      wordBreak: 'break-word'
                    }}
                  >
                    Order #{ecomOrder.pkEcomOrder}
                  </AppText>
                </AppFlexbox>
                <AppFlexbox visibleFrom="xsm">
                  {renderStatusBadges()}
                </AppFlexbox>
              </AppFlexbox>
              <AppText
                style={{
                  fontSize: 12,
                  lineHeight: '16px',
                  color: '#666'
                }}
              >
                Placed{' '}
                {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format(
                  'MMMM D, YYYY'
                )}{' '}
                at{' '}
                {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format('h:mm A')}{' '}
                from {ecomOrder.ecomStore.name}
              </AppText>
            </AppStack>{' '}
            <AppFlexbox hiddenFrom="xsm">{renderStatusBadges()}</AppFlexbox>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }}>
          <AppMenu
            control={
              <Button
                color="dark"
                rightSection={<ChevronDown size={18} />}
                size="compact-md"
                style={{ fontSize: 14 }}
                variant="light"
              >
                Actions
              </Button>
            }
          >
            <Menu.Item
              leftSection={<Download size={18} />}
              onClick={() => {
                onExportOrder([ecomOrder]);
              }}
            >
              Export order
            </Menu.Item>
            {(!isFullyRefunded || !isFullyCancelled) && (
              <>
                <Menu.Divider />
                {!isFullyCancelled && isPendingFulfillment && (
                  <Menu.Item
                    leftSection={<X color="#c40000" size={18} />}
                    onClick={() => onOpenModal('cancel_order')}
                    style={{ color: '#c40000' }}
                  >
                    Cancel order
                  </Menu.Item>
                )}
                {!isFullyRefunded && (
                  <Menu.Item
                    component={Link}
                    leftSection={<CreditCard color="#c40000" size={18} />}
                    style={{ color: '#c40000' }}
                    to={`/vendor/orders/${ecomOrder.pkEcomOrder}/refund`}
                  >
                    Refund order
                  </Menu.Item>
                )}
              </>
            )}
          </AppMenu>
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard
            actionButton={
              !isFullyCancelled &&
              orderProducts.some((p) => p.amountRemaining > 0) && (
                <AppMenu
                  control={
                    <ActionIcon color="dark" variant="subtle">
                      <Dots />
                    </ActionIcon>
                  }
                >
                  <Menu.Item
                    leftSection={<X color="#c40000" size={18} />}
                    onClick={() => onOpenModal('cancel_products')}
                    style={{ color: '#c40000' }}
                  >
                    Cancel products
                  </Menu.Item>
                </AppMenu>
              )
            }
            fulfillLink={
              !haveAllProductsBeenShipped
                ? `/vendor/${
                    ecomOrder.isBulkShipment ? 'bulk-orders' : 'orders'
                  }/${ecomOrder.pkEcomOrder}/fulfill`
                : null
            }
            isCancelled={isFullyCancelled}
            isFulfilled={isFulfilled}
            onAcceptOrder={() => onOpenModal('accept-order')}
            onNotify={
              shippedProductCount > 0 && !allShippingNotified
                ? () => onOpenModal('notify')
                : null
            }
            productData={orderProducts}
          />

          <OrderPaymentInfoCard
            actionMenu={
              (!isFullyRefunded || !isFullyCancelled) && (
                <AppMenu
                  control={
                    <ActionIcon color="dark" variant="subtle">
                      <Dots />
                    </ActionIcon>
                  }
                >
                  {!isFullyCancelled && isPendingFulfillment && (
                    <Menu.Item
                      leftSection={<X color="#c40000" size={18} />}
                      onClick={() => onOpenModal('cancel_order')}
                      style={{ color: '#c40000' }}
                    >
                      Cancel order
                    </Menu.Item>
                  )}
                  {!isFullyRefunded && (
                    <Menu.Item
                      component={Link}
                      leftSection={<CreditCard color="#c40000" size={18} />}
                      style={{ color: '#c40000' }}
                      to={`/vendor/orders/${ecomOrder.pkEcomOrder}/refund`}
                    >
                      Refund order
                    </Menu.Item>
                  )}
                </AppMenu>
              )
            }
            payments={[
              {
                key: 1,
                group: 1,
                label: 'Subtotal',
                color: '#666',
                description: singularPluralFormat(
                  ecomOrder.ecomOrderProducts.reduce(
                    (acc, item) => acc + item.count,
                    0
                  ),
                  'item',
                  'items'
                ),
                value: totalUnitCost / 100
              },
              {
                key: 2,
                group: 2,
                label: 'Total',
                weight: 500,
                description: '',
                value: totalUnitCost / 100
              },
              {
                key: 3,
                group: 2,
                label: 'Refunded',
                color: refundAmount > 0 ? '#c40000' : '#666',
                description: '',
                value: (refundAmount * -1) / 100
              },
              {
                key: 4,
                group: 3,
                label: 'Payout earned',
                weight: 500,
                description: '',
                value: (paidAmount - refundAmount) / 100
              },
              {
                key: 5,
                group: 3,
                label: 'Payout received',
                color: '#666',
                description: '',
                value: (payoutAmount - payoutAmountRefunded) / 100
              },
              {
                key: 6,
                group: 4,
                label: 'Payout balance',
                weight: 500,
                description: '',
                value:
                  (paidAmount -
                    refundAmount -
                    (payoutAmount - payoutAmountRefunded)) /
                  100
              }
            ]}
          />

          <OrderTrackingInfoCard
            ecomOrderShipping={ecomOrderShipping}
            loading={loading}
            onNotify={(shipping) => onOpenModal('notify', shipping)}
            onUpdateShipping={(shipping) =>
              onOpenModal('edit-shipping', shipping)
            }
            productData={productData}
          />

          {returnedProducts.length > 0 && (
            <OrderProductsInfoCard
              description="Cancelled products are kept separate from the fulfillment process."
              hideStatus
              isVendorView
              productData={returnedProducts}
              showReimbursement
              title="Cancelled products"
            />
          )}
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard
            customerEmail={ecomOrder.ecomCustomer.email}
            customerName={ecomOrder.ecomCustomer.user?.name}
            customerUrl={`/vendor/customers/${ecomOrder.ecomCustomer.pkEcomCustomer}?from=orders&fromId=${ecomOrder.pkEcomOrder}`}
            shippingAddress={ecomOrder.ecomCustomerAddressShipping}
          />

          <OrderMerchantInfoCard ecomStore={ecomOrder.ecomStore} />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={backPath}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard loading />

          <OrderPaymentInfoCard loading />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard loading />
          <OrderMerchantInfoCard loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

VendorOrderDetailsView.propTypes = {
  ecomOrder: PropTypes.object,
  loading: PropTypes.bool,
  onExportOrder: PropTypes.func
};

export default VendorOrderDetailsView;
