import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Context as HelperContext } from '../../../providers/HelperContextProvider';
import ResponsiveWebstoreModal from './ResponsiveWebstoreModal';
import { useCartController } from '../../../helpers/webstoreCheckoutHelper';
import { triggerNotification } from '../../../helpers/notification';
import SelectProductOptionsForm from '../products/SelectProductOptionsForm';

const AddWebstoreProductToCartModal = ({
  baseStoreUrl,
  mediaQueries,
  editModeEnabled,
  onNavigate,
  ecomStoreTheme,
  pathname
}) => {
  const {
    state: helperState,
    toggleWebstoreAddProductToCartModal
  } = useContext(HelperContext);
  const { ecomStoreCart, addStoreProducts } = useCartController();
  const [formState, setFormState] = useState({
    optionErrors: [],
    selectedOptions: [],
    ecomStoreCartProductInputAnswers: [],
    count: 1
  });
  const { ecomStoreProduct } = helperState.webstore.addProductToCartModal;
  const { isTabletOrSmaller } = mediaQueries;

  const selectedVariant = ecomStoreProduct?.ecomStoreProductVariants.find(
    (v) =>
      formState.selectedOptions.length ===
        v.ecomVendorProductVariant.ecomVendorProductVariantOptions.length &&
      v.ecomVendorProductVariant.ecomVendorProductVariantOptions.every((op) =>
        formState.selectedOptions.some(
          (f) => f.name === op.name && f.value === op.value
        )
      )
  );
  const productInCart = ecomStoreCart?.ecomStoreCartProducts.find(
    (p) =>
      p.ecomStoreProductVariant.ecomVendorProductVariant.uuid ===
      selectedVariant?.ecomVendorProductVariant.uuid
  );

  useEffect(() => {
    if (helperState.webstore.addProductToCartModal.isOpen) {
      // eslint-disable-next-line no-use-before-define
      onClose();
    }
  }, [pathname]);

  const onClose = () => {
    toggleWebstoreAddProductToCartModal({
      isOpen: false,
      ecomStoreProduct:
        helperState.webstore.addProductToCartModal.ecomStoreProduct
    });
  };

  const onAddProductToCart = () => {
    setFormState({
      ...formState,
      loading: true,
      optionErrors: []
    });
    addStoreProducts(
      ecomStoreTheme.ecomStore.pkEcomStore,
      {
        ecomStoreCartProducts: [
          {
            ecomStoreProduct,
            uuid: selectedVariant.ecomVendorProductVariant.uuid,
            count: formState.count,
            ecomStoreCartProductInputAnswers:
              formState.ecomStoreCartProductInputAnswers
          }
        ]
      },
      () => {
        setFormState({
          ...formState,
          loading: false,
          optionErrors: []
        });
        onClose();
      },
      (error, type) => {
        if (type === 'input') {
          setFormState({
            ...formState,
            optionErrors: [error],
            loading: false
          });
        }
        else {
          triggerNotification(error);
          setFormState({
            ...formState,
            optionErrors: [],
            loading: false
          });
        }
      }
    );
  };

  return (
    <ResponsiveWebstoreModal
      alignModal={isTabletOrSmaller ? 'end' : 'center'}
      editModeEnabled={editModeEnabled}
      isOpen={helperState.webstore.addProductToCartModal.isOpen}
      mediaQueries={mediaQueries}
      noHeader
      onClose={onClose}
      radius={0}
      size={1200}
    >
      <SelectProductOptionsForm
        baseStoreUrl={baseStoreUrl}
        ecomStoreProduct={ecomStoreProduct}
        formState={formState}
        isOpen={helperState.webstore.addProductToCartModal.isOpen}
        mediaQueries={mediaQueries}
        onAddProduct={onAddProductToCart}
        onNavigate={onNavigate}
        productInCartCount={productInCart?.count ?? 0}
        productUrl={`${baseStoreUrl}/products/${ecomStoreProduct?.urlHandle}`}
        setFormState={(s) =>
          setFormState({
            ...formState,
            ...s
          })
        }
      />
    </ResponsiveWebstoreModal>
  );
};

AddWebstoreProductToCartModal.propTypes = {
  baseStoreUrl: PropTypes.string,
  ecomStoreTheme: PropTypes.object,
  editModeEnabled: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onNavigate: PropTypes.func,
  pathname: PropTypes.string
};

export default AddWebstoreProductToCartModal;
