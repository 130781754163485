import React from 'react';
import { Anchor, Divider, Skeleton } from '@mantine/core';
import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';
import { Link } from 'react-router-dom';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import AppCard from '../../common/AppCard';
import { PAYMENT_TYPE_LIST } from '../../../config/constants';

const AdminVendorInfoCard = ({ ecomVendor }) => {
  const { isTabletOrSmaller, isDesktopOrSmaller } = useMediaQueryIndex();

  return ecomVendor ? (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontSize: 16, fontWeight: 500 }}>Vendor</AppText>
          </AppFlexbox>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox>
              <Anchor
                c="dodgerblue"
                component={Link}
                style={{ fontSize: 13 }}
                to={`/admin/vendors/${ecomVendor.pkEcomVendor}`}
              >
                {ecomVendor.name}
              </Anchor>
            </AppFlexbox>
          </AppStack>
        </AppStack>

        <Divider />

        <AppStack style={{ flex: 1, padding: 16, gap: 8 }}>
          <AppFlexbox
            style={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Contact information
            </AppText>
          </AppFlexbox>
          <AppStack style={{ gap: 0 }}>
            <AppText style={{ fontSize: 13 }}>{ecomVendor.name}</AppText>
            {ecomVendor.domain && (
              <AppFlexbox>
                <Anchor
                  href={ecomVendor.domain}
                  style={{ fontSize: 13, color: 'dodgerblue' }}
                  target="_blank"
                >
                  {ecomVendor.domain}
                </Anchor>
              </AppFlexbox>
            )}

            <AppFlexbox>
              <Anchor
                href={`mailto:${ecomVendor.supportEmail}`}
                style={{ fontSize: 13, color: 'dodgerblue' }}
                target="_blank"
              >
                {ecomVendor.supportEmail}
              </Anchor>
            </AppFlexbox>
          </AppStack>
        </AppStack>

        <Divider />

        <AppStack style={{ flex: 1, padding: 16, gap: 16 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Preferred payment method
              </AppText>
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppText
                style={{
                  fontSize: 14,
                  color: ecomVendor.fkRegFormSubmissionPaymentType
                    ? '#000'
                    : '#666'
                }}
              >
                {PAYMENT_TYPE_LIST.find(
                  (p) =>
                    p.value.toString() ===
                    ecomVendor.fkRegFormSubmissionPaymentType?.toString()
                )?.label || 'Not selected'}
              </AppText>
            </AppStack>
          </AppStack>

          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Billing address
              </AppText>
            </AppFlexbox>
            {ecomVendor.ecomCustomerAddress ? (
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox style={{ alignItems: 'center' }}>
                  <AppText style={{ fontSize: 14 }}>
                    {ecomVendor.ecomCustomerAddress.fullName}
                  </AppText>
                </AppFlexbox>
                <AppText style={{ fontSize: 14 }}>
                  {ecomVendor.ecomCustomerAddress.address1}
                </AppText>
                {ecomVendor.ecomCustomerAddress.address2 && (
                  <AppText style={{ fontSize: 14 }}>
                    {ecomVendor.ecomCustomerAddress.address2}
                  </AppText>
                )}
                <AppText style={{ fontSize: 14 }}>
                  {ecomVendor.ecomCustomerAddress.city}{' '}
                  {ecomVendor.ecomCustomerAddress.postalCode}{' '}
                  {ecomVendor.ecomCustomerAddress.state},{' '}
                  {ecomVendor.ecomCustomerAddress.country}
                </AppText>
                {ecomVendor.ecomCustomerAddress.phone && (
                  <AppText style={{ fontSize: 14 }}>
                    <PatternFormat
                      allowEmptyFormatting
                      displayType="text"
                      format="+1 (###) ###-####"
                      mask="_"
                      value={ecomVendor.ecomCustomerAddress.phone}
                    />
                  </AppText>
                )}
              </AppStack>
            ) : (
              <AppStack style={{ gap: 0 }}>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  No billing address
                </AppText>
              </AppStack>
            )}
          </AppStack>
        </AppStack>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack
        style={{
          gap: 0,
          flexDirection:
            !isTabletOrSmaller && isDesktopOrSmaller ? 'row' : 'column'
        }}
      >
        <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton height={22} width="30%" />
            </AppFlexbox>
            <AppStack style={{ gap: 0 }}>
              <AppFlexbox>
                <Skeleton height={16} width="40%" />
              </AppFlexbox>
            </AppStack>
          </AppStack>

          <AppStack style={{ flex: 1, gap: 8 }}>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Skeleton height={22} width="60%" />
            </AppFlexbox>
            <AppStack style={{ gap: 5 }}>
              <Skeleton height={16} width="40%" />
              <Skeleton height={16} width="40%" />
              <Skeleton height={16} width="40%" />
            </AppStack>
          </AppStack>
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

AdminVendorInfoCard.propTypes = { ecomVendor: PropTypes.object };

export default AdminVendorInfoCard;
