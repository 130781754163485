import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import CustomerAddressForm from './CustomerAddressForm';

const CustomerAddressModal = ({
  isOpen,
  onClose,
  address,
  isBilling,
  title,
  includePhone,
  onSubmit
}) => {
  const [formState, setFormState] = useState({
    fullName: '',
    address1: '',
    address2: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    phone: '',
    loading: false
  });

  useEffect(() => {
    if (isOpen) {
      setFormState({
        fullName: address?.fullName || '',
        address1: address?.address1 || '',
        address2: address?.address2 || '',
        city: address?.city || '',
        state: address?.state || '',
        postalCode: address?.postalCode || '',
        country: address?.country || '',
        phone: address?.phone || '',
        loading: false
      });
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        submitTitle: 'Update address',
        cancelTitle: 'Close',
        onCancel: onClose,
        onSubmit: () => {
          onSubmit(formState);
        },
        isLoading: formState.loading
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={title || isBilling ? 'Billing address' : 'Shipping address'}
    >
      <AppStack style={{}}>
        {/*         
        <Checkbox
          checked={formState.sameAsCopy}
          disabled={formState.loading}
          label={
            isBilling ? 'Same as shipping address' : 'Same as billing address'
          }
          onChange={() =>
            setFormState({
              ...formState,
              sameAsCopy: !formState.sameAsCopy
            })
          }
        /> */}
        <CustomerAddressForm
          formState={formState}
          includePhone={includePhone}
          setFormState={setFormState}
        />
      </AppStack>
    </ResponsiveModal>
  );
};

CustomerAddressModal.propTypes = {
  address: PropTypes.object,
  includePhone: PropTypes.bool,
  isBilling: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string
};

export default CustomerAddressModal;
