import onApiRequest from './ApiRequestHandler';
import createDataProvider from './createDataProvider';
import { providerContextReducer } from './providerContextReducer';

const initialPaginatedState = {
  pageIndex: 1,
  pageSize: 50,
  totalCount: 0,
  totalPages: 0,
  totalNoFilterCount: 0,
  data: [],
  filter: null,
  loading: false,
  error: null
};

const initialState = {
  ecomCustomer: { value: null, loading: false, error: null },
  ecomVendor: { value: null, loading: false, error: null },
  ecomVendorAnalytics: { value: null, loading: false, error: null },
  ecomVendorCollections: { value: [], loading: false, error: null },
  ecomVendorCollection: { value: null, loading: false, error: null },
  ecomVendorMedia: { value: [], loading: false, error: null },
  ecomVendors: { value: [], loading: false, error: null },
  ecomNotifications: { value: null, loading: false, error: null },
  ecomOrder: { value: null, loading: false, error: null },
  ecomOrdersSummary: { value: null, loading: false, error: null },
  ecomStore: { value: null, loading: false, error: null },
  ecomVendorProducts: { value: [], loading: false, error: null },
  ecomVendorProductsExport: { value: [], loading: false, error: null },
  ecomVendorProduct: { value: null, loading: false, error: null },
  ecomVendorReport: { value: null, loading: false, error: null },
  ecomVendorSetupSummary: { value: null, loading: false, error: null },
  paginatedEcomOrders: initialPaginatedState,
  paginatedEcomOrdersBulk: initialPaginatedState,
  paginatedEcomVendorCustomers: initialPaginatedState,
  paginatedEcomVendorStores: initialPaginatedState,
  paginatedEcomVendorProductVariants: initialPaginatedState,
  paginatedEcomVendorProducts: initialPaginatedState
};

// #region Vendors
const createVendor = (dispatch) => async (
  {
    name,
    domain,
    logoImageUrl,
    supportEmail,
    sandboxEnabled,
    provinces,
    fkCurrencyType
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendors',
      url: '/api/staff/ecommerce/vendors',
      type: 'post',
      data: {
        name,
        domain,
        logoImageUrl,
        supportEmail,
        sandboxEnabled,
        provinces,
        fkCurrencyType
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendorSetupSummary = (dispatch) => async (
  pkEcomVendor,
  { skipLoading } = {},
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorSetupSummary',
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/setup-summary`,
      type: 'get',
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendors = (dispatch) => async (
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendors',
      url: '/api/ecommerce/vendors',
      type: 'get'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendor = (dispatch) => async (
  pkEcomVendor,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendor',
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}`,
      type: 'get',
      filter: { pkEcomVendor },
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateVendor = (dispatch) => async (
  pkEcomVendor,
  {
    name,
    domain,
    supportEmail,
    description,
    logoImageUrl,
    bannerImageUrl,
    primaryColor,
    primaryContrastColor,
    secondaryColor,
    secondaryAltColor,
    secondaryContrastColor,
    fkCurrencyType,
    fkRegFormSubmissionPaymentType,
    ecomCustomerAddress,
    provinces,
    sandboxEnabled,
    urlHandle
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendor',
      successAction: 'success',
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}`,
      type: 'put',
      data: {
        name,
        domain,
        supportEmail,
        description,
        logoImageUrl,
        bannerImageUrl,
        primaryColor,
        primaryContrastColor,
        secondaryColor,
        secondaryAltColor,
        secondaryContrastColor,
        fkCurrencyType,
        fkRegFormSubmissionPaymentType,
        ecomCustomerAddress,
        provinces,
        sandboxEnabled,
        urlHandle
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const onboardVendorPaymentProvider = (dispatch) => async (
  { pkEcomVendor, accountId, updateAccount },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/payment/ecommerce/onboard`,
      type: 'post',
      data: {
        pkEcomVendor,
        accountId,
        updateAccount
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const disconnectVendorPaymentProvider = (dispatch) => async (
  { pkEcomVendor },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/payment/ecommerce/onboard/disconnect`,
      type: 'post',
      data: { pkEcomVendor }
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Vendor Media
const fetchVendorMedia = (dispatch) => async (
  { fkEcomVendor },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorMedia',
      url: '/api/ecommerce/admin/vendors/media',
      type: 'get',
      params: { fkEcomVendor },
      resetStateValue: []
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createVendorMedia = (dispatch) => async (
  pkEcomVendor,
  ecomVendorMedia,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorMedia',
      url: `/api/ecommerce/admin/vendors/media/${pkEcomVendor}`,
      type: 'post',
      data: ecomVendorMedia
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Products
const fetchPaginatedVendorProducts = (dispatch) => async (
  fkEcomVendor,
  {
    search,
    fkEcomProductStatus,
    fkEcomCategory,
    sort,
    page,
    pageSize,
    appendResults,
    skipLoading
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomVendorProducts',
      url: `/api/ecommerce/admin/vendors/products`,
      type: 'get',
      params: {
        fkEcomVendor,
        search,
        fkEcomProductStatus,
        fkEcomCategory,
        sort,
        page,
        pageSize,
        appendResults
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data }),
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendorProductOptions = (dispatch) => async (
  fkEcomVendor,
  { skipLoading },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorProducts',
      url: `/api/ecommerce/admin/vendors/products/options`,
      type: 'get',
      params: { fkEcomVendor },
      successAction: 'success',
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendorProductExportData = (dispatch) => async (
  { fkEcomVendor, uuids, urlHandles, skipStateUpdate = false },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const uuidsParam = uuids?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `uuids[${index}]=${current}&`),
    ''
  );

  const urlHandlesParam = urlHandles?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `urlHandles[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      url: `/api/ecommerce/admin/vendors/products/export-data?fkEcomVendor=${fkEcomVendor}${
        uuidsParam ? `&${uuidsParam}` : ''
      }${urlHandlesParam ? `&${urlHandlesParam}` : ''}`,
      type: 'get',
      ...(skipStateUpdate
        ? {}
        : {
            dispatch,
            stateName: 'ecomVendorProductsExport',
            filter: { fkEcomVendor, uuids },
            resetStateValue: []
          })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendorProduct = (dispatch) => async (
  productUuid,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorProduct',
      url: `/api/ecommerce/admin/vendors/products/${productUuid}`,
      type: 'get',
      filter: { productUuid },
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createVendorProducts = (dispatch) => async (
  pkEcomVendor,
  { ecomVendorProducts, selectFirstItem },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: selectFirstItem
        ? 'ecomVendorProduct'
        : 'ecomVendorProductsExport',
      url: `/api/ecommerce/admin/vendors/products/${pkEcomVendor}`,
      type: 'post',
      selectFirstItem,
      data: ecomVendorProducts,
      successAction: selectFirstItem ? 'success' : 'append'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateVendorProducts = (dispatch) => async (
  pkEcomVendor,
  { ecomVendorProducts, selectFirstItem, createProductsNotFound },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: selectFirstItem
        ? 'ecomVendorProduct'
        : 'ecomVendorProductsExport',
      url: `/api/ecommerce/admin/vendors/products/${pkEcomVendor}`,
      type: 'put',
      data: {
        ecomVendorProducts,
        createProductsNotFound
      },
      selectFirstItem,
      successAction: selectFirstItem ? 'success' : 'replace',
      removeFilter: (item) =>
        !ecomVendorProducts.map((p) => p.uuid).includes(item.uuid)
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteVendorProducts = (dispatch) => async (
  pkEcomVendor,
  pkEcomVendorProducts,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkEcomVendorProductsParam = pkEcomVendorProducts?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `pkEcomVendorProducts[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorProducts',
      url: `/api/ecommerce/admin/vendors/products/${pkEcomVendor}?${pkEcomVendorProductsParam}`,
      type: 'delete',
      removeFilter: (item) =>
        !pkEcomVendorProducts.includes(item.pkEcomVendorProduct)
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchPaginatedVendorProductVariants = (dispatch) => async (
  fkEcomVendor,
  {
    trackQuantity,
    uuids,
    search,
    sku,
    barcode,
    quantity,
    sort,
    page,
    exportResponse = false,
    skipLoading = false,
    skipStateUpdate = false,
    appendResults = false
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const uuidsParam = uuids?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `uuids[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      url: `/api/ecommerce/admin/vendors/products/${fkEcomVendor}/variants${
        uuidsParam ? `?${uuidsParam}` : ''
      }`,
      type: 'get',
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data }),
      params: {
        trackQuantity,
        uuids,
        search,
        sku,
        barcode,
        quantity,
        sort,
        page,
        exportResponse
      },
      skipLoading,
      ...(skipStateUpdate
        ? {}
        : {
            dispatch,
            stateName: 'paginatedEcomVendorProductVariants',
            resetStateValue: []
          })
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Collections
const fetchVendorCollections = (dispatch) => async (
  { fkEcomVendor },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorCollections',
      url: '/api/ecommerce/admin/vendors/collections',
      type: 'get',
      params: { fkEcomVendor },
      resetStateValue: []
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendorCollection = (dispatch) => async (
  pkEcomVendorCollection,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorCollection',
      url: `/api/ecommerce/admin/vendors/collections/${pkEcomVendorCollection}`,
      type: 'get',
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const createVendorCollections = (dispatch) => async (
  pkEcomVendor,
  { ecomVendorCollections, selectFirstItem },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: selectFirstItem
        ? 'ecomVendorCollection'
        : 'ecomVendorCollections',
      url: `/api/ecommerce/admin/vendors/collections/${pkEcomVendor}`,
      type: 'post',
      selectFirstItem,
      data: ecomVendorCollections,
      successAction: selectFirstItem ? 'success' : 'append'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateVendorCollection = (dispatch) => async (
  pkEcomVendorCollection,
  { name, description, ecomVendorCollectionProducts },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorCollection',
      url: `/api/ecommerce/admin/vendors/collections/${pkEcomVendorCollection}`,
      type: 'put',
      data: {
        name,
        description,
        ecomVendorCollectionProducts
      },
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const deleteVendorCollections = (dispatch) => async (
  pkEcomVendor,
  pkEcomVendorCollections,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkParam = pkEcomVendorCollections?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `pkEcomVendorCollections[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorCollections',
      url: `/api/ecommerce/admin/vendors/collections/${pkEcomVendor}?${pkParam}`,
      type: 'delete',
      removeFilter: (item) =>
        !pkEcomVendorCollections.includes(item.pkEcomVendorCollection)
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Reports & Analytics
const fetchVendorAnalytics = (dispatch) => async (
  pkEcomVendor,
  { startDate, endDate, compareStartDate, compareEndDate },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorAnalytics',
      url: `/api/ecommerce/admin/vendors/analytics`,
      type: 'get',
      successAction: 'success',
      params: {
        pkEcomVendor,
        startDate,
        endDate,
        compareStartDate,
        compareEndDate
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const generateVendorReport = (dispatch) => async (
  pkEcomStore,
  {
    uuid,
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
    periodInterval,
    groupBy,
    headers,
    options
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomVendorReport',
      url: `/api/ecommerce/admin/vendors/${pkEcomStore}/reports/generate/${uuid}`,
      type: 'post',
      successAction: 'success',
      data: {
        startDate,
        endDate,
        compareStartDate,
        compareEndDate,
        periodInterval,
        groupBy,
        headers,
        options
      }
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Customers & Orders
const fetchPaginatedVendorOrders = (dispatch) => async (
  pkEcomVendor,
  {
    search,
    store,
    status,
    sort,
    page,
    pageSize,
    isBulkShipment,
    appendResults,
    pkEcomOrders,
    skipStateUpdate
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  const pkEcomOrdersParam = pkEcomOrders?.reduce(
    // eslint-disable-next-line no-return-assign
    (result, current, index) =>
      // eslint-disable-next-line no-param-reassign
      (result += `pkEcomOrders[${index}]=${current}&`),
    ''
  );

  onApiRequest(
    {
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/orders${
        pkEcomOrdersParam ? `?${pkEcomOrdersParam}` : ''
      }`,
      type: 'get',
      params: {
        search,
        store,
        status,
        sort,
        page,
        pageSize,
        isBulkShipment
      },
      transformPayload: (data) => ({ ...data }),
      ...(skipStateUpdate
        ? {}
        : {
            successAction: appendResults ? 'paginated-append' : 'success',
            dispatch,
            stateName: isBulkShipment
              ? 'paginatedEcomOrdersBulk'
              : 'paginatedEcomOrders'
          })
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendorOrdersSummary = (dispatch) => async (
  pkEcomVendor,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomOrdersSummary',
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/orders/summary`,
      type: 'get'
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendorOrder = (dispatch) => async (
  pkEcomVendor,
  { pkEcomOrder, skipLoading },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomOrder',
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/orders/${pkEcomOrder}`,
      type: 'get',
      filter: {
        pkEcomVendor,
        pkEcomOrder
      },
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const refundVendorOrder = (dispatch) => async (
  pkEcomVendor,
  { pkEcomOrder, refundAmount, skipNotification, ecomOrderReturnProducts },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCartTransactionDetails',
      url: `/api/payment/ecommerce/orders/${pkEcomOrder}/refund`,
      type: 'post',
      successAction: 'success',
      data: {
        refundAmount,
        skipNotification,
        pkEcomVendor,
        ecomOrderReturnProducts
      },
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const cancelVendorOrder = (dispatch) => async (
  pkEcomVendor,
  {
    pkEcomOrder,
    ecomOrderReturnProducts,
    cancelOrder,
    refundPayment,
    refundAmount,
    reason
  },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStoreCartTransactionDetails',
      url: `/api/payment/ecommerce/orders/${pkEcomOrder}/cancel`,
      type: 'post',
      successAction: 'success',
      data: {
        pkEcomVendor,
        ecomOrderReturnProducts,
        cancelOrder,
        refundPayment,
        refundAmount,
        reason
      },
      resetStateValue: null
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fulfillEcomVendorOrderProducts = (dispatch) => async (
  pkEcomVendor,
  ecomOrderShippings,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/orders/fulfill`,
      type: 'post',
      data: ecomOrderShippings
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const updateEcomOrderShippings = (dispatch) => async (
  pkEcomVendor,
  ecomOrderShippings,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/orders/shipping`,
      type: 'put',
      data: ecomOrderShippings
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const notifyEcomOrderShipping = (dispatch) => async (
  pkEcomVendor,
  { pkEcomOrderShippings },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/orders/shipping/notify`,
      type: 'post',
      data: pkEcomOrderShippings
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const acceptEcomOrder = (dispatch) => async (
  pkEcomVendor,
  { pkEcomOrders },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/orders/accept`,
      type: 'post',
      data: pkEcomOrders
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// region Customers
const fetchPaginatedVendorCustomers = (dispatch) => async (
  pkEcomVendor,
  { search, sort, page, pageSize, appendResults, skipLoading },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomVendorCustomers',
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/customers`,
      type: 'get',
      params: {
        search,
        sort,
        page,
        pageSize,
        appendResults
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data }),
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendorCustomer = (dispatch) => async (
  pkEcomVendor,
  { pkEcomCustomer },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomCustomer',
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/customers/${pkEcomCustomer}`,
      type: 'get',
      params: {},
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// region Merchants
const fetchPaginatedVendorStores = (dispatch) => async (
  pkEcomVendor,
  { search, sort, page, pageSize, appendResults, skipLoading },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'paginatedEcomVendorStores',
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/stores`,
      type: 'get',
      params: {
        search,
        sort,
        page,
        pageSize,
        appendResults
      },
      successAction: appendResults ? 'paginated-append' : 'success',
      transformPayload: (data) => ({ ...data }),
      skipLoading
    },
    onSuccessCallback,
    onErrorCallback
  );
};

const fetchVendorStore = (dispatch) => async (
  pkEcomVendor,
  { pkEcomStore },
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomStore',
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/stores/${pkEcomStore}`,
      type: 'get',
      params: {},
      successAction: 'success'
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

// #region Notifications
const fetchVendorNotifications = (dispatch) => async (
  pkEcomVendor,
  onSuccessCallback = null,
  onErrorCallback = null
) => {
  onApiRequest(
    {
      dispatch,
      stateName: 'ecomNotifications',
      url: `/api/ecommerce/admin/vendors/${pkEcomVendor}/notifications`,
      type: 'get',
      successAction: 'success',
      filter: { pkEcomVendor }
    },
    onSuccessCallback,
    onErrorCallback
  );
};
// #endregion

export const { Provider, Context } = createDataProvider(
  providerContextReducer,
  {
    acceptEcomOrder,
    cancelVendorOrder,
    createVendor,
    createVendorCollections,
    createVendorMedia,
    createVendorProducts,
    deleteVendorCollections,
    deleteVendorProducts,
    disconnectVendorPaymentProvider,
    fetchPaginatedVendorProducts,
    fetchVendor,
    fetchVendorAnalytics,
    fetchVendorCollection,
    fetchVendorCollections,
    fetchVendorCustomer,
    fetchVendorMedia,
    fetchVendorNotifications,
    fetchVendors,
    fetchVendorOrder,
    fetchVendorOrdersSummary,
    fetchVendorProduct,
    fetchVendorProductOptions,
    fetchVendorProductExportData,
    fetchVendorSetupSummary,
    fetchVendorStore,
    fetchPaginatedVendorCustomers,
    fetchPaginatedVendorOrders,
    fetchPaginatedVendorProductVariants,
    fetchPaginatedVendorStores,
    fulfillEcomVendorOrderProducts,
    generateVendorReport,
    notifyEcomOrderShipping,
    onboardVendorPaymentProvider,
    updateEcomOrderShippings,
    updateVendor,
    updateVendorCollection,
    updateVendorProducts,
    refundVendorOrder
  },
  initialState
);
