import React from 'react';
import { CircleCheck } from 'tabler-icons-react';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { currencyFormat } from '../../../helpers/format';
import ConfirmModal from '../../common/ConfirmModal';
import OrderRefundConfirmSummary from './OrderRefundConfirmSummary';

const OrderRefundConfirmModal = ({
  isOpen,
  onClose,
  onRefund,
  loading,
  ecomOrder,
  selectedProducts,
  refundAmount,
  skipNotification,
  hasRefundedAllItems,
  selectedItemCount,
  selectedItemsRefundValue,
  tieredPricing,
  maxRefundAmountWithoutLosses,
  productData,
  isVendorView
}) => (
  <ConfirmModal
    confirmActionColor="red"
    confirmActionText={`Yes, refund ${currencyFormat(refundAmount / 100)}`}
    formSectionProps={{
      padding: 0,
      isLoading: loading,
      leftSection: !skipNotification && (
        <AppFlexbox
          style={{
            gap: 8,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircleCheck color="#067D62" size={20} />
          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            Notify customer
          </AppText>
        </AppFlexbox>
      )
    }}
    isOpen={isOpen}
    modalProps={{ size: 500 }}
    onCancel={onClose}
    onConfirm={onRefund}
    title={`Refund order #${ecomOrder.pkEcomOrder}`}
  >
    <OrderRefundConfirmSummary
      ecomOrder={ecomOrder}
      hasRefundedAllItems={hasRefundedAllItems}
      isVendorView={isVendorView}
      maxRefundAmountWithoutLosses={maxRefundAmountWithoutLosses}
      productData={productData}
      refundAmount={refundAmount}
      selectedItemCount={selectedItemCount}
      selectedItemsRefundValue={selectedItemsRefundValue}
      selectedProducts={selectedProducts}
      tieredPricing={tieredPricing}
    />
  </ConfirmModal>
);

OrderRefundConfirmModal.propTypes = {
  ecomOrder: PropTypes.object,
  hasRefundedAllItems: PropTypes.bool,
  isOpen: PropTypes.bool,
  isProductCancel: PropTypes.bool,
  isVendorView: PropTypes.bool,
  loading: PropTypes.bool,
  maxRefundAmountWithoutLosses: PropTypes.number,
  onClose: PropTypes.func,
  onRefund: PropTypes.func,
  productData: PropTypes.array,
  refundAmount: PropTypes.number,
  selectedItemCount: PropTypes.number,
  selectedItemsRefundValue: PropTypes.number,
  selectedProducts: PropTypes.array,
  skipNotification: PropTypes.bool,
  tieredPricing: PropTypes.number
};

export default OrderRefundConfirmModal;
