import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AlertCircle, ArrowLeft } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Button, Divider, Select, Skeleton } from '@mantine/core';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import ActionableIcon from '../../common/ActionableIcon';
import AppText from '../../common/AppText';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import AppCard from '../../common/AppCard';
import StoreOrderContributorListItem from './StoreOrderContributorListItem';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import {
  useMediaQueryIndex,
  usePaginationFilter
} from '../../../helpers/hooks';
import { translateStoreOrderData } from '../../../helpers/storeHelper';
import StoreOrderGroupProductRequirementsCard from './StoreOrderGroupProductRequirementsCard';
import { triggerNotification } from '../../../helpers/notification';
import ConfirmModal from '../../common/ConfirmModal';
import StoreOrderTransferCreateOrderListItem from './StoreOrderTransferCreateOrderListItem';
import { ECOM_ORDER_PRODUCT_STATUS_ENUM } from '../../../config/constants';

const StoreOrderTransferCreateView = ({ loading, ecomOrder }) => {
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { pkEcomOrder } = useParams();
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchPaginatedStoreOrders,
    transferEcomStoreOrderProducts
  } = useContext(StoreContext);
  const mediaQueries = useMediaQueryIndex();
  const {
    isTabletOrSmaller,
    isLargeMobileOrSmaller,
    isDesktopOrSmaller
  } = mediaQueries;
  const {
    groupedEcomOrderProducts,
    ecomOrderProductBulkChanges
  } = translateStoreOrderData(ecomOrder);
  const { data: orderData, loading: dataLoading } = usePaginationFilter(
    {
      isBulkShipment: true,
      status: 'accumulating',
      sort: 'order_desc',
      vendorProductUuids: [],
      fkUserAdmin: null,
      pageSize: 200
    },
    (newFilter) => {
      fetchPaginatedStoreOrders(
        authState.pkEcomStore,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    state.paginatedEcomOrdersAdmin
  );
  const [formState, setFormState] = useState({
    fkEcomStoreFilter: '',
    selectedOrder: null,
    ecomOrderProductBulk: [],
    showConfirm: false,
    loading: false,
    error: null
  });

  const isViewLoading =
    !hasFetched.current || loading || !ecomOrder || dataLoading;
  const selectedItemCount = formState.ecomOrderProductBulk.reduce(
    (r, c) => r + c.count,
    0
  );
  const orderSelectedCount = formState.ecomOrderProductBulk.reduce(
    (r, c) => (r.find((f) => f.fkEcomOrder === c.fkEcomOrder) ? r : [...r, c]),
    []
  ).length;
  const groupProduct =
    groupedEcomOrderProducts[0] ||
    ecomOrderProductBulkChanges.transferred[0] ||
    {};

  const storeOptions = orderData?.reduce((r, c) => {
    if (!r.find((o) => o.value === c.ecomStore.pkEcomStore.toString())) {
      r.push({
        label: c.ecomStore.name,
        value: c.ecomStore.pkEcomStore.toString()
      });
    }
    return r;
  }, []);

  const availableOrders = orderData.filter(
    (d) =>
      d.pkEcomOrder !== ecomOrder?.pkEcomOrder &&
      d.ecomOrderProductBulk.some(
        (b) =>
          b.fkEcomOrderProductStatus.toString() ===
          ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
      ) &&
      (!formState.fkEcomStoreFilter ||
        d.ecomStore.pkEcomStore.toString() === formState.fkEcomStoreFilter)
  );

  useEffect(() => {
    if (ecomOrder && authState.pkEcomStore) {
      fetchPaginatedStoreOrders(
        authState.pkEcomStore,
        {
          isBulkShipment: true,
          status: 'accumulating',
          sort: 'order_desc',
          vendorProductUuids: [groupProduct.vendorProductUuid],
          fkUserAdmin: authState.userData.user.pkUser,
          pageSize: 200
        },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      hasFetched.current = true;
    }
  }, [ecomOrder, authState.pkEcomStore]);

  const onSubmitProductTransfer = () => {
    setFormState({ ...formState, loading: true });
    transferEcomStoreOrderProducts(
      authState.pkEcomStore,
      {
        pkEcomOrder: ecomOrder.pkEcomOrder,
        ecomOrderProductBulk: formState.ecomOrderProductBulk
      },
      () => {
        triggerNotification('Products transferred successfully', 'success');
        navigate(`/merchant/group-orders/${ecomOrder.pkEcomOrder}`);
      },
      (e) => {
        setFormState({ ...formState, loading: false });
        triggerNotification(e);
      }
    );
  };

  return !isViewLoading ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <ConfirmModal
        isLoading={formState.loading}
        isOpen={formState.showConfirm}
        modalProps={{ size: 500 }}
        onCancel={() => {
          setFormState({ ...formState, showConfirm: false });
        }}
        onConfirm={onSubmitProductTransfer}
        title={`Transfer ${singularPluralFormat(
          selectedItemCount,
          'product',
          'products'
        )}?`}
      >
        <AppStack style={{ gap: 8 }}>
          <AppText style={{ fontSize: 14 }}>
            Are you sure you want to transfer{' '}
            <b>
              {singularPluralFormat(selectedItemCount, 'product', 'products')}
            </b>{' '}
            from{' '}
            <b>{singularPluralFormat(orderSelectedCount, 'order', 'orders')}</b>{' '}
            into this order?
          </AppText>

          <AppText style={{ fontSize: 14 }}>
            Transferring products will add them to this order, and the vendor
            will use this order's address for delivery.
          </AppText>

          <AppText
            style={{
              fontSize: 14,
              color: '#666',
              fontStyle: 'italic',
              textAlign: 'center'
            }}
          >
            This action will not impact reports or analytics for each store.
          </AppText>
        </AppStack>
      </ConfirmModal>

      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={`/merchant/group-orders/${ecomOrder.pkEcomOrder}`}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText
                style={{
                  flex: 1,
                  fontSize: 24,
                  fontWeight: 700,
                  wordBreak: 'break-word'
                }}
              >
                Order #{ecomOrder.pkEcomOrder}
              </AppText>
            </AppFlexbox>

            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              Placed{' '}
              {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format(
                'MMMM D, YYYY'
              )}{' '}
              at {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format('h:mm A')}
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }} />
      </AppFlexbox>

      <AppCard
        radius={isTabletOrSmaller ? 0 : 'md'}
        shadow="xs"
        style={{ padding: 8 }}
        withBorder
      >
        <AppFlexbox style={{ gap: 8 }}>
          <AppStack>
            <AlertCircle color="#eca70a" style={{ size: 16 }} />
          </AppStack>

          <AppText style={{ fontSize: 14 }}>
            Transfer products from orders accross other stores you manage into
            this order to help reach the minimum order amount.
          </AppText>
        </AppFlexbox>
      </AppCard>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 16, padding: 16 }}>
                <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                  Available orders
                </AppText>

                <AppText
                  style={{
                    fontSize: 14,
                    textAlign: 'center',
                    color: '#666'
                  }}
                >
                  Select the products from the orders below to transfer into
                  this order.
                </AppText>

                <AppFlexbox style={{ gap: 8 }}>
                  <Select
                    data={
                      groupProduct.vendorProductUuid
                        ? [
                            {
                              label: groupProduct.name,
                              value: groupProduct.vendorProductUuid
                            }
                          ]
                        : []
                    }
                    disabled
                    onChange={() => {}}
                    placeholder="Filter by product"
                    value={groupProduct.vendorProductUuid}
                  />
                  <Select
                    clearable
                    data={storeOptions}
                    onChange={(v) =>
                      setFormState({ ...formState, fkEcomStoreFilter: v })
                    }
                    placeholder="Filter by store"
                    searchable
                    value={formState.fkEcomStoreFilter}
                  />
                </AppFlexbox>
              </AppStack>

              <Divider />

              {availableOrders.length === 0 ? (
                <AppText
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: 'center',
                    color: '#666',
                    padding: 24
                  }}
                >
                  No available orders to transfer.
                </AppText>
              ) : (
                availableOrders.map((order) => (
                  <StoreOrderTransferCreateOrderListItem
                    key={order.pkEcomOrder}
                    disabled={formState.loading}
                    ecomOrderSummary={order}
                    onEcomOrderProductBulkSelect={(selected) => {
                      setFormState({
                        ...formState,
                        ecomOrderProductBulk: [
                          ...formState.ecomOrderProductBulk.filter(
                            (f) => f.fkEcomOrder !== order.pkEcomOrder
                          ),
                          ...selected
                        ]
                      });
                    }}
                    selectedEcomOrderProductBulk={formState.ecomOrderProductBulk.filter(
                      (f) => f.fkEcomOrder === order.pkEcomOrder
                    )}
                  />
                ))
              )}
            </AppStack>
          </AppCard>
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          {groupedEcomOrderProducts[0] && (
            <StoreOrderGroupProductRequirementsCard
              checkoutItemCount={selectedItemCount}
              productData={groupedEcomOrderProducts[0]}
            />
          )}

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 8, padding: 16 }}>
                <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                  Summary
                </AppText>
                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Orders included
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    {orderSelectedCount}
                  </AppText>
                </AppFlexbox>

                <AppFlexbox
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    Items selected
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    {selectedItemCount}
                  </AppText>
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ gap: 0, padding: 16 }}>
                <Button
                  color="dark"
                  disabled={selectedItemCount === 0}
                  loading={formState.loading && !formState.showConfirm}
                  onClick={() => {
                    setFormState({ ...formState, showConfirm: true });
                  }}
                  variant="filled"
                >
                  Transfer{' '}
                  {selectedItemCount === 0
                    ? 'products'
                    : singularPluralFormat(
                        selectedItemCount,
                        'product',
                        'products'
                      )}
                </Button>
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={`/merchant/group-orders/${pkEcomOrder}`}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppStack style={{ gap: 16 }}>
            <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
              <AppStack style={{ gap: 0 }}>
                <AppStack style={{ padding: 16, gap: 8 }}>
                  <AppFlexbox
                    style={{
                      gap: 8,
                      alignItems: 'center'
                    }}
                  >
                    <Skeleton height={16} width="25%" />
                  </AppFlexbox>

                  <AppFlexbox
                    style={{
                      gap: 0,
                      alignItems: 'end',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Skeleton
                      height={36}
                      width={isLargeMobileOrSmaller ? '100%' : 200}
                    />

                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      {!isLargeMobileOrSmaller && (
                        <Skeleton height={14} width={80} />
                      )}

                      <Skeleton
                        height={36}
                        width={isLargeMobileOrSmaller ? '100%' : 200}
                      />
                    </AppFlexbox>
                  </AppFlexbox>
                </AppStack>
                <Divider />

                <AppStack style={{ flex: 1, gap: 16 }}>
                  {Array.from(Array(3)).map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={i}>
                      {i > 0 && <Divider />}
                      <StoreOrderContributorListItem showDetails />
                    </React.Fragment>
                  ))}
                </AppStack>
              </AppStack>
            </AppCard>
          </AppStack>
        </AppStack>
        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

StoreOrderTransferCreateView.propTypes = {
  ecomOrder: PropTypes.object,
  loading: PropTypes.bool
};

export default StoreOrderTransferCreateView;
