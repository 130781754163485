/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  ColorSwatch,
  NumberFormatter,
  NumberInput,
  Skeleton
} from '@mantine/core';
import { AlertCircle, Trash } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppCard from '../../../../common/AppCard';
import AppFlexbox from '../../../../common/AppFlexbox';
import AppStack from '../../../../common/AppStack';
import AppText from '../../../../common/AppText';
import AppImage from '../../../../common/AppImage';
import { isVariantUnavailable } from '../../../../../helpers/webstoreHelper';
import ProductLineItemAddonsDisplay from '../../../products/ProductLineItemAddonsDisplay';

const WsCheckoutCartProductCard = ({
  lineItem,
  isOrderHistory,
  mediaQueries,
  loading,
  onRemove,
  submitting,
  productUrl,
  onNavigate,
  onUpdate,
  onCustomize,
  latestVersionEcomStoreCartProduct,
  onRemoveUnavailableProducts,
  ecomStoreCartProduct,
  ...rest
}) => {
  const [debouncedQuantity, setDebouncedQuantity] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const { isTabletOrSmaller } = mediaQueries;

  const isProductUnavailable =
    latestVersionEcomStoreCartProduct &&
    isVariantUnavailable(latestVersionEcomStoreCartProduct);

  const quantityLeft = ecomStoreCartProduct
    ? ecomStoreCartProduct.ecomStoreProductVariant.ecomVendorProductVariant
        .quantity
    : null;

  const outOfStock = quantityLeft != null && quantityLeft <= 0;
  const tooManyInCart =
    quantityLeft != null && quantityLeft - lineItem.quantity < 0;

  useEffect(() => {
    if (lineItem) {
      setQuantity(lineItem.quantity);
    }
  }, [lineItem]);

  useEffect(() => {
    if (debouncedQuantity !== null) {
      if (debouncedQuantity !== lineItem.quantity) {
        onUpdate([
          {
            pkEcomStoreCartProduct: lineItem.entityId,
            uuid:
              ecomStoreCartProduct.ecomStoreProductVariant
                .ecomVendorProductVariant.uuid,
            count: debouncedQuantity
          }
        ]);
      }
    }
  }, [debouncedQuantity]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuantity(quantity);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [quantity]);

  return lineItem ? (
    <AppCard
      shadow="xs"
      style={{ padding: 0, borderRadius: isTabletOrSmaller ? 0 : 8 }}
      withBorder
    >
      {!isOrderHistory && latestVersionEcomStoreCartProduct ? (
        <AppFlexbox
          style={{
            padding: 10,
            backgroundColor: isProductUnavailable ? '#c40000' : '#eca70a',
            color: '#FFF',
            alignItems: 'center',
            gap: 8
          }}
        >
          <AlertCircle size={18} />{' '}
          {isProductUnavailable ? (
            <AppText style={{ fontSize: 14 }}>
              This product is no longer available
            </AppText>
          ) : (
            <AppText style={{ fontSize: 14 }}>
              This product has been changed
            </AppText>
          )}
          {!submitting && (
            <Anchor
              onClick={onRemoveUnavailableProducts}
              style={{ marginLeft: 'auto', color: '#FFF', fontSize: 14 }}
              underline="always"
            >
              Update cart
            </Anchor>
          )}
        </AppFlexbox>
      ) : (
        !isOrderHistory &&
        quantityLeft != null &&
        (outOfStock ? (
          <AppFlexbox
            style={{
              padding: 10,
              alignItems: 'center',
              borderBottom: '1px solid #dee2e6',
              gap: 8
            }}
          >
            <ColorSwatch color="#c40000" size={16} />
            <AppText style={{ fontSize: 14 }}>Out of stock</AppText>
            {!submitting && (
              <Anchor
                onClick={onRemoveUnavailableProducts}
                style={{ marginLeft: 'auto', fontSize: 14 }}
                underline="always"
              >
                Update cart
              </Anchor>
            )}
          </AppFlexbox>
        ) : tooManyInCart ? (
          <AppFlexbox
            style={{
              padding: 10,
              alignItems: 'center',
              borderBottom: '1px solid #dee2e6',
              gap: 8
            }}
          >
            <ColorSwatch color="#c40000" size={18} />
            <AppText style={{ fontSize: 14 }}>
              Only {quantityLeft} left. Please remove some from your cart.
            </AppText>
            {!submitting && (
              <Anchor
                onClick={onRemoveUnavailableProducts}
                style={{ marginLeft: 'auto', fontSize: 14 }}
                underline="always"
              >
                Update cart
              </Anchor>
            )}
          </AppFlexbox>
        ) : (
          quantityLeft <= 25 && (
            <AppFlexbox
              style={{
                padding: 10,
                alignItems: 'center',
                borderBottom: '1px solid #dee2e6',
                gap: 8
              }}
            >
              <ColorSwatch color="#eca70a" size={18} />
              <AppText style={{ fontSize: 14 }}>
                Low stock: {quantityLeft} left
              </AppText>
            </AppFlexbox>
          )
        ))
      )}
      <AppStack style={{ gap: 0, flexWrap: 'nowrap' }}>
        <AppFlexbox
          style={{
            flex: 1,
            margin: 16,
            flexWrap: 'nowrap',
            flexDirection: isTabletOrSmaller ? 'column' : 'row'
          }}
        >
          <AppFlexbox
            style={{ flexWrap: 'nowrap', alignSelf: 'stretch', flex: 2 }}
          >
            <AppFlexbox style={{ alignSelf: 'start', flex: 1 }}>
              <AppFlexbox component={Link} onClick={onNavigate} to={productUrl}>
                <AppImage
                  fit="contain"
                  height={isTabletOrSmaller ? 70 : 100}
                  src={lineItem.previewImage}
                  width={isTabletOrSmaller ? 70 : 100}
                />
              </AppFlexbox>

              <AppStack
                style={{
                  flex: 1,
                  gap: 20,
                  justifyContent: 'center'
                }}
              >
                <AppStack
                  component={Link}
                  onClick={onNavigate}
                  style={{
                    flex: 1,
                    gap: 0,
                    textDecoration: 'none',
                    color: '#000'
                  }}
                  to={productUrl}
                >
                  <AppText style={{ fontSize: 18, fontWeight: 700 }}>
                    {lineItem.productName}
                  </AppText>
                  <ProductLineItemAddonsDisplay addOns={lineItem.addOns} />

                  {isOrderHistory && (
                    <AppText style={{ fontSize: 12, color: '#666' }}>
                      Qty: {lineItem.quantity}
                    </AppText>
                  )}
                </AppStack>
                {!isOrderHistory && (
                  <AppFlexbox style={{ gap: 10, alignItems: 'center' }}>
                    <NumberInput
                      allowNegative={false}
                      disabled={
                        outOfStock ||
                        submitting ||
                        !!latestVersionEcomStoreCartProduct
                      }
                      min={1}
                      onChange={(v) => {
                        let newQuantity =
                          quantityLeft !== null && v > quantityLeft
                            ? quantityLeft
                            : v;
                        if (newQuantity <= 0) {
                          newQuantity = 1;
                        }
                        setQuantity(newQuantity);
                      }}
                      size="xs"
                      style={{ flex: 1, maxWidth: 80 }}
                      value={quantity}
                    />
                    {!latestVersionEcomStoreCartProduct &&
                      lineItem.isCustomizable && (
                        <Anchor
                          onClick={onCustomize}
                          style={{ fontSize: 14, color: 'dodgerblue' }}
                          underline="always"
                        >
                          Customize
                        </Anchor>
                      )}
                  </AppFlexbox>
                )}
              </AppStack>
            </AppFlexbox>
          </AppFlexbox>

          <AppStack
            style={{
              flex: 1,
              alignSelf: 'start',
              placeItems: 'end',
              marginLeft: 'auto',
              gap: 10
            }}
          >
            <AppStack style={{ gap: 0, alignItems: 'end' }}>
              <AppText style={{ fontSize: 18 }} weight={700}>
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  suffix={` ${lineItem.currency?.toUpperCase()}`}
                  thousandSeparator
                  value={(lineItem.subtotalInCents * lineItem.quantity) / 100}
                />
              </AppText>
              <AppText style={{ fontSize: 14, color: '#666' }}>
                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  suffix={` each`}
                  thousandSeparator
                  value={lineItem.subtotalInCents / 100}
                />
              </AppText>
              {lineItem.customerPaysServiceFees &&
                lineItem.serviceFeeInCents > 0 && (
                  <AppFlexbox style={{ gap: 5, flexWrap: 'nowrap' }}>
                    <AppText weight={500}>
                      <NumberFormatter
                        decimalScale={2}
                        displayType="text"
                        fixedDecimalScale
                        prefix="+ $"
                        suffix=" Service fee"
                        thousandSeparator
                        value={lineItem.serviceFeeInCents / 100}
                      />
                    </AppText>
                  </AppFlexbox>
                )}
            </AppStack>
            {!isOrderHistory && (
              <AppStack style={{ gap: 20, flex: 1 }}>
                <AppFlexbox
                  onClick={() => {
                    if (!submitting) {
                      onRemove([{ pkEcomStoreCartProduct: lineItem.entityId }]);
                    }
                  }}
                  style={{
                    cursor: submitting ? 'default' : 'pointer',
                    gap: 5,
                    alignSelf: 'end',
                    alignItems: 'center',
                    color: submitting ? '#999' : '#c40000'
                  }}
                >
                  <Trash size={18} />
                  <AppText style={{ fontSize: 14 }}>Remove</AppText>
                </AppFlexbox>
              </AppStack>
            )}
          </AppStack>
        </AppFlexbox>
      </AppStack>
    </AppCard>
  ) : (
    <AppCard
      radius={isTabletOrSmaller ? 0 : 'md'}
      shadow="xs"
      style={{ padding: 0, borderRadius: 8 }}
      withBorder
    >
      <AppStack style={{ gap: 0, flexWrap: 'nowrap' }}>
        <AppFlexbox
          style={{
            flex: 1,
            margin: 30,
            flexWrap: 'nowrap'
          }}
        >
          <AppFlexbox
            style={{ flexWrap: 'nowrap', alignSelf: 'stretch', flex: 2 }}
          >
            <AppFlexbox style={{ alignSelf: 'start', flex: 1 }}>
              <Skeleton
                height={isTabletOrSmaller ? 70 : 100}
                width={isTabletOrSmaller ? 70 : 100}
              />
              <AppStack
                style={{
                  flex: 1,
                  gap: 10,
                  textDecoration: 'none',
                  justifyContent: 'center',
                  color: '#000'
                }}
              >
                <Skeleton height={16} width="50%" />
                <Skeleton height={14} width="40%" />
              </AppStack>
            </AppFlexbox>
          </AppFlexbox>

          <AppStack
            style={{
              flex: 1,
              alignSelf: 'start',
              placeItems: 'end',
              marginLeft: 'auto',
              gap: 10
            }}
          >
            <AppStack style={{ gap: 0, alignItems: 'end' }}>
              <Skeleton height={20} width={100} />
            </AppStack>

            {!isOrderHistory && (
              <AppFlexbox style={{ cursor: 'pointer', gap: 10 }}>
                <Skeleton height={15} width={80} />
              </AppFlexbox>
            )}
          </AppStack>
        </AppFlexbox>
      </AppStack>
    </AppCard>
  );
};

WsCheckoutCartProductCard.propTypes = {
  ecomStoreCartProduct: PropTypes.object,
  isOrderHistory: PropTypes.bool,
  latestVersionEcomStoreCartProduct: PropTypes.object,
  lineItem: PropTypes.object,
  loading: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onCustomize: PropTypes.func,
  onNavigate: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveUnavailableProducts: PropTypes.func,
  onUpdate: PropTypes.func,
  productUrl: PropTypes.string,
  submitting: PropTypes.bool
};

export default WsCheckoutCartProductCard;
