import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import VendorOrderTable from './VendorOrderTable';
import VendorOrderViews from '../orders/VendorOrderViews';

const VendorOrdersView = ({ isBulkOrders }) => (
  <Routes>
    <Route element={<VendorOrderViews />} path="/:pkEcomOrder/*" />
    <Route
      element={<VendorOrderTable isBulkOrders={isBulkOrders} />}
      path="/"
    />
    <Route element={<Navigate replace to="/vendor/orders" />} path="*" />
  </Routes>
);

VendorOrdersView.propTypes = { isBulkOrders: PropTypes.bool };

export default VendorOrdersView;
