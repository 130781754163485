import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Divider,
  Radio,
  Select,
  Textarea,
  Tooltip
} from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import ResponsiveModal from '../../common/ResponsiveModal';
import OrderProductSelect from './OrderProductSelect';
import {
  calculateVendorOrderRefundValues,
  translateVendorOrderData
} from '../../../helpers/vendorHelper';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import {
  calculateStoreOrderRefundValues,
  translateStoreOrderData
} from '../../../helpers/storeHelper';
import OrderCancelProductSummary from './OrderCancelProductSummary';
import { currencyFormat, singularPluralFormat } from '../../../helpers/format';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import VendorOrderProductPreview from './VendorOrderProductPreview';
import { triggerNotification } from '../../../helpers/notification';
import CustomNumericFormat from '../../common/CustomNumericFormat';
import OrderRefundPaymentSummary from './OrderRefundPaymentSummary';
import AppCheckbox from '../../common/AppCheckbox';
import OrderRefundConfirmSummary from './OrderRefundConfirmSummary';

const CANCEL_REASON_ENUM = {
  CUSTOMER: 'customer',
  INVENTORY: 'inventory',
  OTHER: 'other'
};

const CANCEL_REASONS = [
  {
    label: 'Customer requested',
    value: CANCEL_REASON_ENUM.CUSTOMER
  },
  {
    label: 'Items unavailable',
    value: CANCEL_REASON_ENUM.INVENTORY
  },
  {
    label: 'Other',
    value: CANCEL_REASON_ENUM.OTHER
  }
];

const OrderCancelModal = ({
  isOpen,
  onClose,
  ecomOrder,
  isProductCancel,
  isVendorView
}) => {
  const { state: authState } = useContext(AuthContext);
  const {
    cancelVendorOrder,
    fetchVendorOrder,
    fetchVendorSetupSummary
  } = useContext(VendorContext);
  const {
    cancelStoreOrder,
    fetchStoreOrder,
    fetchStoreSetupSummary
  } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    ecomOrderProducts: [],
    reason: CANCEL_REASONS[0].value,
    customReason: '',
    refundAmount: 0,
    unlockRefundAmount: false,
    skipEmailNotice: false,
    showRefundView: false,
    refundPayment: true,
    showConfirm: false,
    loading: false
  });
  const orderData = isVendorView
    ? translateVendorOrderData(ecomOrder)
    : translateStoreOrderData(ecomOrder);
  const { ecomOrderProducts: productData } = isVendorView
    ? orderData
    : {
        ...orderData,
        ecomOrderProducts: orderData.ecomOrderProductsOriginal
      };
  const refundValues = isVendorView
    ? calculateVendorOrderRefundValues(
        ecomOrder,
        productData,
        formState.ecomOrderProducts
      )
    : calculateStoreOrderRefundValues(
        ecomOrder,
        productData,
        formState.ecomOrderProducts,
        formState.unlockRefundAmount
      );
  const {
    selectedItemCount,
    selectedItemsTotalUnitCost,
    selectedItemsStoreProfit,
    selectedItemsRefundValue,
    hasRefundedAllItems,
    tieredPricing,
    availableRefundAmount,
    maxRefundAmountWithoutLosses
  } = refundValues;
  const pendingProducts = productData.filter((f) => f.amountRemaining > 0);
  const firstPayment = isVendorView
    ? null
    : ecomOrder?.ecomOrderPayments.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      )[0];
  const accountPayee = firstPayment?.ecomStorePaymentProvider?.accountId;
  const isEscrowPayment = !accountPayee;

  const isFullyCancelled =
    productData.length > 0 &&
    productData.every((p) => p.cancelledCount >= p.quantity);

  useEffect(() => {
    if (isOpen) {
      setFormState({
        ecomOrderProducts: pendingProducts.map((p) => ({
          pkEcomOrderProduct: p.pkEcomOrderProduct,
          count: isProductCancel ? 0 : p.amountRemaining,
          unitCost: p.unitCost
        })),
        reason: CANCEL_REASONS[0].value,
        customReason: '',
        refundAmount: availableRefundAmount,
        unlockRefundAmount: false,
        skipEmailNotice: false,
        showRefundView: false,
        refundPayment: true,
        showConfirm: false,
        loading: false
      });
    }
  }, [isOpen, ecomOrder]);

  useEffect(() => {
    const amount = Math.min(selectedItemsTotalUnitCost, availableRefundAmount);
    if (amount !== formState.refundAmount) {
      setFormState((c) => ({
        ...c,
        refundAmount: amount
      }));
    }
  }, [availableRefundAmount]);

  return (
    <ResponsiveModal
      formSectionProps={{
        padding: 0,
        cancelTitle:
          !formState.showConfirm && !formState.showRefundView
            ? 'Close'
            : 'Back',
        onCancel:
          !formState.showConfirm && !formState.showRefundView
            ? onClose
            : () => {
                if (formState.showConfirm && !isProductCancel) {
                  setFormState({
                    ...formState,
                    showConfirm: false,
                    showRefundView: true
                  });
                }
                else {
                  setFormState({
                    ...formState,
                    showConfirm: false,
                    showRefundView: false
                  });
                }
              },
        submitColor: !formState.showConfirm ? 'dark' : 'red',
        submitTitle: !formState.showConfirm
          ? 'Continue'
          : isProductCancel
          ? `Yes, cancel ${singularPluralFormat(
              selectedItemCount,
              'product',
              'products'
            )}`
          : 'Yes, cancel order',
        isSubmitDisabled: selectedItemCount === 0,
        isSubmitHidden: isFullyCancelled,
        isLoading: formState.loading,
        onSubmit: () => {
          if (formState.showRefundView) {
            setFormState({
              ...formState,
              showRefundView: false,
              showConfirm: true
            });
          }
          else if (!formState.showConfirm) {
            if (isProductCancel || ecomOrder.isBulkShipment) {
              setFormState({
                ...formState,
                showConfirm: true
              });
            }
            else {
              setFormState({
                ...formState,
                showRefundView: true
              });
            }
          }
          else {
            setFormState({
              ...formState,
              loading: true
            });
            const includeRefund = !isProductCancel && formState.refundPayment;
            const cancelFunc = isVendorView
              ? cancelVendorOrder
              : cancelStoreOrder;
            cancelFunc(
              isVendorView ? authState.pkEcomVendor : authState.pkEcomStore,
              {
                pkEcomOrder: ecomOrder.pkEcomOrder,
                cancelOrder: !isProductCancel,
                refundPayment: includeRefund,
                refundAmount: includeRefund ? formState.refundAmount : 0,
                unlockRefundAmount: formState.unlockRefundAmount,
                reason:
                  formState.reason === CANCEL_REASON_ENUM.OTHER
                    ? formState.customReason
                    : formState.reason,
                skipEmailNotice: formState.skipEmailNotice,
                ecomOrderReturnProducts: formState.ecomOrderProducts.filter(
                  (p) => p.count > 0
                )
              },
              () => {
                triggerNotification(
                  `Cancelled order #${ecomOrder.pkEcomOrder}`,
                  'success'
                );
                if (isVendorView) {
                  fetchVendorOrder(authState.pkEcomVendor, {
                    pkEcomOrder: ecomOrder.pkEcomOrder
                  });
                  fetchVendorSetupSummary(authState.pkEcomVendor, {
                    skipLoading: true
                  });
                }
                else {
                  fetchStoreOrder(authState.pkEcomStore, {
                    pkEcomOrder: ecomOrder.pkEcomOrder
                  });
                  fetchStoreSetupSummary(authState.pkEcomStore, {
                    skipLoading: true
                  });
                }

                onClose();
              },
              (e) => {
                setFormState({
                  ...formState,
                  loading: false
                });
                triggerNotification(e);
              }
            );
          }
        }
      }}
      isOpen={isOpen}
      onClose={onClose}
      title={
        isProductCancel
          ? formState.showConfirm
            ? `Cancel ${singularPluralFormat(
                selectedItemCount,
                'product',
                'products'
              )}?`
            : 'Cancel products'
          : 'Cancel order'
      }
    >
      {isFullyCancelled ? (
        <AppStack style={{ padding: 16, gap: 8 }}>
          <AppText
            style={{
              fontSize: 14,
              fontStyle: 'italic',
              fontWeight: 500,
              color: '#666',
              textAlign: 'center'
            }}
          >
            This order has already been fully cancelled.
          </AppText>
        </AppStack>
      ) : formState.showConfirm ? (
        <OrderRefundConfirmSummary
          ecomOrder={ecomOrder}
          hasRefundedAllItems={hasRefundedAllItems}
          isProductCancel={isProductCancel}
          isVendorView={isVendorView}
          maxRefundAmountWithoutLosses={maxRefundAmountWithoutLosses}
          productData={pendingProducts}
          refundAmount={formState.refundPayment ? formState.refundAmount : null}
          selectedItemCount={selectedItemCount}
          selectedItemsRefundValue={selectedItemsRefundValue}
          selectedProducts={formState.ecomOrderProducts}
          tieredPricing={tieredPricing}
        >
          <Divider />

          <AppStack style={{ padding: 16 }}>
            <Select
              data={CANCEL_REASONS}
              description="The reason you provide will be included in the customer's email notification."
              disabled={formState.loading}
              label="Reason for cancellation"
              onChange={(v) => setFormState({ ...formState, reason: v })}
              value={formState.reason}
            />

            {formState.reason === CANCEL_REASON_ENUM.OTHER && (
              <Textarea
                autosize
                disabled={formState.loading}
                label="Custom reason"
                minRows={3}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    customReason: e.currentTarget.value
                  })
                }
                required
                value={formState.customReason}
              />
            )}

            <AppStack style={{ gap: 5 }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Notification
              </AppText>
              <AppCheckbox
                checked={!formState.skipEmailNotice}
                disabled={formState.loading}
                label="Send email notification to customer"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    skipEmailNotice: !formState.skipEmailNotice
                  })
                }
              />
            </AppStack>
          </AppStack>
        </OrderRefundConfirmSummary>
      ) : isProductCancel ? (
        <AppStack style={{ gap: 0 }}>
          <OrderCancelProductSummary
            isVendorView={isVendorView}
            platformFee={hasRefundedAllItems ? tieredPricing : null}
            selectedItemCount={selectedItemCount}
            selectedItemsRefundValue={selectedItemsRefundValue}
            selectedItemsStoreProfit={selectedItemsStoreProfit}
            selectedItemsTotalUnitCost={selectedItemsTotalUnitCost}
          />
          <Divider />

          <AppStack style={{ gap: 16, padding: 16 }}>
            {ecomOrder.isBulkShipment && (
              <Alert
                color="dodgerblue"
                style={{ padding: 8 }}
                variant="outline"
              >
                <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
                  <AppStack>
                    <AlertCircle color="dodgerblue" size={18} />
                  </AppStack>
                  <AppText
                    style={{
                      fontSize: 14,
                      fontWeight: 500
                    }}
                  >
                    Refunds are only available to the selected customer(s).
                    Please choose carefully.
                  </AppText>
                </AppFlexbox>
              </Alert>
            )}

            <OrderProductSelect
              disabled={formState.loading}
              isBulkShipment={ecomOrder.isBulkShipment}
              onChange={(ecomOrderProducts) =>
                setFormState({
                  ...formState,
                  ecomOrderProducts: formState.ecomOrderProducts.reduce(
                    (r, c) => {
                      const found = ecomOrderProducts.find(
                        (p) => p.pkEcomOrderProduct === c.pkEcomOrderProduct
                      );
                      return found
                        ? [
                            ...r,
                            {
                              ...c,
                              ...found
                            }
                          ]
                        : [...r, c];
                    },
                    []
                  )
                })
              }
              productData={pendingProducts}
              selectedProducts={formState.ecomOrderProducts}
            />
          </AppStack>
        </AppStack>
      ) : formState.showRefundView ? (
        <AppStack style={{ gap: 0 }}>
          <AppStack style={{ gap: 8, padding: 16 }}>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Refund payment
            </AppText>
            <Radio
              checked={formState.refundPayment}
              label={`Refund from available ${currencyFormat(
                availableRefundAmount / 100
              )} balance`}
              onChange={(v) => {
                if (v.currentTarget.checked) {
                  setFormState({
                    ...formState,
                    refundPayment: true
                  });
                }
              }}
            />
            <Radio
              checked={!formState.refundPayment}
              description={
                !formState.refundPayment
                  ? `${
                      isVendorView ? 'The merchant' : 'Your customer'
                    } will not receive an automatic refund. You will need to process their refund manually.`
                  : ''
              }
              label="Refund later"
              onChange={(v) => {
                if (v.currentTarget.checked) {
                  setFormState({
                    ...formState,
                    refundPayment: false
                  });
                }
              }}
            />
          </AppStack>

          {formState.refundPayment && (
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ gap: 16, padding: 16, paddingTop: 0 }}>
                <AppStack style={{ gap: 3 }}>
                  <AppText style={{ fontSize: 14 }}>Refund amount</AppText>
                  <CustomNumericFormat
                    disabled={formState.loading || availableRefundAmount <= 0}
                    onBlur={(e) => {
                      if (formState.refundAmount > availableRefundAmount) {
                        setFormState({
                          ...formState,
                          refundAmount: availableRefundAmount
                        });
                      }
                      else if (
                        !formState.refundAmount ||
                        formState.refundAmount < 0
                      ) {
                        setFormState({
                          ...formState,
                          refundAmount: 0
                        });
                      }
                    }}
                    onValueChange={(values) => {
                      if (values.floatValue * 100 !== formState.refundAmount) {
                        setFormState({
                          ...formState,
                          refundAmount: Math.round(values.floatValue * 100)
                        });
                      }
                    }}
                    style={{ flex: 1, maxWidth: 250 }}
                    value={formState.refundAmount / 100}
                  />
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    {currencyFormat(availableRefundAmount / 100)} available for
                    refund
                  </AppText>
                </AppStack>

                {!isVendorView && (
                  <Tooltip
                    disabled={!isEscrowPayment}
                    label="Funds cannot be unlocked for escrow payments."
                    multiline
                    w={250}
                    withArrow
                  >
                    <AppFlexbox>
                      <AppCheckbox
                        checked={formState.unlockRefundAmount}
                        description="Lift restrictions on available refund amounts by covering product costs and service fees, even at a loss."
                        disabled={
                          !authState.userData?.isAdmin && isEscrowPayment
                        }
                        label="Unlock refund funds"
                        onChange={() =>
                          setFormState({
                            ...formState,
                            unlockRefundAmount: !formState.unlockRefundAmount
                          })
                        }
                      />
                    </AppFlexbox>
                  </Tooltip>
                )}
              </AppStack>
              {!isVendorView && (
                <>
                  <Divider label="REFUND SUMMARY" />
                  <AppStack style={{ gap: 16, padding: 16 }}>
                    <OrderRefundPaymentSummary
                      amountPaidInCents={
                        ecomOrder.checkoutBalance.amountPaidInCents
                      }
                      unlockRefundAmount={formState.unlockRefundAmount}
                      {...refundValues}
                    />
                  </AppStack>
                </>
              )}
            </AppStack>
          )}
        </AppStack>
      ) : (
        <AppStack style={{ gap: 0 }}>
          <AppStack style={{ gap: 0 }}>
            <OrderCancelProductSummary
              description={
                isVendorView
                  ? 'The remaining products listed below will be cancelled.'
                  : 'The remaining products listed below will be cancelled, and you will be reimbursed for the vendor fulfillment costs.'
              }
              isVendorView={isVendorView}
              platformFee={hasRefundedAllItems ? tieredPricing : null}
              selectedItemCount={selectedItemCount}
              selectedItemsRefundValue={selectedItemsRefundValue}
              selectedItemsStoreProfit={selectedItemsStoreProfit}
              selectedItemsTotalUnitCost={selectedItemsTotalUnitCost}
            />
          </AppStack>
          <Divider
            label={`CANCELLING ${singularPluralFormat(
              selectedItemCount,
              'PRODUCT',
              'PRODUCTS'
            )}`}
          />
          <AppStack style={{ padding: 16, gap: 8 }}>
            {formState.ecomOrderProducts
              .filter((p) => p.count > 0)
              .reduce((r, c) => {
                const product = pendingProducts.find(
                  (pr) => pr.pkEcomOrderProduct === c.pkEcomOrderProduct
                );

                const existing = r.find((e) => e.uuid === product?.uuid);
                if (existing) {
                  existing.count += c.count;
                }
                else {
                  r.push({
                    uuid: product?.uuid,
                    count: c.count,
                    product
                  });
                }
                return r;
              }, [])
              .map((p, index) => (
                <React.Fragment key={p.uuid}>
                  {index > 0 && <Divider />}

                  <AppFlexbox style={{ gap: 16 }}>
                    <VendorOrderProductPreview
                      hideStatus
                      product={p.product}
                      quantityIndicatorAmount={p.count}
                      showCancelWarnings={!ecomOrder.isBulkShipment}
                    />
                  </AppFlexbox>
                </React.Fragment>
              ))}
          </AppStack>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

OrderCancelModal.propTypes = {
  ecomOrder: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isProductCancel: PropTypes.bool,
  isVendorView: PropTypes.bool
};

export default OrderCancelModal;
