import {
  AddressBook,
  Download,
  Eye,
  EyeCheck,
  Mail,
  Photo,
  Plus,
  Shirt,
  Stack,
  World
} from 'tabler-icons-react';

const setupGuideConfig = {
  title: 'Setup your vendor',
  intro: 'Here is a guide to help you get started with your vendor account.',
  description:
    'Follow the steps below to to help you setup your vendor account and start selling your products.',
  isCompleted: (state) =>
    state.ecomVendorSetupSummary.value &&
    !state.ecomVendorSetupSummary.value?.ecomVendor.sandboxEnabled,
  sections: [
    {
      value: 'products',
      title: 'Product setup',
      steps: [
        {
          value: 'import-product',
          title: 'Import your first product',
          description:
            'Write a description and set the price to start selling.',
          icon: Shirt,
          primaryButton: {
            title: 'Add product',
            action: 'link',
            url: '/vendor/products/new',
            icon: Plus
          },
          secondaryButton: {
            title: 'Import',
            action: 'link',
            url: '/vendor/products?modal=import',
            icon: Download
          },
          required: true,
          isCompleted: (state) =>
            state.ecomVendorSetupSummary.value?.hasProducts ||
            state.ecomVendorProducts.value.length > 0
        },
        {
          value: 'create-collection',
          title: 'Create your first collection',
          description:
            'Organize your products into collections to make it easier for merchants to find and add them to their stores.',
          subDescription: 'You can skip this step and complete it later.',
          icon: Stack,
          primaryButton: {
            title: 'Create collection',
            icon: Plus,
            action: 'link',
            url: '/vendor/collections/new'
          },
          secondaryButton: {
            title: 'Skip step',
            action: 'skip'
          },
          required: false,
          isCompleted: (state) =>
            state.ecomVendorSetupSummary.value?.hasCollections ||
            state.ecomVendorCollections.value.length > 0
        }
      ]
    },
    {
      value: 'settings',
      title: 'Vendor settings',
      steps: [
        {
          value: 'add-logo',
          title: 'Add your logo',
          description:
            'Update your vendor information to help merchants discover you. Start by adding a logo.',
          icon: Photo,
          primaryButton: {
            title: 'Add logo',
            action: 'link',
            url: '/vendor/settings?modal=logo',
            icon: Photo
          },
          required: true,
          isCompleted: (state) =>
            state.ecomVendorSetupSummary.value &&
            !!state.ecomVendorSetupSummary.value?.ecomVendor.logoImageUrl
        },
        {
          value: 'add-billing-address',
          title: 'Add billing address',
          description:
            'Setup your billing address to start receiving payments.',
          icon: Photo,
          primaryButton: {
            title: 'Add address',
            action: 'link',
            url: '/vendor/settings?modal=billing_address',
            icon: Mail
          },
          required: true,
          isCompleted: (state) =>
            state.ecomVendorSetupSummary.value &&
            !!state.ecomVendorSetupSummary.value?.ecomVendor.ecomCustomerAddress
        },
        {
          value: 'set-provinces',
          title: 'Set provinces',
          description: 'Set the provinces you want to sell your products to.',
          icon: World,
          primaryButton: {
            title: 'Set provinces',
            icon: World,
            action: 'link',
            url: '/vendor/settings?modal=provinces'
          },
          required: true,
          isCompleted: (state) =>
            state.ecomVendorSetupSummary.value?.ecomVendor.ecomVendorProvinces
              .length > 0
        }
      ]
    },
    {
      value: 'launch',
      title: 'Launch your vendor',
      steps: [
        {
          value: 'turn-off-sandbox',
          title: 'Turn off sandbox mode',
          description:
            'Turn off sandbox mode to make your vendor live and start receiving orders.',
          icon: EyeCheck,
          primaryButton: {
            title: 'Launch vendor',
            icon: Eye,
            action: 'link',
            url: '/vendor/settings?modal=sandbox'
          },
          required: false,
          disableAfterCompletion: true,
          isCompleted: (state) =>
            state.ecomVendorSetupSummary.value &&
            !state.ecomVendorSetupSummary.value?.ecomVendor.sandboxEnabled
        }
      ]
    }
  ]
};

export { setupGuideConfig };
