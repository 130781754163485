import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Card, Checkbox, Divider } from '@mantine/core';
import { AlertCircle, CircleCheck } from 'tabler-icons-react';
import ResponsiveModal from './ResponsiveModal';
import AppStack from './AppStack';
import AppText from './AppText';
import AppFlexbox from './AppFlexbox';
import { CURRENCY_TYPE_LIST } from '../../config/constants';
import * as classes from '../../styles/nestedStyles.module.css';

const ChangeCurrencyModal = ({
  isOpen,
  onClose,
  onConfirm,
  fkCurrencyType,
  sandboxEnabled
}) => {
  const [selectedFkCurrencyType, setSelectedFkCurrencyType] = useState(null);
  const selectedCurrency =
    CURRENCY_TYPE_LIST.find((c) => c.value === fkCurrencyType) ??
    CURRENCY_TYPE_LIST[0];

  useEffect(() => {
    if (isOpen) {
      setSelectedFkCurrencyType(null);
    }
  }, [isOpen]);

  return (
    <ResponsiveModal
      formSectionProps={{
        isSubmitDisabled: !sandboxEnabled,
        onCancel: onClose,
        onSubmit: () => {
          onConfirm(selectedFkCurrencyType);
        },
        submitTitle: 'Confirm'
      }}
      isOpen={isOpen}
      onClose={onClose}
      title="Change Currency"
    >
      {selectedFkCurrencyType ? (
        <AppStack style={{ gap: 20 }}>
          {!sandboxEnabled ? (
            <Alert
              color="yellow"
              icon={<AlertCircle />}
              title="Review pricing"
              variant="outline"
            >
              <AppStack>
                <AppText style={{ fontSize: 14 }}>
                  You must switch to sandbox mode before changing your currency
                  to ensure that your pricing is correct. You can switch back to
                  live mode once you have reviewed and updated your pricing.
                </AppText>
              </AppStack>
            </Alert>
          ) : (
            <>
              <AppText style={{ marginTop: 20, fontSize: 14 }}>
                Are you sure you want to change the currency to{' '}
                <b>{selectedCurrency?.fullLabel}</b>? This will be the currency
                used for all future transactions.
              </AppText>
              <Alert
                color="yellow"
                icon={<AlertCircle />}
                title="Review pricing"
                variant="outline"
              >
                <AppStack>
                  <AppText style={{ fontSize: 14 }}>
                    Please note that changing the currency will require you to
                    review and update any pricing you already have set up.
                  </AppText>

                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    You must ensure that your Stripe account is set up to accept{' '}
                    <b>{selectedCurrency?.label}</b> before changing.
                  </AppText>

                  <Checkbox
                    label="I acknowledge that pricing and my Stripe account settings need to be review upon changing."
                    required
                  />
                </AppStack>
              </Alert>
            </>
          )}
        </AppStack>
      ) : (
        <AppStack style={{ gap: 20 }}>
          <AppStack>
            <AppText style={{ fontSize: 14 }}>
              Please select the currency you would like to use for all future
              transactions, this{' '}
              <b>must match the currency of your Stripe account</b>.
            </AppText>
          </AppStack>
          <Card radius="md" shadow="none" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0, padding: 0 }}>
              {CURRENCY_TYPE_LIST.filter((c) => !c.hidden).map((c, index) => {
                const selected = c.value === fkCurrencyType;

                return (
                  <React.Fragment key={c.value}>
                    {index !== 0 && <Divider />}
                    <AppFlexbox
                      className={classes['hover-action-card']}
                      onClick={() => {
                        if (selected) {
                          onClose();
                        }
                        else {
                          setSelectedFkCurrencyType(c.value);
                        }
                      }}
                      style={{
                        padding: '24px 16px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: selected
                          ? 'rgba(56, 56, 56, 0.1)'
                          : 'unset'
                      }}
                    >
                      <AppFlexbox style={{ alignItems: 'center' }}>
                        <AppStack
                          style={{
                            height: 35,
                            width: 35,
                            borderRadius: 8,
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden'
                          }}
                        >
                          {selectedCurrency.value === c.value && (
                            <CircleCheck color="green" size={32} />
                          )}
                        </AppStack>
                        <AppText>{c.fullLabel}</AppText>
                      </AppFlexbox>

                      {selected && (
                        <AppText
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: 'green'
                          }}
                        >
                          Selected
                        </AppText>
                      )}
                    </AppFlexbox>
                  </React.Fragment>
                );
              })}
            </AppStack>
          </Card>

          <AppText style={{ fontSize: 12, color: '#666', textAlign: 'center' }}>
            You can change this at any time in your settings.
          </AppText>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

ChangeCurrencyModal.propTypes = {
  fkCurrencyType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  sandboxEnabled: PropTypes.bool
};

export default ChangeCurrencyModal;
