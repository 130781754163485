import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Button, Menu } from '@mantine/core';
import { Dots, Download, Pencil, Plus, Shirt } from 'tabler-icons-react';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import TableView from '../../common/TableView';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';
import { triggerNotification } from '../../../helpers/notification';
import HeaderView from '../../common/HeaderView';
import AppStack from '../../common/AppStack';
import {
  useGlobalFormState,
  useModalState,
  usePaginationFilter
} from '../../../helpers/hooks';
import AppMenu from '../../common/AppMenu';
import VendorInventoryAdjustQuantityModal from './VendorInventoryAdjustQuantityModal';
import CustomNumberInput from '../../common/CustomNumberInput';
import ExportCsvModal from '../bulkEdit/ExportCsvModal';
import { vendorProductInventoryCsvConfig } from './vendorProductCsvConfig';
import ImportCsvModal from '../bulkEdit/ImportCsvModal';
import AppText from '../../common/AppText';
import ConfirmModal from '../../common/ConfirmModal';

const TABLE_COLUMNS = [
  {
    label: 'Product',
    value: 'name',
    sortable: true
  },
  {
    label: 'SKU',
    value: 'sku',
    sortable: true
  },
  {
    label: 'Available',
    value: 'quantity',
    sortable: true
  }
];

const VendorInventoryTable = () => {
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const hasReinitialized = useRef(false);
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    fetchPaginatedVendorProductVariants,
    updateVendorProducts,
    fetchVendorProductExportData
  } = useContext(VendorContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const {
    hasInitialized,
    hasUnsavedChanges,
    formState,
    isSubmitting,
    setFormState,
    resetFormState,
    discardFormChanges,
    submitFormState,
    ConfirmDiscardModal
  } = useGlobalFormState(
    { ecomVendorProductVariants: [] },
    { confirmDiscard: true, containerWidth: 950 }
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    pageIndex,
    totalCount,
    totalPages,
    data: variantData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange
  } = usePaginationFilter(
    {
      search: '',
      fkEcomCategory: null,
      fkEcomProductStatus: null
    },
    (newFilter) => {
      fetchPaginatedVendorProductVariants(
        authState.pkEcomVendor,
        newFilter,
        null,
        (error) => triggerNotification(error)
      );
    },
    state.paginatedEcomVendorProductVariants
  );
  const isFetching = !hasFetched.current || dataLoading;
  const loading = !hasInitialized || !hasReinitialized.current || isFetching;
  const selectedVariants = variantData.filter((e) =>
    selectedRows.includes(e.pkEcomVendorProductVariant)
  );
  const selectedProducts = selectedVariants.reduce(
    (r, c) =>
      !r.find((p) => p.productUuid === c.productUuid)
        ? [...r, { productUuid: c.productUuid, name: c.productName }]
        : r,
    []
  );

  useEffect(() => {
    if (authState.pkEcomVendor) {
      fetchPaginatedVendorProductVariants(
        authState.pkEcomVendor,
        { trackQuantity: true },
        null,
        (error) => triggerNotification(error)
      );
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor]);

  useEffect(() => {
    if (!isFetching) {
      resetFormState({ ecomVendorProductVariants: [...variantData] });
      hasReinitialized.current = true;
    }
    else {
      hasReinitialized.current = false;
    }
  }, [isFetching, variantData]);

  const onSaveProductVariants = () => {
    const changedVariants = formState.ecomVendorProductVariants.filter((e) =>
      variantData.find(
        (f) =>
          f.pkEcomVendorProductVariant === e.pkEcomVendorProductVariant &&
          f.quantity !== e.quantity
      )
    );

    const variantProducts = changedVariants.reduce(
      (r, c) =>
        !r.find((f) => f.productUuid === c.productUuid)
          ? [...r, { productUuid: c.productUuid }]
          : r,
      []
    );

    submitFormState((formData, onErrorCallback) =>
      updateVendorProducts(
        authState.pkEcomVendor,
        {
          ecomVendorProducts: variantProducts.map((p) => ({
            uuid: p.productUuid,
            ecomVendorProductVariants: changedVariants.filter(
              (v) => v.productUuid === p.productUuid
            )
          }))
        },
        () => {
          fetchPaginatedVendorProductVariants(
            authState.pkEcomVendor,
            {
              ...filter,
              skipLoading: true
            },
            () => {
              triggerNotification('Inventory updated!', 'success');
            },
            () => {
              triggerNotification('Inventory updated!', 'success');
            }
          );
        },
        onErrorCallback
      )
    );
  };

  const onFilter = (
    newFilter,
    skipDebounce = false,
    page = null,
    skipSavedChanges = false
  ) => {
    if (hasUnsavedChanges && !skipSavedChanges) {
      onOpenModal(VIEW_ACTIONS_ENUM.DISCARD, {
        ...newFilter,
        page,
        trackQuantity: true,
        appendResults: false,
        skipStateUpdate: false,
        exportResponse: false
      });
    }
    else {
      onFilterChange(
        {
          ...newFilter,
          trackQuantity: true,
          appendResults: false
        },
        skipDebounce,
        page
      );
    }
  };

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: 'Inventory',
          to: '/vendor/inventory'
        }
      ]}
      rightSection={
        totalCount > 0 && (
          <>
            <Button
              color="dark"
              onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.EXPORT)}
              radius="md"
              size="compact-md"
              style={{ fontSize: 14 }}
              variant="outline"
            >
              Export
            </Button>
            <Button
              color="dark"
              onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.IMPORT)}
              radius="md"
              size="compact-md"
              style={{ fontSize: 14 }}
              variant="outline"
            >
              Import
            </Button>
          </>
        )
      }
    >
      <TableView
        columns={TABLE_COLUMNS}
        disabledContent={{
          title: 'No inventory products',
          description:
            'Only products that track inventory will be visible and manageable from here. Start by adding a product that tracks inventory to your store. ',
          icon: <Shirt color="#000" size={125} />,
          primaryButton: {
            label: 'Add product',
            icon: <Plus size={18} />,
            link: '/vendor/products/new?from=inventory'
          },
          secondaryButton: {
            label: 'Import',
            icon: <Download size={18} />,
            link: '/vendor/products?modal=import'
          }
        }}
        emptyMessage="No products found."
        enableInputsWhenLoading
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by product',
            value: filter.search || '',
            onChange: (value) => {
              onFilter({
                ...filter,
                search: value
              });
            }
          },
          {
            key: 2,
            label: 'SKU',
            placeholder: 'Filter by SKU',
            value: filter.sku,
            onChange: (sku) =>
              onFilter({
                ...filter,
                sku
              })
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={loading}
        noSort
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={() => onFilter(filter, true, pageIndex)}
        onSelect={setSelectedRows}
        pageIndex={pageIndex}
        rows={formState.ecomVendorProductVariants.map((a) => ({
          key: a.pkEcomVendorProductVariant,
          columns: [
            {
              key: 1,
              uuid: a.uuid,
              label: a.productName,
              onClick: () =>
                navigate(`/vendor/products/${a.productUuid}?from=inventory`),
              weight: 500,
              subLabel: a.ecomVendorProductVariantOptions
                .map((op) => op.value)
                .join(' / '),
              onSubLabelClick: (e) =>
                navigate(
                  `/vendor/products/${a.productUuid}/variants/${a.uuid}?from=inventory`
                ),
              src: a.previewImage ?? a.productPreviewImage ?? null,
              showImage: true
            },
            {
              key: 2,
              label: a.sku
            },
            {
              key: 3,
              label: a.quantity,
              subLabel: isSubmitting,
              sortValueType: 'number',
              customComponent: (
                <CustomNumberInput
                  decimalScale={0}
                  disabled={isSubmitting}
                  fixedDecimalScale
                  min={0}
                  onBlur={() => {
                    if (!a.quantity) {
                      setFormState({
                        ...formState,
                        ecomVendorProductVariants: formState.ecomVendorProductVariants.map(
                          (e) =>
                            e.pkEcomVendorProductVariant ===
                            a.pkEcomVendorProductVariant
                              ? { ...e, quantity: 0 }
                              : e
                        )
                      });
                    }
                  }}
                  onChange={(value) => {
                    if (value !== a.quantity) {
                      setFormState({
                        ...formState,
                        ecomVendorProductVariants: formState.ecomVendorProductVariants.map(
                          (e) =>
                            e.pkEcomVendorProductVariant ===
                            a.pkEcomVendorProductVariant
                              ? { ...e, quantity: value }
                              : e
                        )
                      });
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  rightSectionProps={{
                    onClick: (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}
                  style={{ flex: 1 }}
                  value={a.quantity}
                />
              )
            }
          ]
        }))}
        selectActionSection={
          <AppMenu
            control={
              <ActionIcon color="#ced4da" size="lg" variant="outline">
                <Dots color="#000" />
              </ActionIcon>
            }
            styles={{ dropdown: { padding: 0 } }}
          >
            <AppStack style={{ padding: 2, gap: 1 }}>
              <Menu.Item
                leftSection={<Pencil size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  navigate(
                    `/vendor/products/bulk-edit?ids=${selectedProducts
                      .map((f) => f.productUuid)
                      .join(',')}&variants=${selectedVariants
                      .map((m) => m.uuid)
                      .join(',')}&from=inventory`
                  );
                }}
                visibleFrom="xsm"
              >
                Bulk edit
              </Menu.Item>
              <Menu.Item
                leftSection={<Pencil size={18} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (hasUnsavedChanges) {
                    discardFormChanges((wasDiscarded) => {
                      if (wasDiscarded) {
                        onOpenModal(VIEW_ACTIONS_ENUM.EDIT);
                      }
                    });
                  }
                  else {
                    onOpenModal(VIEW_ACTIONS_ENUM.EDIT);
                  }
                }}
              >
                Update quantities
              </Menu.Item>
            </AppStack>
          </AppMenu>
        }
        selectedRows={selectedVariants.map((v) => v.pkEcomVendorProductVariant)}
        showDisabledContent={!loading && totalCount === 0}
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Inventory"
        totalCount={totalCount}
        totalPages={totalPages}
      />
      {ConfirmDiscardModal}

      <AppStack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSaveProductVariants();
        }}
        style={{ display: 'none' }}
      >
        <Button id="save-button" type="submit" />
      </AppStack>

      <ImportCsvModal
        descriptor="inventory"
        importConfig={vendorProductInventoryCsvConfig}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.IMPORT
        }
        onClose={onCloseModal}
        onImport={({ data }, onSuccessCallback, onErrorCallback) => {
          updateVendorProducts(
            authState.pkEcomVendor,
            {
              ecomVendorProducts: data
                .map((d) => ({
                  uuid: d.uuid,
                  ecomVendorProductVariants: d.ecomVendorProductVariants
                    .map((v) => ({
                      uuid: v.uuid,
                      sku: v.sku,
                      trackQuantity: !!v.quantity,
                      quantity: v.quantity ? v.quantity : 0
                    }))
                    .filter((f) => f.uuid)
                }))
                .filter((f) => f.uuid)
            },
            () => {
              onSuccessCallback();
              onFilter(filter, true, pageIndex);
            },
            onErrorCallback
          );
        }}
        onImportCsvData={(data, onSuccesCallback, onErrorCallback) => {
          const urlHandles = data.map((i) => i.urlHandle);
          if (urlHandles.length > 0) {
            fetchVendorProductExportData(
              {
                fkEcomVendor: authState.pkEcomVendor,
                urlHandles,
                skipStateUpdate: true
              },
              (products) => {
                onSuccesCallback(
                  data.map((d) => {
                    const existingProduct = products.find(
                      (p) => p.urlHandle === d.urlHandle
                    );
                    return {
                      ...d,
                      uuid: existingProduct?.uuid ?? null,
                      foundExisting: !!existingProduct,
                      ecomVendorProduct: existingProduct ?? null,
                      ecomVendorProductVariants: [
                        ...d.ecomVendorProductVariants.map((v) => {
                          const existingVariant = existingProduct?.ecomVendorProductVariants.find(
                            (ev) =>
                              ev.ecomVendorProductVariantOptions.every((op) =>
                                v.ecomVendorProductVariantOptions.some(
                                  (vop) =>
                                    vop.name.toLowerCase() ===
                                      op.name.toLowerCase() &&
                                    vop.value.toLowerCase() ===
                                      op.value.toLowerCase()
                                )
                              )
                          );
                          return {
                            ...v,
                            uuid: existingVariant?.uuid ?? null,
                            foundExisting: !!existingVariant
                          };
                        })
                      ]
                    };
                  })
                );
              },
              onErrorCallback
            );
          }
          else {
            onSuccesCallback(data);
          }
        }}
        overrideRequired
      />

      <ExportCsvModal
        descriptor="inventory"
        disableFieldSelect
        exportConfig={vendorProductInventoryCsvConfig}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.EXPORT
        }
        itemType="variant"
        itemTypePlural="variants"
        onClose={onCloseModal}
        onExport={({ items }, downloadCsvCallback, onErrorCallback) => {
          fetchPaginatedVendorProductVariants(
            authState.pkEcomVendor,
            {
              uuids: items.map((i) => i.uuid),
              skipStateUpdate: true,
              trackQuantity: true,
              exportResponse: true
            },
            (data) => {
              downloadCsvCallback(
                data
                  .sort((a, b) => {
                    const aOptionsSorted = a.ecomVendorProductVariantOptions.sort(
                      (a1, b1) => a1.nameSort - b1.nameSort
                    );
                    const bOptionsSorted = b.ecomVendorProductVariantOptions.sort(
                      (a1, b1) => a1.nameSort - b1.nameSort
                    );

                    return (
                      (a.productName ?? '').localeCompare(
                        b.productName ?? ''
                      ) ||
                      (aOptionsSorted[0]?.valueSort ?? 0) -
                        (bOptionsSorted[0]?.valueSort ?? 0) ||
                      (aOptionsSorted[1]?.valueSort ?? 0) -
                        (bOptionsSorted[1]?.valueSort ?? 0) ||
                      (aOptionsSorted[2]?.valueSort ?? 0) -
                        (bOptionsSorted[2]?.valueSort ?? 0)
                    );
                  })
                  .map((v) => [
                    {
                      urlHandle: v.productUrlHandle,
                      uuid: v.productUuid,
                      name: v.productName
                    },
                    v,
                    0
                  ])
              );
            },
            onErrorCallback
          );
        }}
        selectedItems={selectedVariants}
        showImportInfo
      />

      <VendorInventoryAdjustQuantityModal
        ecomVendorProductVariants={selectedVariants}
        isOpen={
          !hasUnsavedChanges &&
          modalState.isOpen &&
          modalState.action === VIEW_ACTIONS_ENUM.EDIT
        }
        onClose={onCloseModal}
        onComplete={() => {
          triggerNotification('Inventory updated!', 'success');
          onFilter(filter, true, pageIndex, true);
          onCloseModal();
        }}
      />

      <ConfirmModal
        cancelActionText="Stay on page"
        confirmActionColor="red"
        confirmActionText="Leave without saving"
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.DISCARD
        }
        message={
          <AppText style={{ fontSize: 16 }}>
            Are you sure you want to leave this page? You have unsaved changes.
          </AppText>
        }
        onCancel={onCloseModal}
        onConfirm={() => {
          onCloseModal();
          onFilter(modalState.item, true, modalState.page, true);
        }}
        title="Leave without saving?"
      />
    </HeaderView>
  );
};

export default VendorInventoryTable;
