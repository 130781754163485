import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CustomerDetailsView from '../customers/CustomerDetailsView';
import CustomerTable from '../customers/CustomerTable';

const StoreCustomersView = () => (
  <Routes>
    <Route element={<CustomerTable />} path="/" />
    <Route element={<CustomerDetailsView />} path="/:pkEcomCustomer" />
    <Route element={<Navigate replace to="/merchant/customers" />} path="*" />
  </Routes>
);

export default StoreCustomersView;
