import React from 'react';
import { Button, Checkbox, Menu } from '@mantine/core';
import { Calendar } from 'tabler-icons-react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import AppFlexbox from '../../common/AppFlexbox';
import AnalyticsDateSelectMenu from './AnalyticsDateSelectMenu';
import AppText from '../../common/AppText';
import AppStack from '../../common/AppStack';
import AppMenu from '../../common/AppMenu';
import {
  areDatesEqual,
  getDefaultPeriodInterval
} from '../../../helpers/reportHelper';
import { getDateOnly } from '../../../helpers/format';

const REPORT_PERIOD_OPTIONS = [
  {
    value: 'hour',
    label: 'Hourly'
  },
  {
    value: 'day',
    label: 'Daily'
  },
  {
    value: 'week',
    label: 'Weekly',
    isVisible: (daysBetween) => daysBetween >= 6
  },
  {
    value: 'month',
    label: 'Monthly',
    isVisible: (daysBetween) => daysBetween >= 27
  },
  {
    value: 'quarter',
    label: 'Quarterly',
    isVisible: (daysBetween) => daysBetween >= 85
  },
  {
    value: 'year',
    label: 'Yearly',
    isVisible: (daysBetween) => daysBetween >= 364
  }
];

const AnalyticsFilter = ({
  includeCompareDateRange,
  includePeriod,
  filter,
  onChange
}) => {
  const daysBetween = dayjs(filter.endDate).diff(
    dayjs(filter.startDate),
    'day'
  );
  const periodIntervalOptions = REPORT_PERIOD_OPTIONS.filter((op) =>
    op.isVisible ? op.isVisible(daysBetween) : true
  );

  return (
    <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
      <AnalyticsDateSelectMenu
        control={
          <Button
            color="dark"
            radius={8}
            size="compact-sm"
            style={{ border: 'solid 1px #B1B1B1' }}
            variant="white"
          >
            <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
              <Calendar size={18} />
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                {filter.datePeriod?.label ??
                  (areDatesEqual(filter.startDate, filter.endDate)
                    ? dayjs(filter.startDate).format('MMM D, YYYY')
                    : `${dayjs(filter.startDate).format(
                        'MMM D, YYYY'
                      )} - ${dayjs(filter.endDate).format('MMM D, YYYY')}`)}
              </AppText>
            </AppFlexbox>
          </Button>
        }
        datePeriod={filter.datePeriod}
        endDate={filter.endDate}
        onChange={({ datePeriod, startDate, endDate }) => {
          const {
            startDate: compareStartDate,
            endDate: compareEndDate
          } = filter.compareDatePeriod?.getDateRange(
            datePeriod,
            startDate,
            endDate
          ) ?? {
            startDate: filter.compareStartDate,
            endDate: filter.compareEndDate
          };

          const newStart = getDateOnly(startDate);
          const newEnd = getDateOnly(endDate);
          onChange({
            ...filter,
            datePeriod,
            startDate: newStart,
            endDate: newEnd,
            compareStartDate: getDateOnly(compareStartDate),
            compareEndDate: getDateOnly(compareEndDate),
            periodInterval: getDefaultPeriodInterval(newStart, newEnd)
          });
        }}
        startDate={filter.startDate}
      />
      {includeCompareDateRange && (
        <AnalyticsDateSelectMenu
          compareDatePeriod={filter.compareDatePeriod}
          compareEndDate={filter.compareEndDate}
          compareStartDate={filter.compareStartDate}
          control={
            <Button
              color="dark"
              radius={8}
              size="compact-sm"
              style={{ border: 'solid 1px #B1B1B1' }}
              variant="white"
            >
              <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Compared to:{' '}
                  {filter.compareDatePeriod?.value === 'noComparison'
                    ? 'No comparison'
                    : areDatesEqual(
                        filter.compareStartDate,
                        filter.compareEndDate
                      )
                    ? dayjs(filter.compareStartDate).format('MMM D, YYYY')
                    : `${dayjs(filter.compareStartDate).format(
                        'MMM D, YYYY'
                      )} - ${dayjs(filter.compareEndDate).format(
                        'MMM D, YYYY'
                      )}`}
                </AppText>
              </AppFlexbox>
            </Button>
          }
          datePeriod={filter.datePeriod}
          endDate={filter.endDate}
          isCompareDateRange
          onChange={({ datePeriod, startDate, endDate }) => {
            onChange({
              ...filter,
              compareDatePeriod: datePeriod,
              compareStartDate: getDateOnly(startDate),
              compareEndDate: getDateOnly(endDate)
            });
          }}
          startDate={filter.startDate}
        />
      )}

      {includePeriod && (
        <AppMenu
          closeOnClickOutside
          closeOnItemClick
          control={
            <Button
              color="dark"
              radius={8}
              size="compact-sm"
              style={{ border: 'solid 1px #B1B1B1' }}
              variant="white"
            >
              <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {REPORT_PERIOD_OPTIONS.find(
                    (op) => op.value === filter.periodInterval
                  )?.label ?? 'Period'}{' '}
                </AppText>
              </AppFlexbox>
            </Button>
          }
          position="bottom-start"
          radius="md"
          shadow="xl"
          styles={{ dropdown: { padding: 0 } }}
          width="unset"
        >
          <AppStack style={{ gap: 0, flex: 1 }}>
            {periodIntervalOptions.map((op) => (
              <Menu.Item
                key={op.value}
                onClick={(e) => {
                  e.preventDefault();
                  onChange({
                    ...filter,
                    periodInterval: op.value
                  });
                }}
              >
                <AppFlexbox style={{ alignItems: 'center', gap: 12 }}>
                  <Checkbox
                    checked={filter.periodInterval === op.value}
                    onChange={() => {}}
                  />
                  <AppText style={{ fontSize: 14 }}>{op.label}</AppText>
                </AppFlexbox>
              </Menu.Item>
            ))}
          </AppStack>
        </AppMenu>
      )}
    </AppFlexbox>
  );
};

AnalyticsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  includeCompareDateRange: PropTypes.bool,
  includePeriod: PropTypes.bool
};

export default AnalyticsFilter;
