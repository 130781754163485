import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import VendorOrderDetailsView from './VendorOrderDetailsView';
import VendorOrderFulfillmentView from './VendorOrderFulfillmentView';
import { triggerNotification } from '../../../helpers/notification';
import OrderRefundView from './OrderRefundView';
import ExportCsvModal from '../bulkEdit/ExportCsvModal';
import { useModalState } from '../../../helpers/hooks';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';
import { orderExportConfig } from './orderExportConfig';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';

const VendorOrderViews = () => {
  const hasFetched = useRef(false);
  const { pkEcomOrder } = useParams();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchVendorOrder, fetchPaginatedVendorOrders } = useContext(
    VendorContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();

  const loading = !hasFetched.current || state.ecomOrder.loading;
  const ecomOrder =
    pkEcomOrder === state.ecomOrder.value?.pkEcomOrder.toString()
      ? state.ecomOrder.value
      : null;

  useEffect(() => {
    if (authState.pkEcomVendor && pkEcomOrder) {
      fetchVendorOrder(authState.pkEcomVendor, { pkEcomOrder }, null, (e) => {
        triggerNotification(e);
      });
      hasFetched.current = true;
    }
  }, [authState.pkEcomVendor, pkEcomOrder]);

  const onShowExportConfirm = () => {
    onOpenModal(VIEW_ACTIONS_ENUM.EXPORT);
  };

  return (
    <>
      <Routes>
        <Route
          element={
            <VendorOrderFulfillmentView
              ecomOrder={ecomOrder}
              loading={loading}
            />
          }
          path="/fulfill"
        />
        <Route
          element={
            <OrderRefundView
              ecomOrder={ecomOrder}
              isVendorView
              loading={loading}
            />
          }
          path="/refund"
        />
        <Route
          element={
            <VendorOrderDetailsView
              ecomOrder={ecomOrder}
              loading={loading}
              onExportOrder={onShowExportConfirm}
            />
          }
          path="/"
        />
        <Route
          element={<Navigate replace to={`/vendor/orders/${pkEcomOrder}`} />}
          path="*"
        />
      </Routes>

      <ExportCsvModal
        confirmMessage={
          <AppStack style={{ gap: 8 }}>
            <AppText style={{ fontSize: 14 }}>
              Exporting this order will include all the details of the order in
              a CSV file.
            </AppText>

            <AppText style={{ fontSize: 14, fontWeight: '500' }}>
              Are you sure you want to proceed?
            </AppText>
          </AppStack>
        }
        descriptor="order"
        exportConfig={{
          ...orderExportConfig,
          fieldSections: orderExportConfig.fieldSections
            .filter((f) => !f.isStoreOnly)
            .map((f) => ({
              ...f,
              fields: f.fields.filter((ff) => !ff.isStoreOnly)
            }))
        }}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.EXPORT
        }
        itemType="order"
        itemTypePlural="orders"
        onClose={onCloseModal}
        onExport={({ items }, downloadCsvCallback, onErrorCallback) => {
          fetchPaginatedVendorOrders(
            authState.pkEcomVendor,
            {
              pkEcomOrders: [ecomOrder.pkEcomOrder],
              skipStateUpdate: true,
              isBulkShipment: ecomOrder.isBulkShipment
            },
            (result) => {
              const csvData = orderExportConfig.getCsvData(result.data, true);
              downloadCsvCallback(csvData);
            },
            onErrorCallback
          );
        }}
        selectedItems={[ecomOrder]}
      />
    </>
  );
};

export default VendorOrderViews;
