import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Anchor, Checkbox, Divider, NumberFormatter } from '@mantine/core';
import {
  AlertCircle,
  ChevronDown,
  ChevronRight,
  ChevronUp
} from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { singularPluralFormat } from '../../../helpers/format';
import CustomTooltip from '../../common/CustomTooltip';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import * as classes from '../../../styles/nestedStyles.module.css';

const AdminVendorPayoutListItem = ({
  ecomOrderBalance,
  onSelect,
  selected,
  disabled,
  isCreate,
  payoutReceived
}) => {
  const { isLargeMobileOrSmaller } = useMediaQueryIndex();
  const [isExpanded, setIsExpanded] = useState(false);

  const productCount =
    ecomOrderBalance?.ecomOrderProducts.reduce((r, c) => r + c.count, 0) || 0;

  return (
    <AppStack style={{ gap: 0 }}>
      <AppFlexbox
        className={classes['hover-action-card']}
        onClick={() => setIsExpanded(!isExpanded)}
        style={{
          padding: 16,
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          minHeight: 72
        }}
      >
        <AppFlexbox style={{ alignItems: 'center' }}>
          {onSelect && (
            <Checkbox
              checked={selected}
              disabled={disabled}
              onChange={() => {}}
              onClick={(e) => {
                e.stopPropagation();
                if (onSelect) {
                  onSelect(!selected);
                }
              }}
            />
          )}

          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8 }}>
              <AppText style={{ fontSize: 14 }}>
                Order #{ecomOrderBalance.pkEcomOrder}
              </AppText>
            </AppFlexbox>
            <AppText style={{ fontSize: 12, color: '#666' }}>
              {singularPluralFormat(productCount, 'item', 'items')}
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'end',
            textAlign: 'end',
            gap: 8
          }}
        >
          {!isCreate ? (
            <NumberFormatter
              decimalScale={2}
              fixedDecimalScale
              prefix="$"
              style={{ fontSize: 14, fontWeight: 500 }}
              thousandSeparator
              value={payoutReceived / 100}
            />
          ) : (
            <>
              {ecomOrderBalance.amountPaid !==
                ecomOrderBalance.payoutEarned -
                  ecomOrderBalance.payoutReceived && (
                <>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14, textDecoration: 'line-through' }}
                    thousandSeparator
                    value={ecomOrderBalance.amountPaid / 100}
                  />
                  <ChevronRight size={18} />
                </>
              )}
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{ fontSize: 14, fontWeight: 500 }}
                thousandSeparator
                value={
                  (ecomOrderBalance.payoutEarned -
                    ecomOrderBalance.payoutReceived) /
                  100
                }
              />
            </>
          )}

          {isExpanded ? (
            <ChevronUp color="#999" size={18} />
          ) : (
            <ChevronDown color="#999" size={18} />
          )}
        </AppFlexbox>
      </AppFlexbox>

      {isExpanded && (
        <>
          <Divider />
          <AppFlexbox
            style={{
              gap: 0,
              flexDirection: isLargeMobileOrSmaller ? 'column' : 'row'
            }}
          >
            <AppFlexbox
              style={{ flex: 1, padding: isLargeMobileOrSmaller ? 8 : 16 }}
            >
              <AppStack style={{ gap: 10 }}>
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Store
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    {ecomOrderBalance.storeName}
                  </AppText>
                  <CustomTooltip
                    label="You might need to grant yourself admin access to view the order details."
                    width={300}
                  >
                    <Anchor
                      component={Link}
                      style={{ fontSize: 14, color: 'dodgerblue' }}
                      to={`/merchant/orders/${ecomOrderBalance.pkEcomOrder}`}
                    >
                      View store order
                    </Anchor>
                  </CustomTooltip>
                </AppStack>
                <AppStack style={{ gap: 0 }}>
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    Vendor
                  </AppText>
                  <AppText style={{ fontSize: 14, color: '#666' }}>
                    {ecomOrderBalance.ecomVendor.name}
                  </AppText>
                  <CustomTooltip
                    label="You might need to grant yourself admin access to view the order details. Bulk products will be found in a different order."
                    width={300}
                  >
                    <Anchor
                      component={Link}
                      style={{ fontSize: 14, color: 'dodgerblue' }}
                      to={`/vendor/orders/${ecomOrderBalance.pkEcomOrder}`}
                    >
                      View vendor order
                    </Anchor>
                  </CustomTooltip>
                </AppStack>
              </AppStack>
            </AppFlexbox>

            <AppStack
              style={{
                flex: 1,
                padding: isLargeMobileOrSmaller ? 8 : 16,
                alignItems: 'end'
              }}
            >
              <AppStack
                style={{
                  width: '100%',
                  flex: 1,
                  maxWidth: isLargeMobileOrSmaller ? 'unset' : 300,
                  gap: 5
                }}
              >
                <AppFlexbox
                  style={{ flex: 1, justifyContent: 'space-between' }}
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 14 }}>Order value</AppText>
                    <AppText style={{ fontSize: 12, color: '#666' }}>
                      {singularPluralFormat(
                        productCount,
                        'product',
                        'products'
                      )}
                    </AppText>
                  </AppStack>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14 }}
                    thousandSeparator
                    value={ecomOrderBalance.amountPaid / 100}
                  />
                </AppFlexbox>

                <AppFlexbox
                  style={{ flex: 1, justifyContent: 'space-between' }}
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppText
                      style={{
                        fontSize: 14,
                        color:
                          ecomOrderBalance.amountRefunded > 0
                            ? '#C40000'
                            : '#666'
                      }}
                    >
                      Refunded
                    </AppText>
                  </AppStack>
                  <NumberFormatter
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale
                    prefix={ecomOrderBalance.amountRefunded > 0 ? '-$' : '$'}
                    style={{
                      fontSize: 14,
                      color:
                        ecomOrderBalance.amountRefunded > 0 ? '#C40000' : '#666'
                    }}
                    thousandSeparator
                    value={ecomOrderBalance.amountRefunded / 100}
                  />
                </AppFlexbox>

                {ecomOrderBalance.amountPaidOffline > 0 && (
                  <AppFlexbox
                    style={{ flex: 1, justifyContent: 'space-between' }}
                  >
                    <AppStack style={{ gap: 0 }}>
                      <AppText
                        style={{
                          fontSize: 14,
                          color: '#C40000'
                        }}
                      >
                        Offline amount
                      </AppText>
                    </AppStack>
                    <NumberFormatter
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="-$"
                      style={{
                        fontSize: 14,
                        color: '#C40000'
                      }}
                      thousandSeparator
                      value={ecomOrderBalance.amountPaidOffline / 100}
                    />
                  </AppFlexbox>
                )}

                {ecomOrderBalance.payoutLocked > 0 && (
                  <AppFlexbox
                    style={{ flex: 1, justifyContent: 'space-between' }}
                  >
                    <CustomTooltip
                      label={
                        <AppStack style={{ gap: 8 }}>
                          <AppText style={{ fontSize: 14 }}>
                            The funds are currently held due to group orders
                            being in the "accumulating" stage.
                          </AppText>
                          <AppText style={{ fontSize: 14, color: '#666' }}>
                            The vendor has not yet received any orders for the
                            specified products.
                          </AppText>

                          <AppText style={{ fontSize: 14, color: '#666' }}>
                            Once the vendor received the order or the products
                            get cancelled, the funds will be released.
                          </AppText>
                        </AppStack>
                      }
                      width={300}
                    >
                      <AppFlexbox style={{ alignItems: 'center', gap: 5 }}>
                        <AppStack style={{ gap: 0 }}>
                          <AppText
                            style={{
                              fontSize: 14,
                              color: '#C40000'
                            }}
                          >
                            Locked funds
                          </AppText>
                        </AppStack>
                        <AlertCircle color="#C40000" size={16} />
                      </AppFlexbox>
                    </CustomTooltip>

                    <NumberFormatter
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      prefix="-$"
                      style={{
                        fontSize: 14,
                        color: '#C40000'
                      }}
                      thousandSeparator
                      value={ecomOrderBalance.payoutLocked / 100}
                    />
                  </AppFlexbox>
                )}

                <Divider />
                <AppFlexbox
                  style={{ flex: 1, justifyContent: 'space-between' }}
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 14 }}>Payout Earned</AppText>
                  </AppStack>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14, fontWeight: 500 }}
                    thousandSeparator
                    value={ecomOrderBalance.payoutEarned / 100}
                  />
                </AppFlexbox>
                <AppFlexbox
                  style={{ flex: 1, justifyContent: 'space-between' }}
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      Payout received
                    </AppText>
                  </AppStack>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14, color: '#666' }}
                    thousandSeparator
                    value={ecomOrderBalance.payoutReceived / 100}
                  />
                </AppFlexbox>

                <Divider />
                <AppFlexbox
                  style={{ flex: 1, justifyContent: 'space-between' }}
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 14 }}>Balance</AppText>
                  </AppStack>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 14, fontWeight: 500 }}
                    thousandSeparator
                    value={
                      (ecomOrderBalance.payoutEarned -
                        ecomOrderBalance.payoutReceived) /
                      100
                    }
                  />
                </AppFlexbox>
              </AppStack>
            </AppStack>
          </AppFlexbox>
          <Divider />
          <AppStack style={{ backgroundColor: '#F1F1F1', gap: 0 }}>
            {ecomOrderBalance.ecomOrderProducts
              .sort((a, b) => a.productName.localeCompare(b.productName))
              .map((p, index) => (
                <React.Fragment key={p.pkEcomOrderProduct}>
                  {index > 0 && <Divider />}
                  <AppFlexbox
                    style={{
                      margin: '0px 8px',
                      padding: 16,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'default',
                      minHeight: 72
                    }}
                  >
                    <AppFlexbox style={{ alignItems: 'center' }}>
                      <AppStack style={{ gap: 5 }}>
                        <AppStack style={{ gap: 0 }}>
                          <AppFlexbox style={{ gap: 8 }}>
                            <AppText style={{ fontSize: 14 }}>
                              <span style={{ fontWeight: 500 }}>
                                {p.productName}
                              </span>{' '}
                              <span style={{ color: '#666' }}>x{p.count}</span>
                            </AppText>
                          </AppFlexbox>
                          {p.variantOptions.length > 0 && (
                            <AppText style={{ fontSize: 12, color: '#666' }}>
                              {p.variantOptions
                                .sort((a, b) => a.nameSort - b.nameSort)
                                .map((op) => op.value)
                                .join(' / ')}
                            </AppText>
                          )}
                        </AppStack>
                        <Divider />
                        {p.qualifiedQuantity !== p.count && (
                          <CustomTooltip
                            label={
                              <AppStack style={{ gap: 8 }}>
                                <AppText style={{ fontSize: 14 }}>
                                  {singularPluralFormat(
                                    p.count - p.qualifiedQuantity,
                                    'product',
                                    'products'
                                  )}{' '}
                                  do not qualify for the payout because they are
                                  part of a group order that is still
                                  accumulating.
                                </AppText>
                              </AppStack>
                            }
                            width={300}
                          >
                            <AppFlexbox
                              style={{ alignItems: 'center', gap: 5 }}
                            >
                              <AppText
                                style={{ fontSize: 14, color: '#C40000' }}
                              >
                                {p.qualifiedQuantity}/{p.count} qualify
                              </AppText>
                              <AlertCircle color="#C40000" size={16} />
                            </AppFlexbox>
                          </CustomTooltip>
                        )}
                        {p.returnCount > 0 && (
                          <AppText style={{ fontSize: 14, color: '#C40000' }}>
                            {p.returnCount} cancelled
                          </AppText>
                        )}
                      </AppStack>
                    </AppFlexbox>

                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        justifyContent: 'end',
                        textAlign: 'end',
                        gap: 8
                      }}
                    >
                      {p.qualifiedQuantity !== p.count && (
                        <>
                          <NumberFormatter
                            decimalScale={2}
                            fixedDecimalScale
                            prefix="$"
                            style={{
                              fontSize: 14,
                              textDecoration: 'line-through'
                            }}
                            thousandSeparator
                            value={(p.productCost * p.count) / 100}
                          />

                          <ChevronRight size={18} />
                        </>
                      )}
                      <NumberFormatter
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        style={{ fontSize: 14 }}
                        thousandSeparator
                        value={(p.productCost * p.qualifiedQuantity) / 100}
                      />
                    </AppFlexbox>
                  </AppFlexbox>
                </React.Fragment>
              ))}
          </AppStack>
        </>
      )}
    </AppStack>
  );
};

AdminVendorPayoutListItem.propTypes = {
  disabled: PropTypes.bool,
  ecomOrderBalance: PropTypes.object,
  isCreate: PropTypes.bool,
  onSelect: PropTypes.func,
  payoutReceived: PropTypes.number,
  selected: PropTypes.bool
};

export default AdminVendorPayoutListItem;
