import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Anchor,
  Checkbox,
  Divider,
  NumberInput
} from '@mantine/core';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import ResponsiveModal from '../../common/ResponsiveModal';
import { ECOM_ORDER_PRODUCT_STATUS_ENUM } from '../../../config/constants';

const StoreOrderTransferCreateOrderListItem = ({
  ecomOrderSummary,
  selectedEcomOrderProductBulk,
  onEcomOrderProductBulkSelect,
  disabled
}) => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedVariantGroup: null,
    ecomOrderProductBulk: []
  });

  const bulkProducts = ecomOrderSummary.ecomOrderProductBulk.filter(
    (b) =>
      ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING ===
      b.fkEcomOrderProductStatus.toString()
  );

  const bulkOrderProduct = bulkProducts[0] || {};
  const minimumQuantity =
    bulkOrderProduct?.ecomOrderProduct.minimumQuantity ?? 0;

  const productsGroupedByVariant = bulkProducts.reduce((r, c) => {
    const optionsTitle = c.ecomOrderProduct.ecomVendorProductVariantOptions
      .sort((a, b) => a.nameSort - b.nameSort)
      .map((op) => `${op.name} - ${op.value}`)
      .join(' / ');
    const existing = r.find((x) => x.optionsTitle === optionsTitle);
    if (existing) {
      existing.ecomOrderProductBulk.push(c);
    }
    else {
      r.push({ optionsTitle, ecomOrderProductBulk: [c] });
    }
    return r;
  }, []);

  const totalCount = bulkProducts.reduce((r, c) => r + c.count, 0);

  const selectedCount = selectedEcomOrderProductBulk.reduce(
    (r, c) => r + c.count,
    0
  );
  const isEntireOrderSelected = totalCount === selectedCount;

  return (
    <>
      <Accordion
        styles={{ content: { padding: 0 }, item: { borderBottom: 'none' } }}
      >
        <Accordion.Item value={ecomOrderSummary.pkEcomOrder.toString()}>
          <Accordion.Control>
            <AppFlexbox
              style={{
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <AppStack>
                <AppFlexbox
                  onClick={(e) => {
                    e.stopPropagation();
                    onEcomOrderProductBulkSelect(
                      isEntireOrderSelected
                        ? []
                        : bulkProducts.map((x) => ({
                            pkEcomOrderProductBulk: x.pkEcomOrderProductBulk,
                            count: x.count,
                            fkEcomOrder: ecomOrderSummary.pkEcomOrder
                          }))
                    );
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Checkbox
                    checked={isEntireOrderSelected}
                    disabled={disabled}
                    indeterminate={!isEntireOrderSelected && selectedCount > 0}
                    onChange={() => {}}
                    style={{ marginTop: 3 }}
                  />
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                      Order #{ecomOrderSummary.pkEcomOrder}
                    </AppText>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      {ecomOrderSummary.ecomStore.name}
                    </AppText>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      {totalCount}/{minimumQuantity}{' '}
                      {bulkOrderProduct?.ecomOrderProduct.productName}
                    </AppText>
                  </AppStack>
                </AppFlexbox>
              </AppStack>
              {selectedCount > 0 && (
                <AppText
                  style={{ fontSize: 14, fontWeight: 500, paddingRight: 8 }}
                >
                  {selectedCount} selected
                </AppText>
              )}
            </AppFlexbox>
          </Accordion.Control>
          <Accordion.Panel>
            <AppStack style={{ gap: 0 }}>
              {productsGroupedByVariant.map((variant) => {
                const totalVariantCount = variant.ecomOrderProductBulk.reduce(
                  (r, c) => r + c.count,
                  0
                );

                const selectedProducts = selectedEcomOrderProductBulk.filter(
                  (x) =>
                    variant.ecomOrderProductBulk.some(
                      (y) =>
                        y.pkEcomOrderProductBulk === x.pkEcomOrderProductBulk
                    )
                );
                const selectedVariantCount = selectedProducts.reduce(
                  (r, c) => r + c.count,
                  0
                );

                const isEntireVariantSelected =
                  totalVariantCount === selectedVariantCount;

                return (
                  <React.Fragment key={variant.optionsTitle}>
                    <Divider />
                    <AppFlexbox
                      key={variant.optionsTitle}
                      style={{
                        padding: 16,
                        alignItems: 'center',
                        backgroundColor: '#F1F1F1'
                      }}
                    >
                      <AppFlexbox style={{ flex: 1 }}>
                        <AppStack>
                          <AppFlexbox
                            onClick={() => {
                              const filteredList = selectedEcomOrderProductBulk.filter(
                                (x) =>
                                  !variant.ecomOrderProductBulk.some(
                                    (y) =>
                                      y.pkEcomOrderProductBulk ===
                                      x.pkEcomOrderProductBulk
                                  )
                              );
                              onEcomOrderProductBulkSelect(
                                isEntireVariantSelected
                                  ? [...filteredList]
                                  : [
                                      ...filteredList,
                                      ...variant.ecomOrderProductBulk.map(
                                        (x) => ({
                                          pkEcomOrderProductBulk:
                                            x.pkEcomOrderProductBulk,
                                          count: x.count,
                                          fkEcomOrder:
                                            ecomOrderSummary.pkEcomOrder
                                        })
                                      )
                                    ]
                              );
                            }}
                            style={{ flex: 1, gap: 16, cursor: 'pointer' }}
                          >
                            <Checkbox
                              checked={isEntireVariantSelected}
                              disabled={disabled}
                              indeterminate={
                                !isEntireVariantSelected &&
                                selectedVariantCount > 0
                              }
                              onChange={() => {}}
                              style={{ marginTop: 3 }}
                            />
                            <AppStack style={{ gap: 0, flex: 1 }}>
                              <AppText
                                style={{ fontSize: 16, fontWeight: 500 }}
                              >
                                {
                                  variant.ecomOrderProductBulk[0]
                                    .ecomOrderProduct.productName
                                }
                              </AppText>
                              <AppText style={{ fontSize: 14, color: '#666' }}>
                                {variant.ecomOrderProductBulk[0].ecomOrderProduct.ecomVendorProductVariantOptions
                                  .sort((a, b) => a.nameSort - b.nameSort)
                                  .map((op) => op.value)
                                  .join(' / ')}
                              </AppText>
                              <AppFlexbox />
                            </AppStack>
                          </AppFlexbox>
                        </AppStack>
                      </AppFlexbox>

                      <AppFlexbox style={{ alignItems: 'center', gap: 8 }}>
                        <AppStack style={{ gap: 0, alignItems: 'end' }}>
                          <AppText style={{ fontSize: 14, color: '#666' }}>
                            {selectedVariantCount}/{totalVariantCount} selected
                          </AppText>
                          <Anchor
                            disabled={disabled}
                            onClick={() => {
                              if (!disabled) {
                                setModalState({
                                  isOpen: true,
                                  selectedVariantGroup: variant,
                                  ecomOrderProductBulk: [
                                    ...selectedEcomOrderProductBulk.filter(
                                      (x) =>
                                        variant.ecomOrderProductBulk.some(
                                          (y) =>
                                            y.pkEcomOrderProductBulk ===
                                            x.pkEcomOrderProductBulk
                                        )
                                    )
                                  ]
                                });
                              }
                            }}
                            style={{ color: 'dodgerblue', fontSize: 14 }}
                          >
                            Custom selection
                          </Anchor>
                        </AppStack>
                      </AppFlexbox>
                    </AppFlexbox>
                  </React.Fragment>
                );
              })}
            </AppStack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      {modalState.isOpen && (
        <ResponsiveModal
          bodyProps={{ style: { padding: 0 } }}
          formSectionProps={{
            onCancel: () =>
              setModalState({
                ...modalState,
                isOpen: false
              }),
            submitTitle: 'Save selection',
            onSubmit: () => {
              const filteredList = selectedEcomOrderProductBulk.filter(
                (x) =>
                  !modalState.selectedVariantGroup.ecomOrderProductBulk.some(
                    (y) => y.pkEcomOrderProductBulk === x.pkEcomOrderProductBulk
                  )
              );
              onEcomOrderProductBulkSelect([
                ...filteredList,
                ...modalState.ecomOrderProductBulk.map((x) => ({
                  pkEcomOrderProductBulk: x.pkEcomOrderProductBulk,
                  count: x.count,
                  fkEcomOrder: ecomOrderSummary.pkEcomOrder
                }))
              ]);
              setModalState({
                ...modalState,
                isOpen: false
              });
            },
            padding: 0
          }}
          isOpen={modalState.isOpen}
          onClose={() =>
            setModalState({
              ...modalState,
              isOpen: false
            })
          }
          size={500}
          title="Select order items"
        >
          <AppStack style={{ gap: 0 }}>
            <AppStack style={{ padding: 16 }}>
              <AppText
                style={{ fontSize: 14, color: '#666', textAlign: 'center' }}
              >
                Select the items you want to include in the transfer.
              </AppText>
            </AppStack>
            {modalState.selectedVariantGroup.ecomOrderProductBulk
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((p) => {
                const productState = modalState.ecomOrderProductBulk.find(
                  (x) => x.pkEcomOrderProductBulk === p.pkEcomOrderProductBulk
                );

                return (
                  <React.Fragment key={p.pkEcomOrderProductBulk}>
                    <Divider />
                    <AppFlexbox
                      style={{
                        padding: 16,
                        alignItems: 'center'
                      }}
                    >
                      <AppFlexbox
                        style={{
                          gap: 16,
                          flex: 1,
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <AppFlexbox
                          onClick={() => {
                            const filteredList = modalState.ecomOrderProductBulk.filter(
                              (x) =>
                                x.pkEcomOrderProductBulk !==
                                p.pkEcomOrderProductBulk
                            );
                            setModalState({
                              ...modalState,
                              ecomOrderProductBulk: productState
                                ? [...filteredList]
                                : [
                                    ...filteredList,
                                    {
                                      pkEcomOrderProductBulk:
                                        p.pkEcomOrderProductBulk,
                                      count: p.count,
                                      fkEcomOrder: ecomOrderSummary.pkEcomOrder
                                    }
                                  ]
                            });
                          }}
                          style={{ gap: 16, flex: 1, cursor: 'pointer' }}
                        >
                          <Checkbox
                            checked={!!productState}
                            disabled={disabled}
                            onChange={() => {}}
                            style={{ marginTop: 3 }}
                          />
                          <AppStack style={{ gap: 0 }}>
                            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                              {p.ecomOrderProduct.productName}{' '}
                              <span
                                style={{ color: '#666', fontWeight: 'normal' }}
                              >
                                (#{p.ecomOrderProduct.fkEcomOrder})
                              </span>
                            </AppText>
                            <AppText style={{ fontSize: 14, color: '#666' }}>
                              {p.ecomOrderProduct.ecomVendorProductVariantOptions
                                .sort((a, b) => a.nameSort - b.nameSort)
                                .map((op) => op.value)
                                .join(' / ')}
                            </AppText>

                            <AppText style={{ fontSize: 14, color: '#666' }}>
                              {p.ecomCustomer.user?.name ||
                                p.ecomCustomer.email}
                            </AppText>
                          </AppStack>
                        </AppFlexbox>

                        <NumberInput
                          disabled={!productState || disabled}
                          max={p.count}
                          min={0}
                          onChange={(v) => {
                            setModalState({
                              ...modalState,
                              ecomOrderProductBulk: modalState.ecomOrderProductBulk.map(
                                (x) =>
                                  x.pkEcomOrderProductBulk ===
                                  p.pkEcomOrderProductBulk
                                    ? { ...x, count: v }
                                    : x
                              )
                            });
                          }}
                          rightSection={
                            <AppFlexbox>
                              <AppText style={{ fontSize: 15, color: '#999' }}>
                                of {p.count}
                              </AppText>
                            </AppFlexbox>
                          }
                          rightSectionWidth="40%"
                          style={{ flex: 1, maxWidth: 100 }}
                          value={productState?.count}
                        />
                      </AppFlexbox>
                    </AppFlexbox>
                  </React.Fragment>
                );
              })}
          </AppStack>
        </ResponsiveModal>
      )}
    </>
  );
};

StoreOrderTransferCreateOrderListItem.propTypes = {
  disabled: PropTypes.bool,
  ecomOrderSummary: PropTypes.object,
  onEcomOrderProductBulkSelect: PropTypes.func,
  selectedEcomOrderProductBulk: PropTypes.array
};

export default StoreOrderTransferCreateOrderListItem;
