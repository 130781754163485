import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Divider,
  Menu,
  ScrollArea,
  TextInput
} from '@mantine/core';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import AppMenu from '../../common/AppMenu';
import * as classes from '../../../styles/nestedStyles.module.css';

const AnalyticsFilterSelectMenu = ({
  options,
  value,
  onChange,
  isCompare,
  loading,
  prefix,
  required,
  groupValue
}) => {
  const [formState, setFormState] = useState({ search: '', groupValue: '' });
  const hasGroupValues = options.some((op) => op.groupLabel && op.groupValue);
  const groupBy = options.find((op) => op.groupValue === formState.groupValue);

  useEffect(() => {
    if (hasGroupValues && value && !formState.groupValue) {
      setFormState({
        ...formState,
        groupValue: groupValue || ''
      });
    }
  }, [value, hasGroupValues]);

  return (
    <AppMenu
      closeOnClickOutside
      closeOnItemClick={!hasGroupValues || !!groupBy}
      control={
        <Button
          color="dark"
          loading={loading}
          radius={8}
          size="compact-sm"
          style={{ border: 'solid 1px #B1B1B1', minWidth: 50 }}
          variant="white"
        >
          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              {groupBy?.groupValue === groupValue ? (
                <span style={{ color: '#999' }}>{groupBy.groupLabel}: </span>
              ) : (
                prefix ||
                (isCompare && (
                  <span style={{ color: '#999' }}>Compared to: </span>
                ))
              )}
              {
                (hasGroupValues ? groupBy?.options || [] : options).find(
                  (s) => s.value === value
                )?.label
              }
            </AppText>
          </AppFlexbox>
        </Button>
      }
      position="bottom-start"
      radius="md"
      shadow="xl"
      styles={{ dropdown: { padding: 0 } }}
      width={300}
    >
      <AppStack style={{ gap: 0, flex: 1 }}>
        {hasGroupValues && !groupBy ? (
          <ScrollArea.Autosize mah={250} scrollbarSize={5} type="scroll">
            {options.map((s) => (
              <Menu.Item
                key={s.groupValue}
                onClick={(e) => {
                  e.preventDefault();
                  if (s.groupValue !== formState.groupValue) {
                    setFormState({
                      ...formState,
                      groupValue: s.groupValue
                    });
                  }
                }}
              >
                <AppFlexbox
                  style={{
                    alignItems: 'center',
                    gap: 12,
                    justifyContent: 'space-between'
                  }}
                >
                  <AppText style={{ fontSize: 14 }}>{s.groupLabel}</AppText>
                  <ChevronRight size={16} />
                </AppFlexbox>
              </Menu.Item>
            ))}
          </ScrollArea.Autosize>
        ) : (
          <>
            {hasGroupValues && (
              <AppFlexbox
                className={classes['hover-action-card']}
                onClick={() =>
                  setFormState({
                    ...formState,
                    groupValue: ''
                  })
                }
                style={{ gap: 12, padding: '8px 12px', alignItems: 'center' }}
              >
                <ChevronLeft size={16} />
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  {groupBy?.groupLabel}
                </AppText>
              </AppFlexbox>
            )}

            <TextInput
              onChange={(e) => {
                setFormState({
                  ...formState,
                  search: e.currentTarget.value
                });
              }}
              placeholder="Search by name..."
              radius={0}
              size="sm"
              style={{ flex: 1 }}
              styles={{ input: { border: 'none' } }}
              value={formState.search}
              variant="filled"
            />
            <Divider />
            <ScrollArea.Autosize mah={250} scrollbarSize={5} type="scroll">
              {(hasGroupValues ? groupBy.options : options)
                .filter(
                  (s) =>
                    !formState.search ||
                    s.label
                      .toLowerCase()
                      .includes(formState.search.toLowerCase())
                )
                .map((s) => (
                  <Menu.Item
                    key={s.value}
                    onClick={(e) => {
                      e.preventDefault();
                      if (s.value !== value && !required) {
                        onChange(s.value, groupBy?.groupValue);
                      }
                      else {
                        onChange('', '');
                      }
                    }}
                  >
                    <AppFlexbox style={{ alignItems: 'center', gap: 12 }}>
                      <Checkbox
                        checked={value === s.value}
                        onChange={() => {}}
                      />
                      <AppText style={{ fontSize: 14 }}>{s.label}</AppText>
                    </AppFlexbox>
                  </Menu.Item>
                ))}
            </ScrollArea.Autosize>
          </>
        )}
      </AppStack>
    </AppMenu>
  );
};

AnalyticsFilterSelectMenu.propTypes = {
  groupValue: PropTypes.string,
  isCompare: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeGroup: PropTypes.func,
  onGroupChange: PropTypes.func,
  options: PropTypes.array,
  prefix: PropTypes.any,
  required: PropTypes.bool,
  value: PropTypes.string
};

export default AnalyticsFilterSelectMenu;
