import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, NumberFormatter } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';

const StoreOrderOfflineConfirmationModal = ({
  isOpen,
  onClose,
  ecomOrder,
  productData
}) => {
  const { completeEcomOrderOffline } = useContext(StoreContext);
  const [formState, setFormState] = useState({ loading: false });

  useEffect(() => {}, []);

  const vendorPayout = productData?.reduce((r, c) => r + c.vendorCost, 0) || 0;

  return (
    <ResponsiveModal
      formSectionProps={{
        onCancel: onClose,
        isLoading: formState.loading,
        submitTitle: 'Yes, complete order',
        onSubmit: () => {
          setFormState({ ...formState, loading: true });
          completeEcomOrderOffline(
            ecomOrder.pkEcomOrder,
            () => {
              setFormState({ ...formState, loading: false });
              triggerNotification('Order completed!', 'success');
              onClose();
            },
            (e) => {
              setFormState({ ...formState, loading: false });
              triggerNotification(e);
            }
          );
        },
        padding: 0
      }}
      isOpen={isOpen}
      onClose={onClose}
      size={450}
      title="Complete order offline"
    >
      <AppStack style={{ gap: 0 }}>
        <AppStack style={{ gap: 8, padding: 16 }}>
          <AppText style={{ fontSize: 14 }}>
            Completing the order offline will mark it as completed without
            notifying the vendor. You will need to manually handle the
            fulfillment process offline.
          </AppText>

          <AppText
            style={{
              fontSize: 14,
              color: '#666',
              textAlign: 'center',
              fontStyle: 'italic'
            }}
          >
            The vendor will not see the order in the system.
          </AppText>
        </AppStack>
        <Divider />
        <AppStack style={{ gap: 12, padding: 16 }}>
          <AppStack style={{ gap: 8 }}>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Summary:
            </AppText>

            <AppFlexbox
              style={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <AppText style={{ fontSize: 14 }}>Products:</AppText>
              <AppText style={{ fontSize: 14 }}>
                {productData?.reduce((acc, item) => acc + item.quantity, 0) ??
                  0}
              </AppText>
            </AppFlexbox>

            <AppFlexbox
              style={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <AppText style={{ fontSize: 14 }}>Vendor cost:</AppText>
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                prefix="$"
                style={{
                  fontSize: 14,
                  fontWeight: 500
                }}
                thousandSeparator
                value={vendorPayout / 100}
              />
            </AppFlexbox>
          </AppStack>

          <AppText
            style={{
              fontSize: 14,
              color: '#666',
              textAlign: 'center',
              fontStyle: 'italic'
            }}
          >
            Pricing may vary depending on the store's price compared to the
            listed fundraising price on the platform.
          </AppText>
        </AppStack>

        <Divider />

        <AppStack style={{ gap: 8, padding: 16 }}>
          <AppText style={{ fontSize: 14 }}>
            Are you sure you want to continue?
          </AppText>

          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
            This action cannot be undone.
          </AppText>
        </AppStack>
      </AppStack>
    </ResponsiveModal>
  );
};

StoreOrderOfflineConfirmationModal.propTypes = {
  ecomOrder: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  productData: PropTypes.array
};

export default StoreOrderOfflineConfirmationModal;
