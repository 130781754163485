import React, { useContext, useEffect, useRef, useState } from 'react';
import { Car, CreditCard, CubeSend, Eye, Receipt, X } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Badge, Button } from '@mantine/core';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import TableView from '../../common/TableView';
import {
  ECOM_ORDER_PRODUCT_STATUS_ENUM,
  ECOM_ORDER_STATUS_ENUM,
  PAYMENT_TYPE_ENUM,
  VIEW_ACTIONS_ENUM
} from '../../../config/constants';
import { useModalState, usePaginationFilter } from '../../../helpers/hooks';
import HeaderView from '../../common/HeaderView';
import { triggerNotification } from '../../../helpers/notification';
import { currencyFormat, formatUtcDate } from '../../../helpers/format';
import OrderTransactionModal from '../orders/OrderTransactionModal';
import ExportCsvModal from '../bulkEdit/ExportCsvModal';
import { orderExportConfig } from '../orders/orderExportConfig';

const VIEW_ACTIONS = [
  {
    label: 'View order',
    value: VIEW_ACTIONS_ENUM.VIEW,
    section: 1,
    icon: <Eye size={18} />
  },
  {
    label: 'View transaction',
    value: 'transaction',
    section: 1,
    icon: <Receipt size={18} />,
    isVisible: ({ isBulkOrders }) => !isBulkOrders
  },
  {
    label: 'Submit order',
    value: 'submit_order',
    section: 1,
    icon: <CubeSend size={18} />,
    isVisible: ({ isAccumulating }) => isAccumulating
  },
  {
    label: 'Complete offline',
    value: 'complete_offline',
    section: 3,
    icon: <Car size={18} />,
    isVisible: ({ isAccumulating, isAdmin }) => isAccumulating && isAdmin
  },
  {
    label: 'Cancel',
    value: 'cancel',
    section: 2,
    color: '#C40000',
    icon: <X color="#C40000" size={18} />,
    isVisible: ({ isCancelled, isBulkOrders }) => !isCancelled && !isBulkOrders
  },
  {
    label: 'Refund',
    value: VIEW_ACTIONS_ENUM.REFUND,
    section: 2,
    color: '#C40000',
    icon: <CreditCard color="#C40000" size={18} />,
    isVisible: ({ isRefunded, isBulkOrders }) => !isRefunded && !isBulkOrders
  }
];

const TABLE_COLUMNS = [
  {
    label: 'Order',
    value: 'order',
    sortable: true
  },
  {
    label: 'Date',
    value: 'date',
    sortable: true
  },
  {
    label: 'Fulfillment status',
    value: 'status',
    sortable: true
  },
  {
    label: 'Payment status',
    value: 'payment_status',
    sortable: true,
    bulkHidden: true
  },
  {
    label: 'Customer',
    value: 'customer',
    sortable: true
  },
  {
    label: 'Products ordered',
    value: 'products',
    sortable: true
  },
  {
    label: 'Total paid',
    value: 'total',
    sortable: true
  }
];

const StoreOrderTable = ({ isBulkOrders }) => {
  const fetchedTableType = useRef(null);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const { state: authState } = useContext(AuthContext);
  const { state, fetchPaginatedStoreOrders } = useContext(StoreContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const {
    pageIndex,
    totalCount,
    totalPages,
    totalNoFilterCount,
    data: orderData,
    filter,
    loading: dataLoading,
    isDescendingSort,
    onPageChange,
    onFilterChange,
    onSortChange,
    onRefresh
  } = usePaginationFilter(
    {
      search: '',
      status: '',
      sort: 'order_desc'
    },
    (newFilter) => {
      fetchPaginatedStoreOrders(
        authState.pkEcomStore,
        newFilter,
        null,
        (error) => {
          triggerNotification(error);
        }
      );
    },
    isBulkOrders ? state.paginatedEcomOrdersBulk : state.paginatedEcomOrders
  );
  const tableInfo = isBulkOrders
    ? {
        title: 'Group orders',
        type: 'bulk',
        urlBase: '/merchant/group-orders'
      }
    : {
        title: 'Orders',
        type: 'normal',
        urlBase: '/merchant/orders'
      };

  const loading = fetchedTableType.current !== tableInfo.type || dataLoading;
  const hasOrders = !loading && totalNoFilterCount > 0;

  const selectedOrders = modalState.item
    ? orderData.filter((d) => modalState.item.includes(d.pkEcomOrder))
    : [];

  useEffect(() => {
    if (authState.pkEcomStore) {
      fetchPaginatedStoreOrders(
        authState.pkEcomStore,
        {
          isBulkShipment: isBulkOrders,
          search: new URLSearchParams(search).get('customer') || '',
          escrowOrders: new URLSearchParams(search).get('type') === 'escrow',
          sort: 'order_desc'
        },
        null,
        (error) => {
          triggerNotification(error);
        }
      );
      fetchedTableType.current = tableInfo.type;
    }
  }, [authState.pkEcomStore]);

  return (
    <HeaderView
      breadcrumbs={[
        {
          title: tableInfo.title,
          to: tableInfo.urlBase
        }
      ]}
      rightSection={
        hasOrders && (
          <Button
            color="dark"
            onClick={() => onOpenModal(VIEW_ACTIONS_ENUM.EXPORT, selectedRows)}
            radius="md"
            size="compact-md"
            style={{ fontSize: 14 }}
            variant="outline"
            visibleFrom="xsm"
          >
            Export
          </Button>
        )
      }
    >
      <TableView
        columns={TABLE_COLUMNS.filter((f) => !f.bulkHidden || !isBulkOrders)}
        disabled={!loading && totalNoFilterCount === 0}
        disabledContent={{
          title: 'No orders found.',
          description:
            'Orders will appear here once customers begin to place orders.',
          icon: <Receipt color="#000" size={125} />
        }}
        emptyMessage="No orders found."
        enableInputsWhenLoading
        filters={[
          {
            key: 1,
            label: 'Search',
            placeholder: 'Filter by customer',
            value: filter.search || '',
            onChange: (v) =>
              onFilterChange({
                ...filter,
                search: v
              })
          },
          {
            key: 2,
            label: 'Status',
            type: 'select',
            value: filter.status || null,
            placeholder: 'Filter by status',
            searchable: true,
            clearable: true,
            data: [
              { value: 'unfulfilled', label: 'Unfulfilled' },
              { value: 'fulfilled', label: 'Fulfilled' },
              { value: 'refunded', label: 'Refunded' },
              { value: 'cancelled', label: 'Cancelled' }
            ],
            onChange: (value) =>
              onFilterChange({ ...filter, status: value }, true)
          }
        ]}
        isDescendingSort={isDescendingSort}
        isLoading={loading}
        noSort
        onAction={(action, item) => {
          if (action === VIEW_ACTIONS_ENUM.VIEW) {
            navigate(`${tableInfo.urlBase}/${item.key}`);
          }
          else if (action === VIEW_ACTIONS_ENUM.REFUND) {
            navigate(`${tableInfo.urlBase}/${item.key}/refund`);
          }
          else if (action === 'cancel') {
            navigate(`${tableInfo.urlBase}/${item.key}?modal=cancel`);
          }
          else if (action === 'complete_offline') {
            navigate(`${tableInfo.urlBase}/${item.key}?modal=complete_offline`);
          }
          else if (action === 'submit_order') {
            navigate(`${tableInfo.urlBase}/${item.key}?modal=submit_order`);
          }
          else {
            onOpenModal(action, item.key);
          }
        }}
        onChangeSortBy={onSortChange}
        onPageChange={onPageChange}
        onRefresh={onRefresh}
        onSelect={setSelectedRows}
        pageIndex={pageIndex}
        rows={orderData.map((d) => {
          const totals = d.ecomOrderPayments.reduce(
            (r, current) => {
              if (
                current.fkRegFormSubmissionPaymentType.toString() ===
                PAYMENT_TYPE_ENUM.REFUND
              ) {
                return {
                  ...r,
                  refunded: r.refunded + current.amount
                };
              }
              if (
                current.fkRegFormSubmissionPaymentType.toString() ===
                PAYMENT_TYPE_ENUM.REIMBURSEMENT
              ) {
                return {
                  ...r,
                  reimbursed: r.reimbursed + current.amount
                };
              }
              return {
                ...r,
                paid: r.paid + current.amount
              };
            },
            {
              refunded: 0,
              reimbursed: 0,
              paid: 0
            }
          );
          const completelyRefunded =
            totals.paid && totals.refunded >= totals.paid;

          const bulkProducts = d.ecomOrderProductBulk.filter(
            (b) =>
              ![
                ECOM_ORDER_PRODUCT_STATUS_ENUM.TRANSFERRED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.CANCELLED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.REFUNDED
              ].includes(b.fkEcomOrderProductStatus.toString())
          );

          const bulkInfo = bulkProducts.reduce(
            (r, current) => ({
              itemCount: r.itemCount + current.count,
              cancelledCount: r.cancelledCount + current.cancelledCount,
              isFullyFulfilled:
                r.isFullyFulfilled &&
                current.fkEcomOrderProductStatus.toString() !==
                  ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING &&
                current.fkEcomOrderProductStatus.toString() !==
                  ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
            }),
            {
              itemCount: 0,
              cancelledCount: 0,
              isFullyFulfilled: true
            }
          );

          const productInfo = d.ecomOrderProducts.reduce(
            (r, current) => ({
              itemCount: r.itemCount + current.count,
              cancelledCount: r.cancelledCount + current.cancelledCount,
              isFullyFulfilled: current.isBulkShipment
                ? r.isFullyFulfilled
                : r.isFullyFulfilled &&
                  current.fkEcomOrderProductStatus.toString() !==
                    ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING &&
                  current.fkEcomOrderProductStatus.toString() !==
                    ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
            }),
            {
              itemCount: 0,
              cancelledCount: 0,
              isFullyFulfilled: true
            }
          );
          const isFullyCancelled =
            !isBulkOrders &&
            productInfo.cancelledCount === productInfo.itemCount;

          const statusInfo =
            d.fkEcomOrderStatus.toString() ===
            ECOM_ORDER_STATUS_ENUM.COMPLETED_OFFLINE
              ? { color: 'green', label: 'Completed Offline' }
              : d.fkEcomOrderStatus.toString() ===
                ECOM_ORDER_STATUS_ENUM.ACCUMULATING
              ? {
                  color: 'blue',
                  label: 'Accumulating'
                }
              : isFullyCancelled
              ? {
                  color: '#C40000',
                  label: 'Cancelled'
                }
              : productInfo.isFullyFulfilled && bulkInfo.isFullyFulfilled
              ? {
                  color: 'green',
                  label: 'Fulfilled'
                }
              : {
                  color: 'orange',
                  label: 'Unfulfilled'
                };

          const paymentStatusInfo = completelyRefunded
            ? {
                color: '#C40000',
                label: 'Refunded'
              }
            : totals.refunded > 0
            ? {
                color: '#666',
                label: 'Partially refunded'
              }
            : {
                color: 'dodgerblue',
                label: 'Paid'
              };

          const totalPaid = isBulkOrders
            ? bulkProducts.reduce(
                (r, c) => r + c.ecomOrderProduct.productCost * c.count,
                0
              )
            : d.ecomOrderPayments
                .filter(
                  (f) =>
                    f.fkRegFormSubmissionPaymentType.toString() !==
                      PAYMENT_TYPE_ENUM.REFUND.toString() &&
                    f.fkRegFormSubmissionPaymentType.toString() !==
                      PAYMENT_TYPE_ENUM.REIMBURSEMENT.toString()
                )
                .reduce((r, c) => r + c.amount, 0);

          const minimumQuantity = isBulkOrders
            ? bulkProducts.reduce(
                (r, c) => {
                  if (!r.uuids.includes(c.ecomOrderProduct.vendorProductUuid)) {
                    return {
                      uuids: [...r.uuids, c.ecomOrderProduct.vendorProductUuid],
                      total: r.total + c.ecomOrderProduct.minimumQuantity
                    };
                  }
                  return r;
                },
                {
                  uuids: [],
                  total: 0
                }
              ).total
            : 0;

          return {
            key: d.pkEcomOrder,
            actions: VIEW_ACTIONS.filter(
              (f) =>
                !f.isVisible ||
                f.isVisible({
                  isCancelled: isFullyCancelled,
                  isRefunded: completelyRefunded,
                  isBulkOrders,
                  isAccumulating:
                    d.fkEcomOrderStatus.toString() ===
                    ECOM_ORDER_STATUS_ENUM.ACCUMULATING,
                  isAdmin: authState.userData?.isAdmin
                })
            ),
            columns: [
              {
                key: 1,
                label: `#${d.pkEcomOrder}`,
                weight: 500,
                component: Link,
                to: `${tableInfo.urlBase}/${d.pkEcomOrder}`
              },
              {
                key: 2,
                label: dayjs(formatUtcDate(d.purchasedAt)).format(
                  'MMMM D, YYYY h:mm A'
                )
              },
              {
                key: 3,
                customComponent: (
                  <Badge color={statusInfo.color} variant="light">
                    {statusInfo.label}
                  </Badge>
                )
              },
              ...(isBulkOrders
                ? []
                : [
                    {
                      key: 4,
                      customComponent: (
                        <Badge color={paymentStatusInfo.color} variant="light">
                          {paymentStatusInfo.label}
                        </Badge>
                      )
                    }
                  ]),
              {
                key: 5,
                label: d.ecomCustomer.user?.name,
                weight: 500,
                subLabel: d.ecomCustomer.email,
                component: Link,
                to: `${tableInfo.urlBase}/${d.pkEcomOrder}`
              },
              {
                key: 6,
                label: isBulkOrders
                  ? `${
                      productInfo.itemCount + bulkInfo.itemCount
                    } / ${minimumQuantity} ${
                      d.ecomOrderProductBulk[0]?.ecomOrderProduct.productName ||
                      ''
                    }`
                  : productInfo.itemCount + bulkInfo.itemCount,
                sortValue: productInfo.itemCount + bulkInfo.itemCount
              },
              {
                key: 7,
                label: currencyFormat(totalPaid / 100)
              }
            ]
          };
        })}
        selectedRows={selectedRows}
        selectInputOnly
        sortBy={filter.sort || TABLE_COLUMNS[0].value}
        tableTitle="Orders"
        totalCount={totalCount}
        totalPages={totalPages}
      />

      <OrderTransactionModal
        isOpen={modalState.isOpen && modalState.action === 'transaction'}
        onClose={onCloseModal}
        onShowRefund={() =>
          onOpenModal(VIEW_ACTIONS_ENUM.REFUND, modalState.item)
        }
        pkEcomOrder={
          modalState.action === 'transaction' ? modalState.item : null
        }
      />

      <ExportCsvModal
        descriptor="orders"
        exportConfig={orderExportConfig}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.EXPORT
        }
        itemType="order"
        itemTypePlural="orders"
        onClose={onCloseModal}
        onExport={({ items }, downloadCsvCallback, onErrorCallback) => {
          fetchPaginatedStoreOrders(
            authState.pkEcomStore,
            {
              pkEcomOrders: items.map((i) => i.pkEcomOrder),
              skipStateUpdate: true,
              pageSize: 500,
              isBulkShipment: isBulkOrders,
              sort: 'order_desc'
            },
            (result) => {
              const csvData = orderExportConfig.getCsvData(result.data);
              downloadCsvCallback(csvData);
            },
            onErrorCallback
          );
        }}
        selectedItems={selectedOrders}
      />
    </HeaderView>
  );
};

StoreOrderTable.propTypes = { isBulkOrders: PropTypes.bool };

export default StoreOrderTable;
