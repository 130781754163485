import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Image, NumberFormatter, TextInput } from '@mantine/core';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import AppFlexbox from '../../common/AppFlexbox';
import { singularPluralFormat } from '../../../helpers/format';
import * as classes from '../../../styles/nestedStyles.module.css';
import SelectProductOptionsForm from '../products/SelectProductOptionsForm';
import { useMediaQueryIndex } from '../../../helpers/hooks';
import { useCartController } from '../../../helpers/webstoreCheckoutHelper';
import { triggerNotification } from '../../../helpers/notification';

const StoreOrderPurchaseProductModal = ({
  isOpen,
  onClose,
  pkEcomOrder,
  pkEcomStoreCartProduct
}) => {
  const {
    ecomStoreCart,
    addStoreProducts,
    updateStoreCartProducts
  } = useCartController({ isAdminCheckout: true, pkEcomOrder });
  const { state: authState } = useContext(AuthContext);
  const { state } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    productSearch: '',
    selectedProductUuid: null,
    selectedOptions: [],
    ecomStoreCartProductInputAnswers: [],
    count: 1,
    loading: false
  });
  const mediaQueries = useMediaQueryIndex();

  const selectedCartProduct = ecomStoreCart?.ecomStoreCartProducts.find(
    (p) => p.pkEcomStoreCartProduct === pkEcomStoreCartProduct
  );
  const selectedProduct = state.ecomOrderPurchaseProducts.value.find(
    (p) => p.uuid === formState.selectedProductUuid
  );
  const selectedVariant = selectedCartProduct
    ? selectedCartProduct?.ecomStoreProductVariant
    : selectedProduct?.ecomStoreProductVariants.find(
        (v) =>
          formState.selectedOptions.length ===
            v.ecomVendorProductVariant.ecomVendorProductVariantOptions.length &&
          v.ecomVendorProductVariant.ecomVendorProductVariantOptions.every(
            (op) =>
              formState.selectedOptions.some(
                (f) => f.name === op.name && f.value === op.value
              )
          )
      );

  const ecomStoreProducts = state.ecomOrderPurchaseProducts.value.filter(
    (p) =>
      !formState.productSearch ||
      p.ecomVendorProduct.name
        .toLowerCase()
        .includes(formState.productSearch.toLowerCase())
  );

  useEffect(() => {
    if (isOpen) {
      const product = selectedCartProduct
        ? state.ecomOrderPurchaseProducts.value.find(
            (p) =>
              p.uuid ===
              selectedCartProduct.ecomStoreProductVariant.ecomStoreProduct.uuid
          )
        : state.ecomOrderPurchaseProducts.value.length === 1
        ? state.ecomOrderPurchaseProducts.value[0]
        : null;

      setFormState({
        productSearch: '',
        selectedProductUuid: product?.uuid ?? null,
        selectedOptions: [],
        ecomStoreCartProductInputAnswers: [],
        count: 1,
        loading: false
      });
    }
  }, [isOpen, state.ecomOrderPurchaseProducts.value, selectedCartProduct]);

  const onSuccess = () => {
    setFormState({
      ...formState,
      optionErrors: [],
      loading: false
    });
    onClose();
  };

  const onError = (error, type) => {
    if (type === 'input') {
      setFormState({
        ...formState,
        optionErrors: [error],
        loading: false
      });
    }
    else {
      triggerNotification(error);
      setFormState({
        ...formState,
        optionErrors: [],
        loading: false
      });
    }
  };

  const onAddProductToCart = () => {
    setFormState({
      ...formState,
      loading: true,
      optionErrors: []
    });
    addStoreProducts(
      authState.pkEcomStore,
      {
        ecomStoreCartProducts: [
          {
            ecomStoreProduct: selectedProduct,
            uuid: selectedVariant.ecomVendorProductVariant.uuid,
            count: formState.count,
            ecomStoreCartProductInputAnswers:
              formState.ecomStoreCartProductInputAnswers
          }
        ]
      },
      onSuccess,
      onError
    );
  };

  const onUpdateCartProduct = () => {
    setFormState({ ...formState, optionErrors: [], loading: true });
    updateStoreCartProducts(
      authState.pkEcomStore,
      {
        ecomStoreCartProducts: [
          {
            ecomStoreProduct: selectedProduct,
            pkEcomStoreCartProduct: selectedCartProduct.pkEcomStoreCartProduct,
            uuid:
              selectedCartProduct.ecomStoreProductVariant
                .ecomVendorProductVariant.uuid,
            ecomStoreCartProductInputAnswers:
              formState.ecomStoreCartProductInputAnswers
          }
        ]
      },
      onSuccess,
      onError
    );
  };

  return (
    <ResponsiveModal
      formSectionProps={{
        cancelTitle:
          !selectedCartProduct &&
          state.ecomOrderPurchaseProducts.value.length > 1 &&
          !!selectedProduct
            ? 'Back'
            : 'Cancel',
        onCancel: () => {
          if (
            !selectedCartProduct &&
            state.ecomOrderPurchaseProducts.value.length > 1 &&
            !!selectedProduct
          ) {
            setFormState({
              ...formState,
              selectedProductUuid: null
            });
          }
          else {
            onClose();
          }
        },
        isLoading: formState.loading,
        isSubmitHidden: !selectedProduct,
        submitTitle: selectedCartProduct ? 'Customize product' : 'Add product',
        onSubmit: selectedCartProduct
          ? onUpdateCartProduct
          : onAddProductToCart,
        padding: 0
      }}
      isLoading={state.ecomOrderPurchaseProducts.loading}
      isOpen={isOpen}
      onClose={onClose}
      size={selectedProduct ? 1200 : 600}
      title={selectedCartProduct ? 'Update product' : 'Add product to purchase'}
    >
      {!selectedProduct ? (
        <AppStack style={{ gap: 0 }}>
          <AppStack style={{ gap: 8, padding: 16 }}>
            <AppText
              style={{ textAlign: 'center', fontSize: 14, color: '#666' }}
            >
              Select the product to purchase.
            </AppText>
          </AppStack>

          <TextInput
            onChange={(e) => {
              setFormState({
                ...formState,
                productSearch: e.currentTarget.value
              });
            }}
            placeholder="Search by title"
            radius={0}
            style={{ flex: 1 }}
            value={formState.productSearch}
          />

          <AppStack style={{ gap: 0 }}>
            {ecomStoreProducts.length === 0 ? (
              <AppFlexbox
                style={{
                  flex: 1,
                  gap: 8,
                  padding: 16,
                  justifyContent: 'center'
                }}
              >
                <AppText
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    textAlign: 'center',
                    color: '#666'
                  }}
                >
                  {formState.productSearch
                    ? 'No products found'
                    : 'No products available'}
                </AppText>
              </AppFlexbox>
            ) : (
              ecomStoreProducts.map((p, index) => {
                const lowestPrice = Math.min(
                  ...p.ecomStoreProductVariants.map((v) => v.price)
                );
                const hasOtherPrices = p.ecomStoreProductVariants.some(
                  (v) => v.price !== lowestPrice
                );

                return (
                  <React.Fragment key={p.uuid}>
                    {index > 0 && <Divider />}
                    <AppFlexbox
                      key={p.pkEcomStoreProduct}
                      className={classes['hover-action-card']}
                      onClick={() => {
                        setFormState({
                          ...formState,
                          selectedProductUuid: p.uuid
                        });
                      }}
                      style={{ flex: 1, gap: 8, padding: 12 }}
                    >
                      <AppFlexbox style={{ flex: 1, gap: 8 }}>
                        <AppStack
                          style={{
                            width: 60,
                            height: 60,
                            borderRadius: 8,
                            backgroundColor: '#f0f0f0',
                            overflow: 'hidden',
                            border: '1px solid lightgrey'
                          }}
                        >
                          <Image
                            fit="contain"
                            src={
                              p.ecomVendorProduct.ecomVendorProductMedia.sort(
                                (a, b) => a.sort - b.sort
                              )[0].src
                            }
                            style={{ height: '100%', width: '100%' }}
                          />
                        </AppStack>
                        <AppStack style={{ gap: 0 }}>
                          <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                            {p.ecomVendorProduct.name}
                          </AppText>
                          <AppText style={{ fontSize: 14, color: '#666' }}>
                            {singularPluralFormat(
                              p.ecomStoreProductVariants.length,
                              'variant',
                              'variants'
                            )}
                          </AppText>
                        </AppStack>
                      </AppFlexbox>
                      <AppStack style={{ gap: 0 }}>
                        <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                          {hasOtherPrices && 'From '}

                          <NumberFormatter
                            decimalScale={2}
                            displayType="text"
                            fixedDecimalScale
                            prefix="$"
                            style={{ fontSize: 14, fontWeight: 500 }}
                            thousandSeparator
                            value={lowestPrice / 100}
                          />
                        </AppText>
                      </AppStack>
                    </AppFlexbox>
                  </React.Fragment>
                );
              })
            )}
          </AppStack>
        </AppStack>
      ) : (
        <AppStack style={{ gap: 16, padding: 16 }}>
          <SelectProductOptionsForm
            baseStoreUrl="/merchant"
            ecomStoreCartProduct={selectedCartProduct}
            ecomStoreProduct={selectedProduct}
            formState={formState}
            isOpen={isOpen}
            mediaQueries={mediaQueries}
            onNavigate={() => {}}
            productInCartCount={0}
            productUrl={`/merchant/products/${selectedProduct.uuid}`}
            setFormState={(s) =>
              setFormState({
                ...formState,
                ...s
              })
            }
          />
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

StoreOrderPurchaseProductModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  pkEcomOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pkEcomStoreCartProduct: PropTypes.number
};

export default StoreOrderPurchaseProductModal;
