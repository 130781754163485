import React from 'react';
import PropTypes from 'prop-types';
import { Anchor, Checkbox, Select, TextInput, Tooltip } from '@mantine/core';
import { BuildingStore } from 'tabler-icons-react';
import { PatternFormat } from 'react-number-format';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import {
  CANADA_PROVINCE_LIST_OPTIONS,
  USA_STATE_LIST_OPTIONS
} from '../../../config/locationConstants';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import * as classes from '../../../styles/nestedStyles.module.css';
import CustomerAddressInfoCard from './CustomerAddressInfoCard';

const CustomerAddressForm = ({
  formState,
  setFormState,
  editDisabledMessage,
  showDefaultAddressSelect,
  onDefaultAddressSelect,
  isDefault,
  disabled,
  defaultDisabled,
  onDelete,
  includePhone,
  removeTitle
}) => (
  <AppStack>
    {editDisabledMessage ? (
      <>
        <CustomerAddressInfoCard
          ecomCustomerAddress={formState}
          icon={<BuildingStore size={50} />}
        />
        {editDisabledMessage}
      </>
    ) : (
      <>
        <TextInput
          disabled={disabled}
          label="Full name"
          onChange={(e) =>
            setFormState({
              ...formState,
              fullName: e.target.value
            })
          }
          required
          value={formState.fullName || ''}
        />

        <TextInput
          disabled={disabled}
          label="Address 1"
          onChange={(e) =>
            setFormState({
              ...formState,
              address1: e.target.value
            })
          }
          required
          value={formState.address1 || ''}
        />

        <TextInput
          disabled={disabled}
          label="Address 2"
          onChange={(e) =>
            setFormState({
              ...formState,
              address2: e.target.value
            })
          }
          value={formState.address2 || ''}
        />

        <Select
          clearable
          data={[
            { label: 'Canada', value: 'CA' },
            { label: 'United States', value: 'US' }
          ]}
          disabled={disabled}
          label="Country"
          onChange={(v) =>
            setFormState({
              ...formState,
              country: v
            })
          }
          required
          searchable
          value={formState.country || ''}
        />

        <AppFlexbox style={{ flex: 1 }}>
          <TextInput
            disabled={disabled}
            label="City"
            onChange={(e) =>
              setFormState({
                ...formState,
                city: e.target.value
              })
            }
            required
            style={{ flex: 1 }}
            value={formState.city || ''}
          />
          <Select
            data={
              formState.country === 'CA'
                ? CANADA_PROVINCE_LIST_OPTIONS.map((p) => ({
                    label: p.label,
                    value: p.abbr
                  }))
                : USA_STATE_LIST_OPTIONS.map((s) => ({
                    label: s.label,
                    value: s.abbr
                  }))
            }
            disabled={disabled}
            label={formState.country === 'CA' ? 'Province' : 'State'}
            onChange={(v) =>
              setFormState({
                ...formState,
                state: v
              })
            }
            required
            style={{ flex: 1 }}
            value={formState.state || null}
          />
        </AppFlexbox>
        <TextInput
          disabled={disabled}
          label="Postal code"
          onChange={(e) =>
            setFormState({
              ...formState,
              postalCode: e.target.value
            })
          }
          required
          value={formState.postalCode || ''}
        />

        {includePhone && (
          <PatternFormat
            customInput={TextInput}
            disabled={formState.isLoading}
            format="+1 (###) ###-####"
            label="Phone Number"
            mask="_"
            name="phoneNumber"
            onValueChange={(values) => {
              setFormState({
                ...formState,
                phone: values.value
              });
            }}
            style={{ flex: 1 }}
            styles={{
              label: {
                fontSize: 16,
                fontWeight: 500
              }
            }}
            value={formState.phone}
          />
        )}
      </>
    )}

    {showDefaultAddressSelect && (
      <Tooltip
        disabled={!defaultDisabled}
        label="You must assign a different address as the default to remove this one"
        multiline
        w={250}
        withArrow
      >
        <AppCard
          className={
            disabled || defaultDisabled ? null : classes['hover-action-card']
          }
          onClick={
            onDefaultAddressSelect
              ? () => {
                  if (!disabled && !defaultDisabled) {
                    onDefaultAddressSelect();
                  }
                }
              : null
          }
          radius={8}
          shadow="none"
          style={{
            padding: 16,
            cursor: disabled || defaultDisabled ? 'not-allowed' : 'pointer',
            ...((disabled || defaultDisabled) && { backgroundColor: '#dee2e6' })
          }}
          withBorder
        >
          <AppFlexbox style={{ gap: 16, alignItems: 'center' }}>
            <Checkbox
              checked={isDefault}
              disabled={disabled || defaultDisabled}
              onChange={() => {}}
              styles={{input: {
                  cursor: onDefaultAddressSelect ? 'pointer' : 'not-allowed'
                }}}
            />

            <AppStack style={{ gap: 5 }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                Set as default address
              </AppText>
              <AppText style={{ fontSize: 14 }}>
                Use this address to automatically process group orders. For
                manual group order fulfillment, you'll be prompted to select an
                address.
              </AppText>

              <AppText
                style={{ fontSize: 13, color: '#666', fontStyle: 'italic' }}
              >
                You can update this setting anytime.
              </AppText>
            </AppStack>
          </AppFlexbox>
        </AppCard>
      </Tooltip>
    )}

    {onDelete &&
      (formState.isDefault ? (
        <AppFlexbox style={{ justifyContent: 'center' }}>
          <AppText
            style={{
              textAlign: 'center',
              color: '#999',
              fontSize: 14,
              fontStyle: 'italic'
            }}
          >
            Default address cannot be deleted
          </AppText>
        </AppFlexbox>
      ) : (
        <AppFlexbox style={{ justifyContent: 'center' }}>
          <Anchor
            onClick={onDelete}
            style={{
              textAlign: 'center',
              color: '#C40000',
              fontSize: 14
            }}
          >
            {removeTitle ?? 'Delete address'}
          </Anchor>
        </AppFlexbox>
      ))}
  </AppStack>
);

CustomerAddressForm.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  defaultDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  editDisabled: PropTypes.bool,
  editDisabledMessage: PropTypes.any,
  includePhone: PropTypes.bool,
  isDefault: PropTypes.bool,
  onDefaultAddressSelect: PropTypes.func,
  onDelete: PropTypes.func,
  removeTitle: PropTypes.string,
  showDefaultAddressSelect: PropTypes.bool
};

export default CustomerAddressForm;
