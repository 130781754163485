import dayjs from 'dayjs';
import {
  CURRENCY_TYPE_LIST,
  ECOM_ORDER_PRODUCT_STATUS_ENUM,
  ECOM_ORDER_STATUS_ENUM
} from '../../../config/constants';

const DATA_FIELDS = {
  // ORDER
  pkEcomOrder: 'pkEcomOrder',
  status: 'status',
  createdAt: 'createdAt',
  completedAt: 'completedAt',

  // CHECKOUT
  currency: 'currency',
  subtotal: 'subtotal',
  discount: 'discount',
  shipping: 'shipping',
  taxes: 'taxes',
  taxesIncluded: 'taxesIncluded',
  total: 'total',

  // CUSTOMER
  customerName: 'customerName',
  customerEmail: 'customerEmail',

  // PRODUCT
  itemQuantity: 'itemQuantity',
  itemName: 'itemName',
  itemOptions: 'itemOptions',
  itemProperties: 'itemProperties',
  itemPrice: 'itemPrice',
  itemTotalDiscount: 'itemTotalDiscount',
  itemTotalPrice: 'itemTotalPrice',
  itemSKU: 'itemSKU',
  itemRequiresShipping: 'itemRequiresShipping',
  itemTaxable: 'itemTaxable',
  itemVendor: 'itemVendor',

  // BILLING
  billingName: 'billingName',
  billingAddress: 'billingAddress',
  billingCity: 'billingCity',
  billingPostalCode: 'billingPostalCode',
  billingCountry: 'billingCountry',
  billingPhone: 'billingPhone',

  // SHIPPING
  shippingMethod: 'shippingMethod',
  shippingName: 'shippingName',
  shippingAddress: 'shippingAddress',
  shippingCity: 'shippingCity',
  shippingPostalCode: 'shippingPostalCode',
  shippingCountry: 'shippingCountry',
  shippingPhone: 'shippingPhone'
};

const EXPORT_ORDER_FIELDS = [
  {
    label: 'Order ID',
    selectDisabled: true,
    exportColumns: [{ dataValue: DATA_FIELDS.pkEcomOrder }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.pkEcomOrder]: index === 0 ? order.pkEcomOrder : ''
    })
  },
  {
    label: 'Customer name',
    exportColumns: [{ dataValue: DATA_FIELDS.customerName }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.customerName]:
        index === 0 ? order.ecomCustomer.user?.name : ''
    })
  },
  {
    label: 'Customer email',
    selectDisabled: true,
    exportColumns: [{ dataValue: DATA_FIELDS.customerEmail }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.customerEmail]: index === 0 ? order.ecomCustomer.email : ''})
  },
  {
    label: 'Status',
    selectDisabled: true,
    exportColumns: [{ dataValue: DATA_FIELDS.status }],
    getExportValue: ({ order, payment, products }, index) => {
      if (index !== 0) {
        return { [DATA_FIELDS.status]: '' };
      }

      const productInfo = order.ecomOrderProducts.reduce(
        (
          r,
          { count, cancelledCount, fkEcomOrderProductStatus, isBulkShipment }
        ) => ({
          itemCount: r.itemCount + count,
          cancelledCount: r.cancelledCount + cancelledCount,
          isFullyFulfilled: isBulkShipment
            ? r.isFullyFulfilled
            : r.isFullyFulfilled &&
              ![
                ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
              ].includes(fkEcomOrderProductStatus.toString()),
          hasPendingProduct: isBulkShipment
            ? r.hasPendingProduct
            : r.hasPendingProduct ||
              fkEcomOrderProductStatus.toString() ===
                ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
        }),
        {
          itemCount: 0,
          cancelledCount: 0,
          isFullyFulfilled: true,
          hasPendingProduct: false
        }
      );

      const bulkInfo = order.ecomOrderProductBulk.reduce(
        (r, { count, cancelledCount, fkEcomOrderProductStatus }) => ({
          itemCount: r.itemCount + count,
          cancelledCount: r.cancelledCount + cancelledCount,
          isFullyFulfilled:
            r.isFullyFulfilled &&
            ![
              ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING,
              ECOM_ORDER_PRODUCT_STATUS_ENUM.ACCEPTED
            ].includes(fkEcomOrderProductStatus.toString()),
          hasPendingProduct:
            r.hasPendingProduct ||
            fkEcomOrderProductStatus.toString() ===
              ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
        }),
        {
          itemCount: 0,
          cancelledCount: 0,
          isFullyFulfilled: true,
          hasPendingProduct: false
        }
      );

      const isFullyCancelled =
        !order.isBulkShipment &&
        productInfo.cancelledCount === productInfo.itemCount;

      const status =
        order.fkEcomOrderStatus.toString() ===
        ECOM_ORDER_STATUS_ENUM.COMPLETED_OFFLINE
          ? 'Completed Offline'
          : order.fkEcomOrderStatus.toString() ===
            ECOM_ORDER_STATUS_ENUM.ACCUMULATING
          ? 'Accumulating'
          : isFullyCancelled
          ? 'Cancelled'
          : productInfo.isFullyFulfilled && bulkInfo.isFullyFulfilled
          ? 'Fulfilled'
          : productInfo.hasPendingProduct || bulkInfo.hasPendingProduct
          ? 'Pending'
          : 'Unfulfilled';

      return { [DATA_FIELDS.status]: status };
    }
  },
  {
    label: 'Created at',
    exportColumns: [{ dataValue: DATA_FIELDS.createdAt }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.createdAt]:
        index === 0
          ? dayjs(order.purchasedAt).format('MMMM D, YYYY h:mm A')
          : ''
    })
  },
  {
    label: 'Completed at',
    selectDisabled: true,
    exportColumns: [{ dataValue: DATA_FIELDS.completedAt }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.completedAt]:
        index === 0 && order.purchasedAt
          ? dayjs(order.purchasedAt).format('MMMM D, YYYY h:mm A')
          : ''
    })
  }
];

const EXPORT_CHECKOUT_FIELDS = [
  {
    label: 'Currency',
    exportColumns: [{ dataValue: DATA_FIELDS.currency }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.currency]:
        index === 0
          ? CURRENCY_TYPE_LIST.find(
              (c) => c.value.toString() === order.fkCurrencyType?.toString()
            )?.abbr
          : ''
    })
  },
  {
    label: 'Subtotal',
    exportColumns: [{ dataValue: DATA_FIELDS.subtotal }],
    getExportValue: ({ order, payment, products, isVendorView }, index) => ({
      [DATA_FIELDS.subtotal]:
        index === 0
          ? order.isBulkShipment || isVendorView
            ? [
                ...order.ecomOrderProducts,
                ...order.ecomOrderProductBulk.map((m) => ({
                  ...m.ecomOrderProduct,
                  ...m
                }))
              ].reduce(
                (r, c) => r + (c.productCost + c.addonCost) * c.count,
                0
              ) / 100
            : (payment.amount -
                (payment.ecomStoreCartPaymentFee?.tieredPricingAmount || 0)) /
              100
          : ''
    })
  },
  {
    label: 'Discount',
    exportColumns: [{ dataValue: DATA_FIELDS.discount }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.discount]: index === 0 ? 0 : ''})
  },
  {
    label: 'Shipping',
    exportColumns: [{ dataValue: DATA_FIELDS.shipping }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.shipping]: index === 0 ? 0 : ''})
  },
  {
    label: 'Taxes',
    exportColumns: [{ dataValue: DATA_FIELDS.taxes }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.taxes]: index === 0 ? 0 : ''})
  },
  {
    label: 'Taxes included',
    exportColumns: [{ dataValue: DATA_FIELDS.taxesIncluded }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.taxesIncluded]: index === 0 ? 'NO' : ''})
  },
  {
    label: 'Tiered pricing',
    isStoreOnly: true,
    exportColumns: [{ dataValue: DATA_FIELDS.tieredPricing }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.tieredPricing]:
        index === 0
          ? payment?.ecomStoreCartPaymentFee?.tieredPricingAmount || 0
          : ''
    })
  },
  {
    label: 'Total',
    exportColumns: [{ dataValue: DATA_FIELDS.total }],
    getExportValue: ({ order, payment, products, isVendorView }, index) => ({
      [DATA_FIELDS.total]:
        index === 0
          ? order.isBulkShipment || isVendorView
            ? [
                ...order.ecomOrderProducts,
                ...order.ecomOrderProductBulk.map((m) => ({
                  ...m.ecomOrderProduct,
                  ...m
                }))
              ].reduce(
                (r, c) => r + (c.productCost + c.addonCost) * c.count,
                0
              ) / 100
            : payment.amount / 100
          : ''
    })
  }
];

const EXPORT_PRODUCT_FIELDS = [
  {
    label: 'Item quantity',
    exportColumns: [{ dataValue: DATA_FIELDS.itemQuantity }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.itemQuantity]: products.reduce((r, c) => r + c.count, 0)})
  },
  {
    label: 'Item name',
    exportColumns: [{ dataValue: DATA_FIELDS.itemName }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.itemName]: products[0].productName})
  },
  {
    label: 'Item options',
    exportColumns: [{ dataValue: DATA_FIELDS.itemOptions }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.itemOptions]: products[0].ecomVendorProductVariantOptions
        .map((op) => op.value)
        .join(' / ')
    })
  },
  {
    label: 'Item properties',
    exportColumns: [{ dataValue: DATA_FIELDS.itemProperties }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.itemProperties]: products[0].ecomOrderProductInputAnswers
        .map((op) => `${op.label}: ${op.value}`)
        .join('\n')
    })
  },
  {
    label: 'Item price',
    exportColumns: [{ dataValue: DATA_FIELDS.itemPrice }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.itemPrice]:
        products.reduce((r, c) => r + (c.productCost + c.addonCost), 0) / 100
    })
  },
  {
    label: 'Item total discount',
    exportColumns: [{ dataValue: DATA_FIELDS.itemTotalDiscount }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.itemTotalDiscount]: '0'})
  },
  {
    label: 'Item total price',
    exportColumns: [{ dataValue: DATA_FIELDS.itemTotalPrice }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.itemTotalPrice]:
        products.reduce(
          (r, c) => r + (c.productCost + c.addonCost) * c.count,
          0
        ) / 100
    })
  },
  {
    label: 'Item SKU',
    exportColumns: [{ dataValue: DATA_FIELDS.itemSKU }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.itemSKU]: products[0].sku})
  },
  {
    label: 'Item requires shipping',
    exportColumns: [{ dataValue: DATA_FIELDS.itemRequiresShipping }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.itemRequiresShipping]: 'YES'})
  },
  {
    label: 'Item taxable',
    exportColumns: [{ dataValue: DATA_FIELDS.itemTaxable }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.itemTaxable]: 'NO'})
  },
  {
    label: 'Item vendor',
    exportColumns: [{ dataValue: DATA_FIELDS.itemVendor }],
    getExportValue: ({ order, payment, products }, index) => ({[DATA_FIELDS.itemVendor]: products[0].vendorName})
  }
];

const EXPORT_BILLING_FIELDS = [
  {
    label: 'Billing name',
    isStoreOnly: true,
    exportColumns: [{ dataValue: DATA_FIELDS.billingName }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.billingName]:
        index === 0 ? payment?.ecomCustomerAddressBilling?.fullName : ''
    })
  },
  {
    label: 'Billing address',
    isStoreOnly: true,
    exportColumns: [{ dataValue: DATA_FIELDS.billingAddress }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.billingAddress]:
        index === 0 ? payment?.ecomCustomerAddressBilling?.address1 : ''
    })
  },
  {
    label: 'Billing city',
    isStoreOnly: true,
    exportColumns: [{ dataValue: DATA_FIELDS.billingCity }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.billingCity]:
        index === 0 ? payment?.ecomCustomerAddressBilling?.city : ''
    })
  },
  {
    label: 'Billing postal code',
    isStoreOnly: true,
    exportColumns: [{ dataValue: DATA_FIELDS.billingPostalCode }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.billingPostalCode]:
        index === 0 ? payment?.ecomCustomerAddressBilling?.postalCode : ''
    })
  },
  {
    label: 'Billing country',
    isStoreOnly: true,
    exportColumns: [{ dataValue: DATA_FIELDS.billingCountry }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.billingCountry]:
        index === 0 ? payment?.ecomCustomerAddressBilling?.country : ''
    })
  },
  {
    label: 'Billing phone',
    isStoreOnly: true,
    exportColumns: [{ dataValue: DATA_FIELDS.billingPhone }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.billingPhone]:
        index === 0 ? payment?.ecomCustomerAddressBilling?.phone : ''
    })
  }
];

const EXPORT_SHIPPING_FIELDS = [
  {
    label: 'Shipping name',
    exportColumns: [{ dataValue: DATA_FIELDS.shippingName }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.shippingName]:
        index === 0 ? order.ecomCustomerAddressShipping.fullName : ''
    })
  },
  {
    label: 'Shipping address',
    exportColumns: [{ dataValue: DATA_FIELDS.shippingAddress }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.shippingAddress]:
        index === 0 ? order.ecomCustomerAddressShipping.address1 : ''
    })
  },
  {
    label: 'Shipping city',
    exportColumns: [{ dataValue: DATA_FIELDS.shippingCity }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.shippingCity]:
        index === 0 ? order.ecomCustomerAddressShipping.city : ''
    })
  },
  {
    label: 'Shipping postal code',
    exportColumns: [{ dataValue: DATA_FIELDS.shippingPostalCode }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.shippingPostalCode]:
        index === 0 ? order.ecomCustomerAddressShipping.postalCode : ''
    })
  },
  {
    label: 'Shipping country',
    exportColumns: [{ dataValue: DATA_FIELDS.shippingCountry }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.shippingCountry]:
        index === 0 ? order.ecomCustomerAddressShipping.country : ''
    })
  },
  {
    label: 'Shipping phone',
    exportColumns: [{ dataValue: DATA_FIELDS.shippingPhone }],
    getExportValue: ({ order, payment, products }, index) => ({
      [DATA_FIELDS.shippingPhone]:
        index === 0 ? order.ecomCustomerAddressShipping.phone : ''
    })
  }
];

const orderExportConfig = {
  fieldSections: [
    {
      name: 'Order',
      value: 'ORDER',
      fields: EXPORT_ORDER_FIELDS
    },
    {
      name: 'Checkout',
      value: 'CHECKOUT',
      fields: EXPORT_CHECKOUT_FIELDS
    },
    {
      name: 'Product',
      value: 'PRODUCT',
      fields: EXPORT_PRODUCT_FIELDS
    },
    {
      name: 'Billing',
      value: 'BILLING',
      isStoreOnly: true,
      fields: EXPORT_BILLING_FIELDS
    },
    {
      name: 'Shipping',
      value: 'SHIPPING',
      fields: EXPORT_SHIPPING_FIELDS
    }
  ],
  getCsvData: (ecomOrders = [], isVendorView = false) =>
    ecomOrders.flatMap((order) => {
      // Combine individual and bulk order products
      const ecomOrderProducts = [
        ...order.ecomOrderProducts,
        ...order.ecomOrderProductBulk
          .filter(
            (b) =>
              ![
                ECOM_ORDER_PRODUCT_STATUS_ENUM.TRANSFERRED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.CANCELLED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.REFUNDED
              ].includes(b.fkEcomOrderProductStatus.toString())
          )
          .map((b) => ({
            ...b.ecomOrderProduct,
            ...b
          }))
      ];

      // Group products by a generated title
      const groupedProducts = ecomOrderProducts.reduce((acc, product) => {
        const title = [
          product.vendorProductUuid,
          product.productName,
          product.ecomVendorProductVariantOptions
            .map((op) => `${op.name}/${op.value}`)
            .join('-'),
          product.ecomOrderProductInputAnswers
            .map((i) => `${i.label}/${i.value}`)
            .join('-')
        ].join('-');

        acc[title] = acc[title] || { title, ecomOrderProducts: [] };
        acc[title].ecomOrderProducts.push(product);
        return acc;
      }, {});

      // Get first payment with a billing address
      const firstPayment = order.ecomOrderPayments
        .filter((payment) => payment.ecomCustomerAddressBilling)
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))[0];

      // Return mapped order data
      return Object.values(groupedProducts).map((group, index) => [
        {
          order,
          payment: firstPayment,
          products: group.ecomOrderProducts,
          isVendorView
        },
        index
      ]);
    })
};

export { orderExportConfig };
