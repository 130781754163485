import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import { adminAnalyticsAndReportsConfig } from '../analytics/adminAnalyticsAndReportsConfig';
import ReportTableListView from '../analytics/ReportTableListView';
import ReportView from '../analytics/ReportView';

const AdminReportsView = () => {
  const { state, generateAdminReport, fetchAdminResourceOptions } = useContext(
    AdminContext
  );

  useEffect(() => {
    if (!state.ecomResourceOptions.value) {
      fetchAdminResourceOptions();
    }
  }, []);

  return (
    <Routes>
      <Route
        element={
          <ReportTableListView
            baseUrl="/admin"
            reports={adminAnalyticsAndReportsConfig.reports}
          />
        }
        path="/"
      />
      <Route
        element={
          <ReportView
            entityId={1}
            isAdminView
            onGenerateReport={(data, onSuccess, onError) =>
              generateAdminReport(data, onSuccess, onError)
            }
            reportConfigs={adminAnalyticsAndReportsConfig.reports}
            reportResult={state.ecomAdminReport.value}
            reportViewType="admin"
            resourcesLoading={state.ecomResourceOptions.loading}
            urlBase="/admin"
            viewAsOptions={
              state.ecomResourceOptions.value
                ? [
                    {
                      groupValue: 'store',
                      groupLabel: 'Store',
                      options: state.ecomResourceOptions.value.ecomStores
                        .map((s) => ({
                          label: s.name,
                          image: s.logoImageUrl,
                          value: s.pkEcomStore.toString()
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))
                    },
                    {
                      groupValue: 'vendor',
                      groupLabel: 'Vendor',
                      options: state.ecomResourceOptions.value.ecomVendors
                        .map((s) => ({
                          label: s.name,
                          image: s.logoImageUrl,
                          value: s.pkEcomVendor.toString()
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))
                    }
                  ]
                : []
            }
          />
        }
        path="/:uuid"
      />
      <Route element={<Navigate replace to="/admin/reports" />} path="*" />
    </Routes>
  );
};

export default AdminReportsView;
