import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Divider, List } from '@mantine/core';
import { Link } from 'react-router-dom';
import { AlertCircle, CircleCheck, CircleX } from 'tabler-icons-react';
import ResponsiveModal from '../../common/ResponsiveModal';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import { singularPluralFormat } from '../../../helpers/format';
import AppCheckbox from '../../common/AppCheckbox';
import { ECOM_ORDER_STATUS_ENUM } from '../../../config/constants';

const StoreOrderProcessModal = ({
  isOpen,
  onClose,
  ecomOrder,
  groupedEcomOrderProducts,
  overrideSubmitOrder
}) => {
  const { state: authState } = useContext(AuthContext);
  const { submitEcomStoreOrder } = useContext(StoreContext);
  const [formState, setFormState] = useState({
    showSplitOrderConfirmation: false,
    orderSplitConfirmed: false,
    skipNotification: false,
    loading: false
  });

  const groupProduct = groupedEcomOrderProducts[0] || {};
  const isCompletedOffline =
    ecomOrder?.fkEcomOrderStatus.toString() ===
    ECOM_ORDER_STATUS_ENUM.COMPLETED_OFFLINE.toString();
  const canProcessOrder =
    overrideSubmitOrder ||
    (isCompletedOffline && authState.userData.isAdmin) ||
    (groupProduct && groupProduct.minimumAmountReachedCount > 0);
  const leftoverItemCount =
    groupProduct.minimumAmountRequiredRemaining > 0
      ? groupProduct.minimumQuantity -
        groupProduct.minimumAmountRequiredRemaining
      : 0;
  const amountQualified = groupProduct.quantity - leftoverItemCount;

  useEffect(() => {
    if (isOpen) {
      setFormState({
        showSplitOrderConfirmation:
          groupProduct.minimumAmountReachedCount > 0 &&
          groupProduct.minimumAmountRequiredRemaining > 0,
        orderSplitConfirmed: false,
        skipNotification: false,
        loading: false
      });
    }
  }, [isOpen]);

  const onSubmitOrder = () => {
    setFormState({ ...formState, loading: true });
    submitEcomStoreOrder(
      authState.pkEcomStore,
      overrideSubmitOrder
        ? {
            pkEcomOrder: ecomOrder.pkEcomOrder,
            overrideSubmitRestrictions: overrideSubmitOrder
          }
        : {
            pkEcomOrder: ecomOrder.pkEcomOrder,
            skipNotification: formState.skipNotification,
            orderSplitConfirmed: formState.orderSplitConfirmed
          },
      () => {
        triggerNotification(
          `Order #${ecomOrder.pkEcomOrder} submitted!`,
          'success'
        );
        onClose();
      },
      (e) => {
        triggerNotification(e);
        setFormState({ ...formState, loading: false });
      }
    );
  };

  return (
    <ResponsiveModal
      bodyProps={{ style: { padding: 0 } }}
      formSectionProps={
        canProcessOrder
          ? {
              onCancel: onClose,
              isLoading: formState.loading,
              isSubmitDisabled:
                !overrideSubmitOrder &&
                formState.showSplitOrderConfirmation &&
                !formState.orderSplitConfirmed,
              submitTitle:
                !overrideSubmitOrder && formState.showSplitOrderConfirmation
                  ? 'Continue'
                  : 'Yes, submit order',
              onSubmit: () => {
                if (
                  !overrideSubmitOrder &&
                  formState.showSplitOrderConfirmation
                ) {
                  setFormState({
                    ...formState,
                    showSplitOrderConfirmation: false
                  });
                }
                else {
                  onSubmitOrder();
                }
              },
              padding: 0
            }
          : null
      }
      isOpen={isOpen}
      onClose={onClose}
      size={500}
      title="Send order to vendor"
    >
      {overrideSubmitOrder ? (
        <AppStack style={{ gap: 0 }}>
          <AppStack style={{ gap: 8, padding: 16 }}>
            <AppText style={{ fontSize: 14 }}>
              Bypass the minimum quantity requirement and submit the order
              directly to the vendor.
            </AppText>
            <AppText
              style={{
                fontSize: 14,
                textAlign: 'center',
                color: '#666'
              }}
            >
              Confirm that the vendor is fully informed of the order details
            </AppText>
          </AppStack>

          <Divider />
          <AppStack style={{ gap: 16, padding: 16 }}>
            <AppText style={{ fontSize: 14 }}>
              Submitting this order will send the vendor a confirmation email
              and make the order visible in their system for fulfillment.
            </AppText>

            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Are you sure you want to continue?
            </AppText>
          </AppStack>
        </AppStack>
      ) : canProcessOrder ? (
        formState.showSplitOrderConfirmation ? (
          <AppStack style={{ gap: 16, padding: 16 }}>
            <Alert
              color="#eca70a"
              icon={<AlertCircle color="#eca70a" size={18} />}
              title={
                <AppText
                  style={{ fontSize: 14, color: '#000', fontWeight: 500 }}
                >
                  Split order to qualify
                </AppText>
              }
              variant="outline"
            >
              <AppStack>
                <AppText style={{ fontSize: 14, color: '#000' }}>
                  The order qualifies for{' '}
                  {singularPluralFormat(
                    groupProduct.minimumAmountReachedCount,
                    'submission',
                    'submissions'
                  )}{' '}
                  but has {leftoverItemCount} leftover item(s) that do not meet
                  the quantity requirement.
                </AppText>

                <AppStack style={{ gap: 8 }}>
                  <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                    You can:
                  </AppText>

                  <List style={{ paddingRight: 20 }} withPadding>
                    <List.Item>
                      <AppText style={{ fontSize: 14 }}>
                        Split the leftover items into a separate order and
                        submit the qualifying portion now.
                      </AppText>
                    </List.Item>
                    <List.Item>
                      <AppText style={{ fontSize: 14 }}>
                        Wait for additional purchases.
                      </AppText>
                    </List.Item>
                    <List.Item>
                      <AppText style={{ fontSize: 14 }}>
                        Transfer products from other stores you manage.
                      </AppText>
                    </List.Item>
                    <List.Item>
                      <AppText style={{ fontSize: 14 }}>
                        Purchase the remaining items
                      </AppText>
                    </List.Item>
                    <List.Item>
                      <AppText style={{ fontSize: 14 }}>
                        Refund the leftover amount.
                      </AppText>
                    </List.Item>
                  </List>
                </AppStack>
              </AppStack>
            </Alert>

            <AppStack style={{}}>
              <AppCheckbox
                checked={formState.orderSplitConfirmed}
                description="Select this option to continue with the qualifying portion of the order."
                label="Split leftover items into a separate order"
                onChange={() => {
                  setFormState({
                    ...formState,
                    orderSplitConfirmed: !formState.orderSplitConfirmed
                  });
                }}
                required
              />
            </AppStack>
          </AppStack>
        ) : (
          <AppStack style={{ gap: 0 }}>
            <AppStack style={{ gap: 8, padding: 16 }}>
              <AppCard radius={8} style={{ padding: 0 }} withBorder>
                <AppFlexbox style={{ flex: 1, gap: 0 }}>
                  <AppStack
                    style={{
                      gap: 8,
                      padding: 8,
                      justifyContent: 'center'
                    }}
                  >
                    <CircleCheck color="#067D62" size={62} />
                  </AppStack>
                  <Divider orientation="vertical" />
                  <AppStack
                    style={{
                      gap: 8,
                      padding: 8,
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppStack style={{ gap: 0 }}>
                      <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                        {groupProduct.name}
                      </AppText>
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        {amountQualified}/{groupProduct.quantity} qualifying
                      </AppText>
                    </AppStack>
                  </AppStack>
                </AppFlexbox>
              </AppCard>
            </AppStack>

            <Divider />
            <AppStack style={{ gap: 16, padding: 16 }}>
              <AppText style={{ fontSize: 14 }}>
                The {groupProduct.requireExactMinimum ? 'required' : 'minimum'}{' '}
                order quantity has been met, and the order is now ready for
                processing.
              </AppText>

              <AppText style={{ fontSize: 14 }}>
                Submitting this order will send the vendor a confirmation email
                and make the order visible in their system for fulfillment.
              </AppText>

              <AppText
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  textAlign: 'center',
                  fontStyle: 'italic'
                }}
              >
                No further changes can be made once the order is submitted.
              </AppText>

              {formState.orderSplitConfirmed && leftoverItemCount > 0 && (
                <Alert
                  color="#eca70a"
                  icon={<AlertCircle color="#eca70a" size={18} />}
                  title={
                    <AppText
                      style={{ fontSize: 14, color: '#000', fontWeight: 500 }}
                    >
                      Order will be split
                    </AppText>
                  }
                  variant="outline"
                >
                  <AppStack>
                    <AppText style={{ fontSize: 14 }}>
                      {singularPluralFormat(leftoverItemCount, 'item', 'items')}{' '}
                      will be placed into a separate order because they do not
                      meet the quantity requirement.
                    </AppText>

                    <AppText
                      style={{
                        fontSize: 14,
                        color: '#666',
                        fontStyle: 'italic'
                      }}
                    >
                      Items will be separated based on the last purchased date.
                    </AppText>
                  </AppStack>
                </Alert>
              )}
            </AppStack>

            <Divider />

            <AppStack style={{ gap: 8, padding: 16 }}>
              <AppText style={{ fontSize: 14 }}>
                Are you sure you want to continue?
              </AppText>

              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                This action cannot be undone.
              </AppText>
            </AppStack>
          </AppStack>
        )
      ) : (
        <AppStack style={{ gap: 0 }}>
          <AppStack style={{ gap: 8, padding: 16 }}>
            <AppCard radius={8} style={{ padding: 0 }} withBorder>
              <AppFlexbox style={{ flex: 1, gap: 0 }}>
                <AppStack
                  style={{
                    gap: 8,
                    padding: 8,
                    justifyContent: 'center'
                  }}
                >
                  <CircleX color="#C40000" size={62} />
                </AppStack>
                <Divider orientation="vertical" />
                <AppStack
                  style={{
                    gap: 8,
                    padding: 8,
                    justifyContent: 'space-between'
                  }}
                >
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                      {groupProduct.name}
                    </AppText>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      ({groupProduct.quantity}/
                      {groupProduct.minimumAmountRequired}) purchased
                    </AppText>
                  </AppStack>

                  <AppText
                    style={{ fontSize: 14, fontWeight: 500, color: '#C40000' }}
                  >
                    {groupProduct.minimumAmountRequiredRemaining} more required
                  </AppText>
                </AppStack>
              </AppFlexbox>
            </AppCard>
          </AppStack>

          <Divider />

          <AppStack style={{ gap: 16, padding: 16 }}>
            <AppText style={{ fontSize: 14 }}>
              Unable to submit the order because the{' '}
              {groupProduct.requireExactMinimum ? 'required' : 'minimum'} order
              quantity hasn’t been met.
            </AppText>

            <AppStack style={{ gap: 8 }}>
              <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                To proceed, you can:
              </AppText>

              <List withPadding>
                <List.Item>
                  <AppText style={{ fontSize: 14 }}>
                    Wait for additional purchases.
                  </AppText>
                </List.Item>
                <List.Item>
                  <AppText style={{ fontSize: 14 }}>
                    Transfer products from other stores you manage.
                  </AppText>
                </List.Item>
                <List.Item>
                  <AppText style={{ fontSize: 14 }}>
                    Purchase the remaining items
                  </AppText>
                </List.Item>
              </List>
            </AppStack>

            <AppText style={{ fontSize: 14 }}>
              If meeting the{' '}
              {groupProduct.requireExactMinimum ? 'required' : 'minimum'} order
              quantity isn’t possible, you also have the option to cancel the
              order.
            </AppText>
          </AppStack>

          <Divider />

          <AppStack style={{ gap: 16, padding: 16 }}>
            <Button
              color="dark"
              component={Link}
              to={`/merchant/group-orders/${ecomOrder?.pkEcomOrder}/transfer`}
              variant="filled"
            >
              Transfer products
            </Button>

            <Divider label="OR" labelPosition="center" />

            <Button
              color="dark"
              component={Link}
              to={`/merchant/group-orders/${ecomOrder?.pkEcomOrder}/purchase`}
              variant="outline"
            >
              Purchase remaining items
            </Button>
          </AppStack>
        </AppStack>
      )}
    </ResponsiveModal>
  );
};

StoreOrderProcessModal.propTypes = {
  ecomOrder: PropTypes.object,
  groupedEcomOrderProducts: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  overrideSubmitOrder: PropTypes.bool
};

export default StoreOrderProcessModal;
