import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AlertCircle, ArrowLeft } from 'tabler-icons-react';
import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  Skeleton,
  Tooltip
} from '@mantine/core';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as VendorContext } from '../../../providers/VendorContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import ActionableIcon from '../../common/ActionableIcon';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import {
  calculateVendorOrderRefundValues,
  translateVendorOrderData
} from '../../../helpers/vendorHelper';
import OrderProductsInfoCard from './OrderProductsInfoCard';
import OrderHelpModal, { ORDER_FULFILLMENT_TYPE_ENUM } from './OrderHelpModal';
import {
  calculateStoreOrderRefundValues,
  translateStoreOrderData
} from '../../../helpers/storeHelper';
import { currencyFormat } from '../../../helpers/format';
import CustomNumericFormat from '../../common/CustomNumericFormat';
import { triggerNotification } from '../../../helpers/notification';
import AppCheckbox from '../../common/AppCheckbox';
import OrderRefundConfirmModal from './OrderRefundConfirmModal';
import OrderProductSelect from './OrderProductSelect';
import OrderCancelProductSummary from './OrderCancelProductSummary';
import OrderRefundPaymentSummary from './OrderRefundPaymentSummary';
import { ECOM_ORDER_PRODUCT_STATUS_ENUM } from '../../../config/constants';

const OrderRefundView = ({ ecomOrder, loading, isVendorView }) => {
  const navigate = useNavigate();
  const { pkEcomOrder } = useParams();
  const { isDesktopOrSmaller } = useMediaQueryIndex();
  const { state: authState } = useContext(AuthContext);
  const {
    refundVendorOrder,
    fetchVendorOrder,
    fetchVendorSetupSummary
  } = useContext(VendorContext);
  const {
    refundStoreOrder,
    fetchStoreOrder,
    fetchStoreSetupSummary
  } = useContext(StoreContext);
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [formState, setFormState] = useState({
    ecomOrderProducts: [],
    refundAmount: 0,
    unlockRefundAmount: false,
    skipNotification: false,
    showConfirm: false,
    loading: false,
    error: null
  });
  const baseUrl = isVendorView ? '/vendor/orders' : '/merchant/orders';
  const orderData = isVendorView
    ? translateVendorOrderData(ecomOrder)
    : translateStoreOrderData(ecomOrder);
  const { ecomOrderProducts: productData } = isVendorView
    ? orderData
    : {
        ...orderData,
        ecomOrderProducts: orderData.ecomOrderProductsOriginal
      };
  const refundValues = isVendorView
    ? calculateVendorOrderRefundValues(
        ecomOrder,
        productData,
        formState.ecomOrderProducts
      )
    : calculateStoreOrderRefundValues(
        ecomOrder,
        productData,
        formState.ecomOrderProducts,
        formState.unlockRefundAmount
      );
  const {
    selectedItemCount,
    selectedItemsTotalUnitCost,
    selectedItemsStoreProfit,
    selectedItemsRefundValue,
    totalStoreProfit,
    serviceFeePaid,
    hasRefundedAllItems,
    totalProductFulfillmentCost,
    tiedUpProductCost,
    tieredPricing,
    availableRefundAmount,
    refundedAmountInCents,
    reimbursedAmount,
    expectedVsRecordedServiceFeeDifference,
    amountPaid
  } = refundValues;
  const pendingProducts = productData.filter((f) =>
    isVendorView
      ? f.amountRemaining > 0
      : f.fkEcomOrderProductStatus.toString() ===
        ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING
  );
  const firstPayment = isVendorView
    ? null
    : ecomOrder?.ecomOrderPayments.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      )[0];
  const accountPayee = firstPayment?.ecomStorePaymentProvider?.accountId;
  const isEscrowPayment = !accountPayee;

  const unavailableProducts = productData.filter(
    (f) =>
      (isVendorView
        ? f.amountRemaining < 0
        : f.fkEcomOrderProductStatus.toString() !==
          ECOM_ORDER_PRODUCT_STATUS_ENUM.PENDING) &&
      f.cancelledCount < f.quantity
  );

  useEffect(() => {
    if (!loading && ecomOrder) {
      const products = pendingProducts.map((p) => ({
        pkEcomOrderProduct: p.pkEcomOrderProduct,
        count: 0
      }));
      setFormState({
        ecomOrderProducts: products,
        refundAmount: 0,
        unlockRefundAmount: false,
        skipNotification: false,
        showConfirm: false,
        loading: false,
        error: null
      });
    }
  }, [ecomOrder, loading]);

  useEffect(() => {
    if (selectedItemsTotalUnitCost) {
      setFormState({
        ...formState,
        refundAmount: selectedItemsTotalUnitCost
      });
    }
  }, [selectedItemsTotalUnitCost]);

  const onSubmitRefund = () => {
    setFormState({
      ...formState,
      loading: true
    });
    const refundFunc = isVendorView ? refundVendorOrder : refundStoreOrder;
    refundFunc(
      isVendorView ? authState.pkEcomVendor : authState.pkEcomStore,
      {
        pkEcomOrder: ecomOrder.pkEcomOrder,
        refundAmount: formState.refundAmount,
        unlockRefundAmount: formState.unlockRefundAmount,
        ecomOrderReturnProducts: formState.ecomOrderProducts.filter(
          (p) => p.count > 0
        )
      },
      () => {
        triggerNotification(
          `Refunded ${currencyFormat(formState.refundAmount / 100)}`,
          'success'
        );
        if (isVendorView) {
          fetchVendorOrder(authState.pkEcomVendor, {pkEcomOrder: ecomOrder.pkEcomOrder});
          fetchVendorSetupSummary(authState.pkEcomVendor, {skipLoading: true});
        }
        else {
          fetchStoreOrder(authState.pkEcomStore, {pkEcomOrder: ecomOrder.pkEcomOrder});
          fetchStoreSetupSummary(authState.pkEcomStore, { skipLoading: true });
        }
        navigate(`${baseUrl}/${pkEcomOrder}`);
      },
      (e) => {
        setFormState({
          ...formState,
          loading: false,
          error: e
        });
        triggerNotification(e);
      }
    );
  };

  return !loading && ecomOrder ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <OrderHelpModal
        isOpen={modalState.isOpen && modalState.action === 'helpModal'}
        onClose={onCloseModal}
        type={ORDER_FULFILLMENT_TYPE_ENUM.STORE_REFUND}
      />

      <OrderRefundConfirmModal
        ecomOrder={ecomOrder}
        isOpen={formState.showConfirm}
        isVendorView={isVendorView}
        loading={formState.loading}
        onClose={() => {
          setFormState({
            ...formState,
            showConfirm: false
          });
        }}
        onRefund={onSubmitRefund}
        productData={pendingProducts}
        refundAmount={formState.refundAmount}
        selectedProducts={formState.ecomOrderProducts}
        skipNotification={formState.skipNotification}
        {...refundValues}
      />

      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={`${baseUrl}/${pkEcomOrder}`}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 0 }}>
            <AppText
              style={{
                flex: 1,
                fontSize: 24,
                fontWeight: 700,
                wordBreak: 'break-word'
              }}
            >
              Refund order #{ecomOrder.pkEcomOrder}
            </AppText>
          </AppStack>
        </AppFlexbox>
      </AppFlexbox>

      <AppFlexbox
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onOpenModal('confirm');
        }}
        style={{ flexDirection: isDesktopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          {!isVendorView && (
            <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
              <OrderCancelProductSummary
                isFullyCancelled={
                  hasRefundedAllItems || pendingProducts.length === 0
                }
                selectedItemCount={selectedItemCount}
                selectedItemsRefundValue={selectedItemsRefundValue}
                selectedItemsStoreProfit={selectedItemsStoreProfit}
                selectedItemsTotalUnitCost={selectedItemsTotalUnitCost}
                title="Cancelled products"
              />
            </AppCard>
          )}

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ padding: 0, gap: 0 }}>
              <AppStack style={{ gap: 0 }}>
                <AppFlexbox
                  style={{
                    gap: 12,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 16,
                    paddingBottom: 0
                  }}
                >
                  <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                    Pending products
                  </AppText>
                </AppFlexbox>
                {pendingProducts.length > 0 ? (
                  <>
                    <AppStack style={{ padding: 16 }}>
                      <OrderProductSelect
                        disabled={formState.loading}
                        isBulkShipment={ecomOrder.isBulkShipment}
                        onChange={(ecomOrderProducts) =>
                          setFormState({
                            ...formState,
                            ecomOrderProducts: formState.ecomOrderProducts.reduce(
                              (r, c) => {
                                const found = ecomOrderProducts.find(
                                  (p) =>
                                    p.pkEcomOrderProduct ===
                                    c.pkEcomOrderProduct
                                );
                                return found
                                  ? [
                                      ...r,
                                      {
                                        ...c,
                                        ...found
                                      }
                                    ]
                                  : [...r, c];
                              },
                              []
                            )
                          })
                        }
                        productData={pendingProducts}
                        selectedProducts={formState.ecomOrderProducts}
                      />
                    </AppStack>
                    <Divider />
                    <AppFlexbox style={{ padding: 16 }}>
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        Refunded products will be separated from the order and
                        excluded from vendor order fulfillment.
                      </AppText>
                    </AppFlexbox>
                  </>
                ) : (
                  <AppFlexbox style={{ padding: 16 }}>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      There are no pending products left to cancel.
                    </AppText>
                  </AppFlexbox>
                )}
              </AppStack>
            </AppStack>
          </AppCard>

          {unavailableProducts.length > 0 && (
            <OrderProductsInfoCard
              description="These products are already in the processing stage and are non-refundable. To request reimbursement, please contact the vendor directly."
              hideStatus
              productData={unavailableProducts}
              showVendorName
              title="Processed products"
            />
          )}
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          {isVendorView ? (
            <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
              <OrderCancelProductSummary
                isFullyCancelled={
                  hasRefundedAllItems || pendingProducts.length === 0
                }
                isVendorView
                selectedItemCount={selectedItemCount}
                selectedItemsRefundValue={selectedItemsRefundValue}
                selectedItemsStoreProfit={selectedItemsStoreProfit}
                selectedItemsTotalUnitCost={selectedItemsTotalUnitCost}
                title="Cancelled products"
              />
            </AppCard>
          ) : (
            <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
              <AppStack style={{ gap: 0 }}>
                <AppStack style={{ flex: 1, padding: 16, gap: 16 }}>
                  <AppStack style={{ flex: 1, gap: 16 }}>
                    <AppFlexbox
                      style={{
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <AppText style={{ fontSize: 16, fontWeight: 500 }}>
                        Summary
                      </AppText>

                      {!authState.userData?.isAdmin && isEscrowPayment ? (
                        <Tooltip
                          label={
                            <AppStack style={{ gap: 8 }}>
                              <AppText style={{ fontSize: 14 }}>
                                You cannot unlock funds or refund processing
                                fees for this order.
                              </AppText>
                              <AppText style={{ fontSize: 14 }}>
                                If you wish to pursue this further, please
                                contact support for assistance, as this will
                                result in a loss of funds.
                              </AppText>
                            </AppStack>
                          }
                          multiline
                          w={250}
                          withArrow
                        >
                          <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                            <AlertCircle color="#eca70a" size={18} />
                            <AppText style={{ fontSize: 14, color: '#eca70a' }}>
                              Escrow
                            </AppText>
                          </AppFlexbox>
                        </Tooltip>
                      ) : (
                        <Anchor
                          component={Link}
                          style={{ fontSize: 14, color: 'dodgerblue' }}
                          target="_blank"
                          to={
                            authState.userData.isAdmin
                              ? `https://dashboard.stripe.com/connect/accounts/${accountPayee}/payments/${firstPayment.sourceTransaction}`
                              : `https://dashboard.stripe.com/payments/${firstPayment.sourceTransaction}`
                          }
                        >
                          View payment
                        </Anchor>
                      )}
                    </AppFlexbox>
                  </AppStack>

                  <OrderRefundPaymentSummary
                    amountPaidInCents={amountPaid}
                    unlockRefundAmount={formState.unlockRefundAmount}
                    {...refundValues}
                  />
                </AppStack>

                <Divider />
                <AppFlexbox style={{ padding: 16 }}>
                  <Tooltip
                    disabled={!authState.userData?.isAdmin && !isEscrowPayment}
                    label="Funds cannot be unlocked for escrow payments."
                    multiline
                    w={250}
                    withArrow
                  >
                    <AppFlexbox>
                      <AppCheckbox
                        checked={formState.unlockRefundAmount}
                        description="Lift restrictions on available refund amounts by covering product costs and service fees, even at a loss."
                        disabled={
                          !authState.userData?.isAdmin && isEscrowPayment
                        }
                        label="Unlock refund funds"
                        onChange={() =>
                          setFormState({
                            ...formState,
                            unlockRefundAmount: !formState.unlockRefundAmount
                          })
                        }
                      />
                    </AppFlexbox>
                  </Tooltip>
                </AppFlexbox>
              </AppStack>
            </AppCard>
          )}

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ flex: 1, padding: 16, gap: 24 }}>
                <AppStack style={{ flex: 1, gap: 16 }}>
                  <AppFlexbox
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                      Refund amount
                    </AppText>
                  </AppFlexbox>
                  <AppStack style={{ gap: 16 }}>
                    <AppStack style={{ gap: 3 }}>
                      <AppText style={{ fontSize: 14 }}>Manual amount</AppText>
                      <CustomNumericFormat
                        disabled={
                          formState.loading || availableRefundAmount <= 0
                        }
                        onBlur={(e) => {
                          if (formState.refundAmount > availableRefundAmount) {
                            setFormState({
                              ...formState,
                              refundAmount: availableRefundAmount
                            });
                          }
                          else if (
                            !formState.refundAmount ||
                            formState.refundAmount < 0
                          ) {
                            setFormState({
                              ...formState,
                              refundAmount: 0
                            });
                          }
                        }}
                        onValueChange={(values) => {
                          if (
                            values.floatValue * 100 !==
                            formState.refundAmount
                          ) {
                            setFormState({
                              ...formState,
                              refundAmount: Math.round(values.floatValue * 100)
                            });
                          }
                        }}
                        style={{ flex: 1 }}
                        value={formState.refundAmount / 100}
                      />
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        {currencyFormat(availableRefundAmount / 100)} available
                        for refund
                      </AppText>
                    </AppStack>
                  </AppStack>
                </AppStack>
              </AppStack>

              <Divider />
              <AppStack style={{ flex: 1, padding: 16, gap: 16 }}>
                <Button
                  color="red"
                  disabled={!formState.refundAmount || formState.loading}
                  loading={formState.loading}
                  onClick={() => {
                    setFormState({
                      ...formState,
                      showConfirm: true
                    });
                  }}
                  radius={8}
                  type="submit"
                  variant="filled"
                >
                  Refund{' '}
                  {currencyFormat(
                    (formState.refundAmount > availableRefundAmount
                      ? availableRefundAmount
                      : formState.refundAmount) / 100
                  )}
                </Button>
                {!isVendorView && (
                  <Checkbox
                    checked={!formState.skipNotification}
                    label="Send the customer a refund receipt"
                    onChange={() =>
                      setFormState({
                        ...formState,
                        skipNotification: !formState.skipNotification
                      })
                    }
                  />
                )}
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>

      <AppFlexbox style={{ justifyContent: 'center', padding: 16 }}>
        <AppText style={{ fontSize: 14 }}>
          Learn about{' '}
          <Anchor
            onClick={() => onOpenModal('helpModal')}
            style={{ color: 'dodgerblue', fontSize: 14 }}
          >
            order refunds
          </Anchor>
          .
        </AppText>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={`${baseUrl}/${pkEcomOrder}`}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <OrderProductsInfoCard loading />
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard fulfillmentPreview loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

OrderRefundView.propTypes = {
  ecomOrder: PropTypes.object,
  isVendorView: PropTypes.bool,
  loading: PropTypes.bool
};

export default OrderRefundView;
