import React, { useContext, useEffect, useRef } from 'react';
import { Link, Navigate, Route, Routes, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Divider } from '@mantine/core';
import { Receipt } from 'tabler-icons-react';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { triggerNotification } from '../../../helpers/notification';
import StoreOrderDetailsView from './StoreOrderDetailsView';
import StoreOrderContributors from './StoreOrderContributors';
import OrderRefundView from './OrderRefundView';
import StoreOrderPurchaseViews from './StoreOrderPurchaseViews';
import StoreOrderTransferViews from './StoreOrderTransferViews';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { getStoreBackPath } from '../../../helpers/storeHelper';
import { useModalState } from '../../../helpers/hooks';
import { VIEW_ACTIONS_ENUM } from '../../../config/constants';
import ExportCsvModal from '../bulkEdit/ExportCsvModal';
import { orderExportConfig } from './orderExportConfig';

const StoreOrderViews = ({ baseUrl, isBulkOrders }) => {
  const hasFetched = useRef(false);
  const { pkEcomOrder } = useParams();
  const { state: authState, selectEcomStore } = useContext(AuthContext);
  const { state, fetchStoreOrder, fetchPaginatedStoreOrders } = useContext(
    StoreContext
  );
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const loading =
    !hasFetched.current ||
    state.ecomOrder.loading ||
    (state.ecomOrder.value &&
      pkEcomOrder !== state.ecomOrder.value?.pkEcomOrder.toString());
  const ecomOrder =
    pkEcomOrder === state.ecomOrder.value?.pkEcomOrder.toString()
      ? state.ecomOrder.value
      : null;

  const showWrongStore =
    !loading &&
    ecomOrder &&
    ecomOrder.ecomStore.pkEcomStore.toString() !==
      authState.pkEcomStore?.toString();
  const showOrderNotFound = !loading && !ecomOrder;

  const defaultBackLocation = isBulkOrders
    ? '/merchant/bulk-orders'
    : '/merchant/orders';
  const backLocation = getStoreBackPath(defaultBackLocation);

  useEffect(() => {
    if (authState.pkEcomStore && pkEcomOrder) {
      if (
        isBulkOrders ||
        pkEcomOrder !== state.ecomOrder.value?.pkEcomOrder.toString()
      ) {
        fetchStoreOrder(authState.pkEcomStore, { pkEcomOrder }, null, (e) => {
          triggerNotification(e);
        });
      }

      hasFetched.current = true;
    }
  }, [authState.pkEcomStore, pkEcomOrder]);

  const onShowExportConfirm = () => {
    onOpenModal(VIEW_ACTIONS_ENUM.EXPORT);
  };

  return showWrongStore || showOrderNotFound ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 600,
        paddingTop: 16,
        gap: 16,
        alignItems: 'center'
      }}
    >
      <AppCard
        radius="md"
        shadow="lg"
        style={{ padding: 20, maxWidth: 400, marginTop: 40 }}
        withBorder
      >
        <AppStack style={{ flex: 1, gap: 8 }}>
          <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
            <Receipt color="#000" size={150} />
          </AppFlexbox>
          <AppStack style={{ gap: 20 }}>
            {showWrongStore ? (
              <>
                <AppStack style={{ gap: 8, textAlign: 'center' }}>
                  <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                    Order #{pkEcomOrder}
                  </AppText>
                  <AppText style={{ fontSize: 16, color: '#666' }}>
                    This order belongs to <b>{ecomOrder.ecomStore.name}</b>.
                    Please switch to the correct store to view this order.
                  </AppText>
                </AppStack>

                <Button
                  color="dark"
                  onClick={() => {
                    selectEcomStore(ecomOrder.ecomStore.pkEcomStore);
                  }}
                  variant="outline"
                >
                  Switch stores
                </Button>

                <Divider label="OR" labelPosition="center" />
              </>
            ) : (
              <AppStack style={{ gap: 8, textAlign: 'center' }}>
                <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                  Order #{pkEcomOrder} not found
                </AppText>
                <AppText style={{ fontSize: 16, color: '#666' }}>
                  The order you are looking for does not exist or you do not
                  have permission to view it.
                </AppText>
              </AppStack>
            )}
            <Button
              color="dark"
              component={Link}
              to={backLocation}
              variant="filled"
            >
              {backLocation === defaultBackLocation ? 'Back to orders' : 'Back'}
            </Button>
          </AppStack>
        </AppStack>
      </AppCard>
    </AppStack>
  ) : (
    <>
      <Routes>
        {isBulkOrders && (
          <>
            <Route
              element={
                <StoreOrderContributors
                  ecomOrder={ecomOrder}
                  loading={loading}
                />
              }
              path="/contributors/*"
            />
            <Route
              element={
                <StoreOrderPurchaseViews
                  ecomOrder={ecomOrder}
                  loading={loading}
                />
              }
              path="/purchase/*"
            />
            <Route
              element={
                <StoreOrderTransferViews
                  ecomOrder={ecomOrder}
                  loading={loading}
                />
              }
              path="/transfer/*"
            />
          </>
        )}
        <Route
          element={<OrderRefundView ecomOrder={ecomOrder} loading={loading} />}
          path="/refund/*"
        />
        <Route
          element={
            <StoreOrderDetailsView
              ecomOrder={ecomOrder}
              isBulkOrders={isBulkOrders}
              loading={loading}
              onExportOrder={onShowExportConfirm}
            />
          }
          path="/*"
        />
        <Route
          element={<Navigate replace to={`/${baseUrl}/${pkEcomOrder}`} />}
          path="*"
        />
      </Routes>
      <ExportCsvModal
        confirmMessage={
          <AppStack style={{ gap: 8 }}>
            <AppText style={{ fontSize: 14 }}>
              Exporting this order will include all the details of the order in
              a CSV file.
            </AppText>

            <AppText style={{ fontSize: 14, fontWeight: '500' }}>
              Are you sure you want to proceed?
            </AppText>
          </AppStack>
        }
        descriptor="order"
        exportConfig={orderExportConfig}
        isOpen={
          modalState.isOpen && modalState.action === VIEW_ACTIONS_ENUM.EXPORT
        }
        itemType="order"
        itemTypePlural="orders"
        onClose={onCloseModal}
        onExport={({ items }, downloadCsvCallback, onErrorCallback) => {
          fetchPaginatedStoreOrders(
            authState.pkEcomStore,
            {
              pkEcomOrders: [ecomOrder.pkEcomOrder],
              skipStateUpdate: true,
              isBulkShipment: ecomOrder.isBulkShipment
            },
            (result) => {
              const csvData = orderExportConfig.getCsvData(result.data, true);
              downloadCsvCallback(csvData);
            },
            onErrorCallback
          );
        }}
        selectedItems={[ecomOrder]}
      />
    </>
  );
};

StoreOrderViews.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  isBulkOrders: PropTypes.bool
};

export default StoreOrderViews;
