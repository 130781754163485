import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, TextInput } from '@mantine/core';
import AppStack from './AppStack';
import ResponsiveModal from './ResponsiveModal';

const CustomInputModal = ({
  control,
  title,
  clearable,
  inputProps,
  parser,
  value,
  onChange
}) => {
  const [formState, setFormState] = useState({
    isOpen: false,
    value: ''
  });

  useEffect(() => {
    if (formState.isOpen) {
      setFormState({
        ...formState,
        value: value ?? ''
      });
    }
  }, [formState.isOpen]);

  return (
    <>
      <AppStack
        onClick={() =>
          setFormState({
            ...formState,
            isOpen: true
          })
        }
      >
        {control}
      </AppStack>
      <ResponsiveModal
        formSectionProps={{
          submitTitle: 'Done',
          onSubmit: () => {
            onChange(formState.value);
            setFormState({
              ...formState,
              isOpen: false
            });
          },
          ...(clearable && formState.value
            ? {
                onCancel: () => {
                  onChange('');
                  setFormState({
                    ...formState,
                    value: '',
                    isOpen: false
                  });
                },
                cancelTitle: 'Remove',
                cancelColor: 'red'
              }
            : { isCancelHidden: true })
        }}
        isOpen={formState.isOpen}
        onClose={() =>
          setFormState({
            ...formState,
            isOpen: false
          })
        }
        title={title}
      >
        {inputProps?.type === 'select' ? (
          <Select
            {...inputProps}
            onChange={(v) => {
              if (!inputProps?.required || !!v) {
                setFormState({
                  ...formState,
                  value: parser ? parser(v) : v
                });
              }
            }}
            value={formState.value}
          />
        ) : (
          <TextInput
            {...inputProps}
            onChange={(e) =>
              setFormState({
                ...formState,
                value: parser
                  ? parser(e.currentTarget.value)
                  : e.currentTarget.value
              })
            }
            value={formState.value}
          />
        )}
      </ResponsiveModal>
    </>
  );
};

CustomInputModal.propTypes = {
  clearable: PropTypes.bool,
  control: PropTypes.node,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  parser: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string
};

export default CustomInputModal;
