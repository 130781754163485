import {
  DEFAULT_REPORT_CONFIGS,
  ECOM_REPORT_ENUM,
  REPORT_CATEGORY_ENUM,
  REPORT_HEADER_ADMIN_ENUM,
  REPORT_HEADER_ORDER_ENUM,
  REPORT_HEADER_PRODUCT_ENUM,
  REPORT_TYPE_ENUM
} from './reportsConfig';
import { DEFAULT_DATE_OPTIONS_ENUM } from './analyticsConfig';
import AnalyticsPaymentSummaryList from './AnalyticsPaymentSummaryList';
import { processProductCounts } from '../../../helpers/reportHelper';

const analytics = [
  {
    title: 'Total sales',
    header: REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
    reportUuid: ECOM_REPORT_ENUM.SALES_OVER_TIME,
    type: 'line'
  },
  {
    title: 'Total orders',
    header: REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
    reportUuid: ECOM_REPORT_ENUM.ORDERS_OVER_TIME,
    type: 'line'
  },
  {
    title: 'Average order value',
    header: REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,
    reportUuid: ECOM_REPORT_ENUM.AVERAGE_ORDER_VALUE,
    type: 'line'
  },
  {
    title: 'Amount refunded',
    header: REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
    reportUuid: ECOM_REPORT_ENUM.REFUNDS,
    type: 'line'
  },
  {
    title: 'Store profit',
    header: REPORT_HEADER_ORDER_ENUM.NET_PROFIT,
    reportUuid: ECOM_REPORT_ENUM.NET_PROFIT,
    type: 'line'
  },
  {
    title: 'SportsHeadz profit',
    header: REPORT_HEADER_ADMIN_ENUM.SPORTSHEADZ_PROFIT,
    reportUuid: null,
    type: 'line'
  },
  {
    title: 'Top selling products',
    header: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_NAME,
    reportUuid: null,
    type: 'bar',
    dataKey: 'name',
    getOverviewData: (chartData, dataLabel, compareDataLabel) => ({
      total: chartData.reduce((r, c) => r + c[dataLabel], 0),
      compareTotal: chartData.reduce((r, c) => r + c[compareDataLabel], 0)
    }),
    getData: (data, dataLabel, compareDataLabel) =>
      processProductCounts(
        data,
        dataLabel,
        compareDataLabel,
        'soldProducts',
        'productName'
      )
  },
  {
    title: 'Escrow earned',
    header: REPORT_HEADER_ORDER_ENUM.ESCROW_AMOUNT_EARNED,
    reportUuid: null,
    type: 'line'
  },
  {
    title: 'Vendor amount paid',
    header: REPORT_HEADER_ADMIN_ENUM.VENDOR_AMOUNT_PAID,
    reportUuid: null,
    type: 'line'
  }
];

const reports = [
  {
    title: 'Analytics overview',
    uuid: ECOM_REPORT_ENUM.OVERVIEW,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.AVERAGE_ORDER_VALUE,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT,
      REPORT_HEADER_ORDER_ENUM.ESCROW_AMOUNT_EARNED,
      REPORT_HEADER_ADMIN_ENUM.VENDOR_AMOUNT_PAID,
      REPORT_HEADER_ADMIN_ENUM.SPORTSHEADZ_PROFIT
    ],
    groupBy: 'period',
    compareEnabled: true,
    isHidden: true
  },
  {
    title: 'Finance summary',
    uuid: ECOM_REPORT_ENUM.FINANCE_SUMMARY,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.FINANCES,
    groupBy: 'payment_id',
    defaultDateOption: DEFAULT_DATE_OPTIONS_ENUM.MONTH_TO_DATE,
    isSummaryReport: true,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_DISCOUNTED,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TIERED_PRICING_FEES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_COST,
      REPORT_HEADER_ORDER_ENUM.SERVICE_FEES,
      REPORT_HEADER_ORDER_ENUM.TAXES,
      REPORT_HEADER_ORDER_ENUM.SHIPPING_COST,
      REPORT_HEADER_ORDER_ENUM.ORDER_ID,
      REPORT_HEADER_ORDER_ENUM.PURCHASE_DATE,
      REPORT_HEADER_ADMIN_ENUM.STRIPE_PRODUCT_FEES,
      REPORT_HEADER_ADMIN_ENUM.STRIPE_ADJUSTMENT_FEES,
      REPORT_HEADER_ADMIN_ENUM.SPORTSHEADZ_FEES,
      REPORT_HEADER_ADMIN_ENUM.SPORTSHEADZ_PROFIT
    ],
    summaryDisplays: [
      {
        group: 1,
        title: 'Sales',
        data: [
          {
            header: REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
            reportUuid: ECOM_REPORT_ENUM.GROSS_SALES,
            fontWeight: 500
          },
          {
            header: REPORT_HEADER_ORDER_ENUM.AMOUNT_DISCOUNTED,
            label: 'Discounts'
          },
          {
            header: REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
            label: 'Refunds',
            reportUuid: ECOM_REPORT_ENUM.REFUNDS
          },
          {
            header: REPORT_HEADER_ORDER_ENUM.NET_SALES,
            topDivider: true,
            reportUuid: ECOM_REPORT_ENUM.NET_SALES,
            fontWeight: 500
          },
          {
            header: REPORT_HEADER_ORDER_ENUM.TIERED_PRICING_FEES,
            label: 'Tiered pricing fees'
          },
          { header: REPORT_HEADER_ORDER_ENUM.SHIPPING_COST },
          { header: REPORT_HEADER_ORDER_ENUM.TAXES },
          {
            header: REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
            topDivider: true,
            reportUuid: ECOM_REPORT_ENUM.TOTAL_SALES,
            fontWeight: 500
          }
        ]
      },
      {
        group: 1,
        title: 'SportsHeadz profit',
        data: [
          {
            header: REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
            topDivider: true,
            reportUuid: ECOM_REPORT_ENUM.TOTAL_SALES,
            fontWeight: 500
          },
          {
            header: REPORT_HEADER_ORDER_ENUM.NET_PROFIT,
            reportUuid: ECOM_REPORT_ENUM.NET_PROFIT,
            label: 'Store profit',
            isNegative: true
          },
          {
            header: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_COST,
            label: 'Cost of goods sold',
            reportUuid: ECOM_REPORT_ENUM.COST_OF_GOODS,
            isNegative: true
          },
          {
            header: REPORT_HEADER_ADMIN_ENUM.STRIPE_PRODUCT_FEES,
            reportUuid: ECOM_REPORT_ENUM.SERVICE_FEES,
            isNegative: true
          },
          {
            header: REPORT_HEADER_ADMIN_ENUM.STRIPE_ADJUSTMENT_FEES,
            isNegative: true,
            tooltip:
              'Stripe fee for product rounding adjustments and tiered pricing. Plus $0.30 per transaction.'
          },
          {
            header: REPORT_HEADER_ADMIN_ENUM.SPORTSHEADZ_PROFIT,
            topDivider: true,
            reportUuid: ECOM_REPORT_ENUM.NET_PROFIT,
            fontWeight: 500
          }
        ]
      },
      {
        group: 2,
        title: 'Store profit',
        data: [
          {
            header: REPORT_HEADER_ORDER_ENUM.NET_SALES,
            reportUuid: ECOM_REPORT_ENUM.NET_SALES
          },
          {
            header: REPORT_HEADER_ADMIN_ENUM.SPORTSHEADZ_FEES,
            reportUuid: ECOM_REPORT_ENUM.SERVICE_FEES,
            isNegative: true
          },
          {
            header: REPORT_HEADER_ADMIN_ENUM.STRIPE_PRODUCT_FEES,
            reportUuid: ECOM_REPORT_ENUM.SERVICE_FEES,
            isNegative: true
          },
          {
            header: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_COST,
            label: 'Cost of goods sold',
            reportUuid: ECOM_REPORT_ENUM.COST_OF_GOODS,
            isNegative: true
          },
          {
            header: REPORT_HEADER_ORDER_ENUM.NET_PROFIT,
            topDivider: true,
            reportUuid: ECOM_REPORT_ENUM.NET_PROFIT,
            fontWeight: 500
          }
        ]
      },
      {
        group: 2,
        title: 'Payments',
        ComponentBottom: AnalyticsPaymentSummaryList,
        data: [
          {
            header: REPORT_HEADER_ORDER_ENUM.AMOUNT_PAID,
            bottomDivider: true,
            reportUuid: ECOM_REPORT_ENUM.AMOUNT_PAID,
            fontWeight: 500
          }
        ]
      }
    ]
  },
  ...DEFAULT_REPORT_CONFIGS,
  {
    title: 'Sales by store',
    uuid: ECOM_REPORT_ENUM.SALES_BY_STORE,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_ORDER_ENUM.STORE,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT
    ]
  },
  {
    title: 'Sales by vendor',
    uuid: ECOM_REPORT_ENUM.SALES_BY_VENDOR,
    fkReportType: REPORT_TYPE_ENUM.SALES,
    fkReportCategory: REPORT_CATEGORY_ENUM.SALES,
    groupBy: REPORT_HEADER_PRODUCT_ENUM.PRODUCT_VENDOR,
    compareEnabled: false,
    defaultHeaders: [
      REPORT_HEADER_ORDER_ENUM.ORDER_COUNT,
      REPORT_HEADER_ORDER_ENUM.GROSS_SALES,
      REPORT_HEADER_ORDER_ENUM.AMOUNT_REFUNDED,
      REPORT_HEADER_ORDER_ENUM.NET_SALES,
      REPORT_HEADER_ORDER_ENUM.TOTAL_SALES,
      REPORT_HEADER_ORDER_ENUM.NET_PROFIT,
      REPORT_HEADER_PRODUCT_ENUM.PRODUCT_COST
    ]
  }
];

const adminAnalyticsAndReportsConfig = {
  analytics,
  reports
};

export { adminAnalyticsAndReportsConfig };
