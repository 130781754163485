import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AlertCircle, ArrowLeft, CirclePlus, Lock } from 'tabler-icons-react';
import { Button, Divider, NumberFormatter, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useMediaQueryIndex, useModalState } from '../../../helpers/hooks';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import ActionableIcon from '../../common/ActionableIcon';
import { formatUtcDate, singularPluralFormat } from '../../../helpers/format';
import OrderCustomerInfoCard from './OrderCustomerInfoCard';
import { translateStoreOrderData } from '../../../helpers/storeHelper';
import AppCard from '../../common/AppCard';
import StoreOrderContributorListItem from './StoreOrderContributorListItem';
import * as classes from '../../../styles/nestedStyles.module.css';
import StoreOrderPurchaseProductModal from './StoreOrderPurchaseProductModal';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreContext } from '../../../providers/StoreContextProvider';
import { usePrompt } from '../../../helpers/prompt';
import { useCartController } from '../../../helpers/webstoreCheckoutHelper';
import WsCheckoutCartProductCard from '../webstore/sections/checkoutContent/WsCheckoutCartProductCard';
import { triggerNotification } from '../../../helpers/notification';
import StoreOrderGroupProductRequirementsCard from './StoreOrderGroupProductRequirementsCard';

const StoreOrderPurchaseCreateView = ({ loading, ecomOrder }) => {
  const hasFetched = useRef(false);
  const { pkEcomOrder } = useParams();
  const { state: authState } = useContext(AuthContext);
  const { fetchEcomOrderPurchaseProducts } = useContext(StoreContext);
  const mediaQueries = useMediaQueryIndex();
  const {
    isTabletOrSmaller,
    isLargeMobileOrSmaller,
    isDesktopOrSmaller
  } = mediaQueries;
  const { groupedEcomOrderProducts } = translateStoreOrderData(ecomOrder);
  const {
    ecomStoreCart,
    resetCart,
    fetchStoreCart,
    removeStoreProducts,
    updateStoreCartProducts,
    checkoutStoreCart,
    getGuestCartStorage
  } = useCartController({ isAdminCheckout: true, pkEcomOrder });
  const { state: modalState, onOpenModal, onCloseModal } = useModalState();
  const [checkoutState, setCheckoutState] = useState({
    email: '',
    fkEcomStoreRosterPlayer: null,
    loading: false,
    loadingCheckout: false
  });
  const isViewLoading = !hasFetched.current || loading || !ecomOrder;
  const checkoutItemCount =
    ecomStoreCart?.ecomStoreCartProducts.reduce((r, c) => r + c.count, 0) || 0;

  usePrompt(
    'Are you sure you want to leave this page? You have unsaved changes.',
    ecomStoreCart && ecomStoreCart.ecomStoreCartProducts.length > 0
  );

  useEffect(() => {
    if (authState.pkEcomStore) {
      const cart = getGuestCartStorage(authState.pkEcomStore);
      if (pkEcomOrder?.toString() !== cart.pkEcomOrder?.toString()) {
        resetCart(authState.pkEcomStore);
      }

      fetchStoreCart(authState.pkEcomStore, null, () => {
        hasFetched.current = true;
      });
    }
  }, [authState.pkEcomStore]);

  useEffect(() => {
    if (ecomOrder) {
      const uuids = ecomOrder.ecomOrderProductBulk
        .filter((b) => !b.ecomOrderProductBulkParent)
        .map(
          (b) =>
            b.ecomOrderProduct.ecomStoreProductVariant.ecomStoreProduct.uuid
        )
        .reduce((r, c) => {
          if (!r.includes(c)) {
            r.push(c);
          }
          return r;
        }, []);

      if (uuids.length > 0) {
        fetchEcomOrderPurchaseProducts(
          authState.pkEcomStore,
          { uuids },
          null,
          (e) => {}
        );
      }
    }
  }, [ecomOrder]);

  const onRemoveCartProducts = (ecomStoreCartProducts = []) => {
    setCheckoutState({
      ...checkoutState,
      loading: true
    });
    removeStoreProducts(
      authState.pkEcomStore,
      ecomStoreCartProducts,
      () => {
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      }
    );
  };

  const onUpdateProducts = (ecomStoreCartProducts) => {
    setCheckoutState({
      ...checkoutState,
      loading: true
    });
    updateStoreCartProducts(
      authState.pkEcomStore,
      { ecomStoreCartProducts },
      () => {
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      }
    );
  };

  const onSubmitCheckout = () => {
    setCheckoutState({
      ...checkoutState,
      loadingCheckout: true
    });
    checkoutStoreCart(
      authState.pkEcomStore,
      {
        email: checkoutState.email,
        fkEcomStoreRosterPlayer: checkoutState.fkEcomStoreRosterPlayer?.toString(),
        redirectPath: `/merchant/group-orders/${pkEcomOrder}/purchase`
      },
      ({ sessionUrl }) => {
        window.location.href = sessionUrl;
      },
      (error) => {
        triggerNotification(error);
        setCheckoutState({
          ...checkoutState,
          loading: false
        });
      }
    );
  };

  return !isViewLoading ? (
    <AppStack
      p={{ base: 8, sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <StoreOrderPurchaseProductModal
        isOpen={modalState.isOpen && modalState.action === 'add-product'}
        onClose={onCloseModal}
        pkEcomStoreCartProduct={modalState.item?.pkEcomStoreCartProduct}
      />

      <AppFlexbox
        style={{
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 8
        }}
      >
        <AppFlexbox style={{ gap: 8 }}>
          <ActionableIcon
            color="dark"
            component={Link}
            radius="md"
            style={{ marginTop: 5 }}
            to={`/merchant/group-orders/${ecomOrder.pkEcomOrder}`}
            variant="subtle"
          >
            <ArrowLeft />
          </ActionableIcon>
          <AppStack style={{ gap: 0 }}>
            <AppFlexbox style={{ gap: 8, alignItems: 'center' }}>
              <AppText
                style={{
                  flex: 1,
                  fontSize: 24,
                  fontWeight: 700,
                  wordBreak: 'break-word'
                }}
              >
                Order #{ecomOrder.pkEcomOrder}
              </AppText>
            </AppFlexbox>

            <AppText
              style={{
                fontSize: 12,
                lineHeight: '16px',
                color: '#666'
              }}
            >
              Placed{' '}
              {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format(
                'MMMM D, YYYY'
              )}{' '}
              at {dayjs(formatUtcDate(ecomOrder.purchaseDate)).format('h:mm A')}
            </AppText>
          </AppStack>
        </AppFlexbox>

        <AppFlexbox style={{ alignItems: 'center', gap: 8, marginTop: 5 }} />
      </AppFlexbox>

      <AppCard
        radius={isTabletOrSmaller ? 0 : 'md'}
        shadow="xs"
        style={{ padding: 8 }}
        withBorder
      >
        <AppFlexbox style={{ gap: 8 }}>
          <AppStack>
            <AlertCircle color="#eca70a" style={{ size: 16 }} />
          </AppStack>

          <AppText style={{ fontSize: 14 }}>
            Purchase group order products directly from the vendor, covering any
            remaining balance to meet the minimum order requirement.
          </AppText>
        </AppFlexbox>
      </AppCard>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          {ecomStoreCart?.checkoutCart.lineItems.map((lineItem) => {
            const product = ecomStoreCart.ecomStoreCartProducts.find(
              (p) => p.pkEcomStoreCartProduct === lineItem.entityId
            );
            return (
              <WsCheckoutCartProductCard
                key={lineItem.entityId}
                ecomStoreCartProduct={product}
                lineItem={lineItem}
                mediaQueries={mediaQueries}
                onCustomize={() => {
                  onOpenModal('add-product', product);
                }}
                onRemove={onRemoveCartProducts}
                onUpdate={onUpdateProducts}
                productUrl={`/merchant/products/${product.ecomStoreProductVariant.ecomStoreProduct.uuid}`}
                submitting={
                  checkoutState.loading || checkoutState.loadingCheckout
                }
              />
            );
          })}
          {!checkoutState.loadingCheckout && (
            <AppStack
              className={classes['hover-action-card']}
              onClick={() => onOpenModal('add-product')}
              style={{
                gap: 8,
                border: 'dashed 1px dodgerblue',
                padding: 24,
                borderRadius: 8
              }}
            >
              <AppFlexbox
                style={{
                  gap: 8,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CirclePlus color="dodgerblue" size={20} />
                <AppText style={{ fontSize: 14, color: 'dodgerblue' }}>
                  {ecomStoreCart &&
                  ecomStoreCart.ecomStoreCartProducts.length > 0
                    ? 'Add another variant'
                    : 'Add product'}
                </AppText>
              </AppFlexbox>
            </AppStack>
          )}
        </AppStack>

        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          {groupedEcomOrderProducts[0] && (
            <StoreOrderGroupProductRequirementsCard
              checkoutItemCount={checkoutItemCount}
              productData={groupedEcomOrderProducts[0]}
            />
          )}

          <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
            <AppStack style={{ gap: 0 }}>
              <AppStack style={{ padding: 16, gap: 8 }}>
                <AppFlexbox style={{ gap: 8 }}>
                  <AppStack>
                    <AlertCircle color="#eca70a" />
                  </AppStack>
                  <AppStack style={{ gap: 8 }}>
                    <AppText style={{ fontSize: 14 }}>
                      The product pricing includes service fees but excludes the
                      public markup you’ve configured for the store.
                    </AppText>
                    <AppText
                      style={{
                        fontSize: 14,
                        color: '#C40000',
                        fontWeight: 500
                      }}
                    >
                      This purchase will not generate store profit.
                    </AppText>
                  </AppStack>
                </AppFlexbox>
              </AppStack>
              <Divider />

              <AppStack style={{ padding: 16, gap: 8 }}>
                <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                  Order Summary
                </AppText>
                <AppFlexbox style={{ justifyContent: 'space-between' }}>
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 16 }}>Subtotal</AppText>
                    <AppText style={{ fontSize: 14, color: '#666' }}>
                      {singularPluralFormat(checkoutItemCount, 'item', 'items')}
                    </AppText>
                  </AppStack>
                  <AppText style={{ fontSize: 16 }}>
                    <NumberFormatter
                      decimalScale={2}
                      displayType="text"
                      fixedDecimalScale
                      prefix="$"
                      thousandSeparator
                      value={
                        (ecomStoreCart?.checkoutCart.subtotalInCents ?? 0) / 100
                      }
                    />
                  </AppText>
                </AppFlexbox>

                <AppFlexbox style={{ justifyContent: 'space-between' }}>
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 16 }}>Platform fee</AppText>
                  </AppStack>
                  <NumberFormatter
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    thousandSeparator
                    value={
                      (ecomStoreCart?.checkoutCart.tieredPricingFeeInCents ??
                        0) / 100
                    }
                  />
                </AppFlexbox>
              </AppStack>
              <Divider />
              <AppStack style={{ padding: 16, gap: 16 }}>
                <AppFlexbox style={{ justifyContent: 'space-between' }}>
                  <AppStack style={{ gap: 0 }}>
                    <AppText style={{ fontSize: 18, fontWeight: 500 }}>
                      Total
                    </AppText>
                  </AppStack>
                  <NumberFormatter
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="$"
                    style={{ fontSize: 18, fontWeight: 500 }}
                    suffix={
                      ecomStoreCart?.checkoutCart.currency
                        ? ` ${ecomStoreCart.checkoutCart.currency.toUpperCase()}`
                        : ''
                    }
                    thousandSeparator
                    value={
                      (ecomStoreCart?.checkoutCart.totalInCents ?? 0) / 100
                    }
                  />
                </AppFlexbox>
                <Button
                  color="blue"
                  disabled={
                    checkoutState.loading ||
                    loading ||
                    !ecomStoreCart ||
                    ecomStoreCart.checkoutCart.lineItems.length === 0
                  }
                  leftSection={<Lock />}
                  loading={checkoutState.loadingCheckout}
                  onClick={onSubmitCheckout}
                  size="lg"
                  variant="filled"
                >
                  Continue to Checkout
                </Button>
              </AppStack>
            </AppStack>
          </AppCard>
        </AppStack>
      </AppFlexbox>
    </AppStack>
  ) : (
    <AppStack
      p={{ base: '8px 0px', sm: '16px 16px 80px 16px' }}
      style={{
        flex: 1,
        margin: 'auto',
        width: '100%',
        maxWidth: 1050,
        paddingTop: 16,
        gap: 16
      }}
    >
      <AppFlexbox style={{ gap: 8 }}>
        <ActionableIcon
          color="dark"
          component={Link}
          radius="md"
          style={{ marginTop: 5 }}
          to={`/merchant/group-orders/${pkEcomOrder}`}
          variant="subtle"
        >
          <ArrowLeft />
        </ActionableIcon>
        <AppStack style={{ gap: 5 }}>
          <Skeleton height={36} width={200} />
          <Skeleton height={14} width={150} />
        </AppStack>
      </AppFlexbox>

      <AppFlexbox
        style={{ flexDirection: isDesktopOrSmaller ? 'column-reverse' : 'row' }}
      >
        <AppStack style={{ flex: 1 }}>
          <AppStack style={{ gap: 16 }}>
            <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
              <AppStack style={{ gap: 0 }}>
                <AppStack style={{ padding: 16, gap: 8 }}>
                  <AppFlexbox
                    style={{
                      gap: 8,
                      alignItems: 'center'
                    }}
                  >
                    <Skeleton height={16} width="25%" />
                  </AppFlexbox>

                  <AppFlexbox
                    style={{
                      gap: 0,
                      alignItems: 'end',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Skeleton
                      height={36}
                      width={isLargeMobileOrSmaller ? '100%' : 200}
                    />

                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      {!isLargeMobileOrSmaller && (
                        <Skeleton height={14} width={80} />
                      )}

                      <Skeleton
                        height={36}
                        width={isLargeMobileOrSmaller ? '100%' : 200}
                      />
                    </AppFlexbox>
                  </AppFlexbox>
                </AppStack>
                <Divider />

                <AppStack style={{ flex: 1, gap: 16 }}>
                  {Array.from(Array(3)).map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={i}>
                      {i > 0 && <Divider />}
                      <StoreOrderContributorListItem showDetails />
                    </React.Fragment>
                  ))}
                </AppStack>
              </AppStack>
            </AppCard>
          </AppStack>
        </AppStack>
        <AppStack
          style={{ flex: 1, maxWidth: isDesktopOrSmaller ? 'unset' : 350 }}
        >
          <OrderCustomerInfoCard loading />
        </AppStack>
      </AppFlexbox>
    </AppStack>
  );
};

StoreOrderPurchaseCreateView.propTypes = {
  ecomOrder: PropTypes.object,
  loading: PropTypes.bool
};

export default StoreOrderPurchaseCreateView;
