import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mantine/core';
import { AlertCircle, CircleCheck } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppCard from '../../common/AppCard';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { singularPluralFormat } from '../../../helpers/format';

const StoreOrderGroupProductRequirementsCard = ({
  productData,
  checkoutItemCount
}) => {
  const minimumAmountRequired =
    checkoutItemCount !== undefined
      ? productData.requireExactMinimum
        ? Math.ceil(
            (productData.quantity + checkoutItemCount) /
              productData.minimumQuantity
          ) * productData.minimumQuantity
        : productData.minimumQuantity
      : productData.minimumAmountRequired;

  const remainingAmount = Math.max(
    0,
    minimumAmountRequired - productData.quantity - (checkoutItemCount || 0)
  );

  const minimumAmountReachedCount = productData.requireExactMinimum
    ? Math.floor(
        (productData.quantity + (checkoutItemCount || 0)) /
          productData.minimumQuantity
      )
    : productData.minimumAmountReachedCount;

  return (
    <AppCard radius="md" shadow="xs" style={{ padding: 0 }} withBorder>
      <AppStack style={{ padding: 0, gap: 0 }}>
        <AppStack style={{ padding: 16, gap: 8 }}>
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>
            {productData.name}
          </AppText>
          <AppFlexbox style={{ justifyContent: 'space-between' }}>
            <AppText style={{ fontSize: 14 }}>Purchased</AppText>
            <AppText style={{ fontSize: 14 }}>
              {productData.quantity} / {minimumAmountRequired}
            </AppText>
          </AppFlexbox>
          {checkoutItemCount !== undefined && (
            <>
              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <AppText style={{ fontSize: 14 }}>Selected</AppText>
                <AppText style={{ fontSize: 14 }}>{checkoutItemCount}</AppText>
              </AppFlexbox>
              <Divider />
            </>
          )}
          <AppFlexbox style={{ justifyContent: 'space-between' }}>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              Amount needed
            </AppText>
            <AppText style={{ fontSize: 14, fontWeight: 500 }}>
              {singularPluralFormat(remainingAmount, 'item', 'items')}
            </AppText>
          </AppFlexbox>
        </AppStack>
        <Divider />
        <AppStack style={{ padding: '8px 16px', gap: 16 }}>
          {productData.requireExactMinimum ? (
            <AppFlexbox style={{ gap: 16 }}>
              <AppStack>
                {minimumAmountReachedCount > 0 ? (
                  <CircleCheck color="#067D62" />
                ) : (
                  <AlertCircle color="#eca70a" />
                )}
              </AppStack>
              <AppStack style={{ gap: 5 }}>
                {minimumAmountReachedCount > 0 && (
                  <AppText style={{ fontSize: 14 }}>
                    Minimum order quantity reached{' '}
                    {singularPluralFormat(
                      minimumAmountReachedCount,
                      'time',
                      'times'
                    )}
                    .
                  </AppText>
                )}

                {remainingAmount > 0 && (
                  <AppText style={{ fontSize: 14 }}>
                    {singularPluralFormat(
                      remainingAmount,
                      'more item',
                      'more items'
                    )}{' '}
                    required to qualify
                    {minimumAmountReachedCount > 0 ? ` again` : ''}.
                  </AppText>
                )}
              </AppStack>
            </AppFlexbox>
          ) : remainingAmount > 0 ? (
            <AppFlexbox style={{ gap: 16 }}>
              <AppStack>
                <AlertCircle color="#eca70a" />
              </AppStack>

              <AppText style={{ fontSize: 14 }}>
                {singularPluralFormat(
                  remainingAmount,
                  'more item',
                  'more items'
                )}{' '}
                required to qualify.
              </AppText>
            </AppFlexbox>
          ) : (
            <AppFlexbox style={{ gap: 16, alignItems: 'center' }}>
              <AppStack>
                <CircleCheck color="#067D62" />
              </AppStack>

              <AppText style={{ fontSize: 14 }}>
                {productData.requireExactMinimum ? 'Required' : 'Minimum'} order
                quantity met
              </AppText>
            </AppFlexbox>
          )}
        </AppStack>
      </AppStack>
    </AppCard>
  );
};

StoreOrderGroupProductRequirementsCard.propTypes = {
  checkoutItemCount: PropTypes.number,
  productData: PropTypes.object
};

export default StoreOrderGroupProductRequirementsCard;
