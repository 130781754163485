import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ECOM_ORDER_STATUS_ENUM } from '../../../config/constants';
import StoreOrderTransferCreateView from './StoreOrderTransferCreateView';

const StoreOrderTransferViews = ({ loading, ecomOrder }) => {
  const navigate = useNavigate();
  const { pkEcomOrder } = useParams();

  const isAccumulating =
    ecomOrder?.fkEcomOrderStatus.toString() ===
    ECOM_ORDER_STATUS_ENUM.ACCUMULATING;

  useEffect(() => {
    if (ecomOrder && !isAccumulating) {
      navigate(`/merchant/group-orders/${pkEcomOrder}`);
    }
  }, [ecomOrder]);

  return <StoreOrderTransferCreateView ecomOrder={ecomOrder} loading={loading} />;
};

StoreOrderTransferViews.propTypes = {
  ecomOrder: PropTypes.object,
  loading: PropTypes.bool
};

export default StoreOrderTransferViews;
