import {
  ECOM_ORDER_PRODUCT_STATUS_ENUM,
  ECOM_PRODUCT_STATUS_ENUM,
  ECOM_SHIPPING_CARRIER_ENUM,
  PAYMENT_TYPE_ENUM
} from '../config/constants';

const generateTrackingLink = (carrier, trackingNumber, customTrackingUrl) => {
  if (carrier === ECOM_SHIPPING_CARRIER_ENUM.OTHER) {
    return customTrackingUrl;
  }

  if (!carrier || !trackingNumber) {
    return null;
  }

  const trackingUrls = {
    [ECOM_SHIPPING_CARRIER_ENUM.CANADA_POST]: `https://www.canadapost-postescanada.ca/track-reperage/en#/details/${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.UPS]: `https://www.ups.com/track?tracknum=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.FEDEX]: `https://www.fedex.com/en-us/tracking.html?tracknumbers=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.PUROLATOR]: `https://www.purolator.com/en/tools/tracking.page?track=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.DHL]: `https://www.dhl.com/global-en/home/tracking.html?trackingId=${trackingNumber}`,
    [ECOM_SHIPPING_CARRIER_ENUM.USPS]: `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`
  };

  return trackingUrls[carrier];
};

const translateVendorOrderProductData = (
  p,
  ecomOrder,
  ecomOrderReturnProducts = []
) => {
  const {
    returnedProducts = [],
    cancelledProducts = []
  } = ecomOrderReturnProducts
    .filter(
      (f) =>
        f.fkEcomOrderProduct ===
        (p.pkEcomOrderProduct || p.ecomOrderProduct?.pkEcomOrderProduct)
    )
    .reduce(
      (r, c) => {
        if (c.isCancelReturn) {
          r.cancelledProducts.push(c);
        }
        else {
          r.returnedProducts.push(c);
        }
        return r;
      },
      { returnedProducts: [], cancelledProducts: [] }
    );

  const ecomOrderShippingProducts = p.ecomOrderShippingProducts.filter(
    (f) => !ecomOrder.isBulkShipment || f.fkEcomOrder === ecomOrder.pkEcomOrder
  );
  const shippedCount = ecomOrderShippingProducts.reduce(
    (r2, c2) => r2 + c2.count,
    0
  );
  const returnedCount = returnedProducts.reduce((r2, c2) => r2 + c2.count, 0);
  const cancelledCount = cancelledProducts.reduce((r2, c2) => r2 + c2.count, 0);
  const amountRemaining =
    p.count - shippedCount - returnedCount - cancelledCount;

  const reimbursedAmount = p.ecomOrderProductPayments
    .filter(
      (f) =>
        f.fkRegFormSubmissionPaymentType.toString() ===
        PAYMENT_TYPE_ENUM.REIMBURSEMENT.toString()
    )
    .reduce((r, c) => r + c.amount, 0);

  return {
    key: p.pkEcomOrderProduct,
    pkEcomOrderProductBulk: p.pkEcomOrderProductBulk,
    pkEcomOrderProduct: p.pkEcomOrderProduct,
    fkEcomOrderProductStatus: p.fkEcomOrderProductStatus,
    to: `/vendor/products/${p.ecomVendorProductVariant.ecomVendorProduct.uuid}/variants/${p.ecomVendorProductVariant.uuid}`,
    previewImage:
      p.ecomVendorProductVariant.previewImage ??
      p.ecomVendorProductVariant.ecomVendorProduct.previewImage,
    name: p.ecomVendorProductVariant.ecomVendorProduct.name,
    sku: p.ecomVendorProductVariant.sku,
    uuid: p.ecomVendorProductVariant.uuid,
    barcode: p.ecomVendorProductVariant.barcode,
    quantity: p.count,
    unitCost: p.price + p.addonPrice,
    totalCost: (p.price + p.addonPrice) * p.count,
    weight: p.ecomVendorProductVariant.weight,
    weightUnit: p.ecomVendorProductVariant.weightUnit,
    amountRemaining,
    ecomOrderShippingProducts,
    shippedCount,
    returnedProducts,
    cancelledProducts,
    returnedCount,
    cancelledCount,
    reimbursedAmount,
    ecomOrderProducts: [],
    addOns: [
      ...p.ecomVendorProductVariant.ecomVendorProductVariantOptions
        .sort((a, b) => a.nameSort - b.nameSort)
        .map((op) => ({
          sort: op.nameSort,
          name: op.name,
          value: op.value
        })),
      ...p.ecomOrderProductInputAnswers
        .map((a) => ({
          sort: a?.sort,
          name: a?.label,
          value: a.value
        }))
        .sort((a, b) => a.sort - b.sort)
    ]
  };
};

const translateVendorOrderData = (ecomOrder) => {
  const ecomOrderReturnProducts =
    ecomOrder?.ecomOrderReturns.reduce(
      (r, c) => [
        ...r,
        ...c.ecomOrderReturnProducts.map((p) => ({
          ...p,
          isCancelReturn: c.isCancelReturn
        }))
      ],
      []
    ) ?? [];

  const productData = ecomOrder
    ? [
        ...ecomOrder.ecomOrderProducts.map((p) =>
          translateVendorOrderProductData(p, ecomOrder, ecomOrderReturnProducts)
        ),
        ...ecomOrder.ecomOrderProductBulk
          .filter(
            (b) =>
              ![
                ECOM_ORDER_PRODUCT_STATUS_ENUM.TRANSFERRED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.CANCELLED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.REFUNDED
              ].includes(b.fkEcomOrderProductStatus.toString())
          )
          .map((b) =>
            translateVendorOrderProductData(
              { ...b.ecomOrderProduct, ...b },
              ecomOrder,
              ecomOrderReturnProducts
            )
          )
      ]
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .reduce((r, c) => {
          const existing = r.find(
            (p) =>
              p.uuid === c.uuid &&
              p.addOns.length === c.addOns.length &&
              p.addOns.every(
                (ao, i) =>
                  ao.name === c.addOns[i].name && ao.value === c.addOns[i].value
              )
          );
          if (existing) {
            existing.quantity += c.quantity;
            existing.totalCost += c.totalCost;
            existing.amountRemaining += c.amountRemaining;
            existing.ecomOrderShippingProducts.push(
              ...c.ecomOrderShippingProducts
            );
            existing.shippedCount += c.shippedCount;
            existing.ecomOrderProducts.push(c);
          }
          else {
            r.push(c);
          }
          return r;
        }, [])
    : [];

  return {
    ecomOrderProducts: productData.sort((a, b) => a.name.localeCompare(b.name))
  };
};

const calculateVendorOrderRefundValues = (
  ecomOrder,
  productData,
  selectedEcomOrderProducts
) => {
  // Summarize selected products
  const selectedProductsSummary = ecomOrder
    ? selectedEcomOrderProducts.reduce(
        (summary, selectedProduct) => {
          const product = productData.find(
            (p) => p.pkEcomOrderProduct === selectedProduct.pkEcomOrderProduct
          );

          const count = Math.min(
            selectedProduct.count || 0,
            product.amountRemaining
          );
          return {
            unitCost: summary.unitCost + count * product.unitCost,
            itemCount: summary.itemCount + count,
            refundValue: summary.unitCost + count * product.refundValue
          };
        },
        {
          unitCost: 0,
          itemCount: 0,
          refundValue: 0
        }
      )
    : {};

  // Check if all items are refunded
  const totalItemCount = productData.reduce((acc, p) => acc + p.quantity, 0);
  const hasRefundedAllItems =
    selectedProductsSummary.itemCount >= totalItemCount;

  const totals = (ecomOrder
    ? [
        ...ecomOrder.ecomOrderProducts.map((p) => p.ecomOrderProductPayments),
        ...ecomOrder.ecomOrderProductBulk
          .filter(
            (b) =>
              ![
                ECOM_ORDER_PRODUCT_STATUS_ENUM.TRANSFERRED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.CANCELLED,
                ECOM_ORDER_PRODUCT_STATUS_ENUM.REFUNDED
              ].includes(b.fkEcomOrderProductStatus.toString())
          )
          .map((p) => p.ecomOrderProduct.ecomOrderProductPayments)
      ]
    : []
  )
    .flat()
    .reduce(
      (r, { fkRegFormSubmissionPaymentType, amount, payoutAmount }) => {
        const type = fkRegFormSubmissionPaymentType.toString();
        return {
          ...r,

          payoutRefundAmount:
            r.payoutRefundAmount +
            (type === PAYMENT_TYPE_ENUM.CREDIT ? payoutAmount : 0),
          paid: r.paid + (type !== PAYMENT_TYPE_ENUM.CREDIT ? amount : 0),
          payoutAmount:
            r.payoutAmount +
            (type !== PAYMENT_TYPE_ENUM.CREDIT ? payoutAmount : 0)
        };
      },
      { paid: 0, payoutAmount: 0, payoutRefundAmount: 0 }
    );

  const refundedAmountInCents =
    ecomOrder?.ecomOrderPayments
      .filter(
        (p) =>
          p.fkRegFormSubmissionPaymentType.toString() ===
          PAYMENT_TYPE_ENUM.CREDIT
      )
      .reduce((r, c) => r + c.amount, 0) || 0;
  const availableRefundAmount = totals.paid - refundedAmountInCents;

  return {
    selectedItemCount: selectedProductsSummary.itemCount,
    selectedItemsTotalUnitCost: selectedProductsSummary.unitCost,
    selectedItemsRefundValue: selectedProductsSummary.refundValue,
    amountPaid: totals.paid,
    hasRefundedAllItems,
    availableRefundAmount,
    refundedAmountInCents,
    maxRefundAmountWithoutLosses: availableRefundAmount
  };
};

const getEcomStoreProductStatus = (product) =>
  product.ecomVendorProduct?.deleted ||
  product.ecomVendorProduct.fkEcomProductStatus.toString() !==
    ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ? {
        label: 'Not available',
        color: 'red'
      }
    : product.fkEcomProductStatus.toString() === ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ? {
        label: 'Live',
        color: 'green'
      }
    : product.fkEcomProductStatus.toString() === ECOM_PRODUCT_STATUS_ENUM.DRAFT
    ? {
        label: 'Draft',
        color: 'darkgrey'
      }
    : {
        label: 'Pending action',
        color: 'blue'
      };

const getEcomVendorProductStatus = (product) =>
  product.fkEcomProductStatus.toString() === ECOM_PRODUCT_STATUS_ENUM.ACTIVE
    ? {
        label: 'Active',
        color: 'green'
      }
    : product.fkEcomProductStatus.toString() ===
      ECOM_PRODUCT_STATUS_ENUM.ARCHIVED
    ? {
        label: 'Archived',
        color: 'grey'
      }
    : {
        label: 'Draft',
        color: 'grey'
      };

export {
  generateTrackingLink,
  translateVendorOrderData,
  translateVendorOrderProductData,
  calculateVendorOrderRefundValues,
  getEcomStoreProductStatus,
  getEcomVendorProductStatus
};
