import React, { useContext, useEffect, useRef } from 'react';
import HeaderView from '../../common/HeaderView';
import { Context as AdminContext } from '../../../providers/AdminContextProvider';
import { adminAnalyticsAndReportsConfig } from '../analytics/adminAnalyticsAndReportsConfig';
import AnalyticsOverviewView from '../analytics/AnalyticsOverviewView';
import { ECOM_REPORT_ENUM } from '../analytics/reportsConfig';
import { currencyFormat } from '../../../helpers/format';

const AdminAnalyticsView = () => {
  const hasFetched = useRef(false);
  const {
    state,
    generateAdminReport,
    fetchAdminAnalytics,
    fetchAdminAnalyticsOverview,
    fetchAdminResourceOptions
  } = useContext(AdminContext);

  const reportConfig = adminAnalyticsAndReportsConfig.reports.find(
    (c) => c.uuid === ECOM_REPORT_ENUM.OVERVIEW
  );

  const loading =
    !hasFetched.current ||
    state.ecomAdminAnalytics.loading ||
    state.ecomAdminReport.loading ||
    reportConfig.uuid !== state.ecomAdminReport.value?.uuid;

  const summaryCardsLoading =
    !hasFetched.current || state.ecomAdminAnalyticsOverview.loading;

  useEffect(() => {
    fetchAdminAnalyticsOverview();

    if (!state.ecomResourceOptions.value) {
      fetchAdminResourceOptions();
    }
  }, []);

  const onFetchAnalyticsOverview = (filter) => {
    const groupByPeriod = reportConfig.groupBy === 'period';
    const compareEnabled = groupByPeriod && reportConfig.compareEnabled;
    const pkEcomStore = filter.viewType === 'store' ? filter.view : null;
    const pkEcomVendor = filter.viewType === 'vendor' ? filter.view : null;
    generateAdminReport({
      uuid: reportConfig.uuid,
      headers: reportConfig.defaultHeaders,
      groupBy: reportConfig.groupBy,
      ...filter,
      periodInterval: groupByPeriod ? filter.periodInterval : null,
      compareStartDate: compareEnabled ? filter.compareStartDate : null,
      compareEndDate: compareEnabled ? filter.compareEndDate : null,
      pkEcomStore,
      pkEcomVendor
    });

    fetchAdminAnalytics({
      ...filter,
      pkEcomStore,
      pkEcomVendor
    });
    hasFetched.current = true;
  };

  return (
    <HeaderView noHeader>
      <AnalyticsOverviewView
        analyticConfigs={adminAnalyticsAndReportsConfig.analytics}
        dataState={state.ecomAdminAnalytics.value}
        entityId={1}
        isAdminView
        loading={loading}
        onFetchOverview={onFetchAnalyticsOverview}
        reportResult={state.ecomAdminReport.value}
        resourcesLoading={state.ecomResourceOptions.loading}
        summaryCardData={[
          {
            label: 'Escrow balance',
            count: state.ecomAdminAnalyticsOverview.value?.ecomStores.filter(
              (v) => v.escrowBalance > 0
            ).length,
            value: state.ecomAdminAnalyticsOverview.value
              ? currencyFormat(
                  state.ecomAdminAnalyticsOverview.value.ecomStores.reduce(
                    (r, c) => r + c.escrowBalance,
                    0
                  ) / 100
                )
              : 0,
            loading: summaryCardsLoading,
            redirectPath: '/admin/stores'
          },
          {
            label: 'Total escrow earned',
            count: state.ecomAdminAnalyticsOverview.value?.ecomStores.length,
            value: state.ecomAdminAnalyticsOverview.value
              ? currencyFormat(
                  state.ecomAdminAnalyticsOverview.value.ecomStores.reduce(
                    (r, c) => r + c.escrowEarned,
                    0
                  ) / 100
                )
              : 0,
            loading: summaryCardsLoading,
            redirectPath: '/admin/stores'
          },
          {
            label: 'Vendor payout balance',
            count: state.ecomAdminAnalyticsOverview.value?.ecomVendors.filter(
              (v) => v.payoutBalance + v.payoutLocked > 0
            ).length,
            value: state.ecomAdminAnalyticsOverview.value
              ? currencyFormat(
                  state.ecomAdminAnalyticsOverview.value.ecomVendors.reduce(
                    (r, c) => r + c.payoutBalance + c.payoutLocked,
                    0
                  ) / 100
                )
              : 0,
            loading: summaryCardsLoading,
            redirectPath: '/admin/vendors/payout-balance?from=analytics'
          },
          {
            label: 'Locked payout balance',
            count: state.ecomAdminAnalyticsOverview.value?.ecomVendors.filter(
              (v) => v.payoutLocked > 0
            ).length,
            value: state.ecomAdminAnalyticsOverview.value
              ? currencyFormat(
                  state.ecomAdminAnalyticsOverview.value.ecomVendors.reduce(
                    (r, c) => r + c.payoutLocked,
                    0
                  ) / 100
                )
              : 0,
            loading: summaryCardsLoading,
            redirectPath: '/admin/vendors/payout-balance?from=analytics',
            tooltip:
              "Payouts may be locked when group orders are in the 'accumulating' stage."
          }
        ]}
        urlBase="/admin/reports"
        viewAsOptions={
          state.ecomResourceOptions.value
            ? [
                {
                  groupValue: 'store',
                  groupLabel: 'Store',
                  options: state.ecomResourceOptions.value.ecomStores
                    .map((s) => ({
                      label: s.name,
                      image: s.logoImageUrl,
                      value: s.pkEcomStore.toString()
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                },
                {
                  groupValue: 'vendor',
                  groupLabel: 'Vendor',
                  options: state.ecomResourceOptions.value.ecomVendors
                    .map((s) => ({
                      label: s.name,
                      image: s.logoImageUrl,
                      value: s.pkEcomVendor.toString()
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                }
              ]
            : []
        }
      />
    </HeaderView>
  );
};

export default AdminAnalyticsView;
