import React, { useContext, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircleCheck, CircleX } from 'tabler-icons-react';
import { Button, Divider } from '@mantine/core';
import { Context as AuthContext } from '../../../providers/AuthContextProvider';
import { Context as StoreUserContext } from '../../../providers/StoreUserContextProvider';
import StoreOrderPurchaseCreateView from './StoreOrderPurchaseCreateView';
import LoadableView from '../../common/LoadableView';
import { useCartController } from '../../../helpers/webstoreCheckoutHelper';
import AppCard from '../../common/AppCard';
import AppStack from '../../common/AppStack';
import AppFlexbox from '../../common/AppFlexbox';
import AppText from '../../common/AppText';
import { ECOM_ORDER_STATUS_ENUM } from '../../../config/constants';
import { singularPluralFormat } from '../../../helpers/format';
import { translateStoreOrderData } from '../../../helpers/storeHelper';

const StoreOrderPurchaseViews = ({ loading, ecomOrder }) => {
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const { pkEcomOrder } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const secureId = searchParams.get('secureId');
  const paymentStatus = searchParams.get('status');
  const { state: authState } = useContext(AuthContext);
  const { clearCartBySecureId } = useCartController({
    isAdminCheckout: true,
    pkEcomOrder
  });
  const { state, fetchEcomStoreCartCheckoutSession } = useContext(
    StoreUserContext
  );
  const checkoutSession = state.ecomStoreCartCheckoutSession.value;
  const isAccumulating =
    ecomOrder?.fkEcomOrderStatus.toString() ===
    ECOM_ORDER_STATUS_ENUM.ACCUMULATING;

  const { groupedEcomOrderProducts } = translateStoreOrderData(ecomOrder);
  const hasReachedEligibility =
    (groupedEcomOrderProducts[0]?.minimumAmountReachedCount || 0) > 0;
  const remainingAmount =
    groupedEcomOrderProducts[0]?.minimumAmountRequiredRemaining || 0;

  useEffect(() => {
    if (authState.pkEcomStore && secureId) {
      if (paymentStatus === 'success') {
        fetchEcomStoreCartCheckoutSession(authState.pkEcomStore, { secureId });
      }
      hasFetched.current = true;
    }
  }, [authState.pkEcomStore, secureId]);

  useEffect(() => {
    if (checkoutSession?.completed) {
      clearCartBySecureId(authState.pkEcomStore, checkoutSession.secureId);
    }
  }, [checkoutSession]);

  useEffect(() => {
    if (ecomOrder && !isAccumulating && !secureId) {
      navigate(`/merchant/group-orders/${pkEcomOrder}`);
    }
  }, [ecomOrder]);

  return secureId ? (
    <LoadableView
      isLoading={
        !hasFetched.current ||
        loading ||
        state.ecomStoreCartCheckoutSession.loading
      }
    >
      <AppStack
        p={{ base: 8, sm: '16px 16px 80px 16px' }}
        style={{
          flex: 1,
          margin: 'auto',
          width: '100%',
          maxWidth: 600,
          paddingTop: 16,
          gap: 16,
          alignItems: 'center'
        }}
      >
        <AppCard
          radius="md"
          shadow="lg"
          style={{ padding: 20, maxWidth: 400, marginTop: 40 }}
          withBorder
        >
          {checkoutSession?.completed ? (
            <AppStack style={{ flex: 1, gap: 8 }}>
              <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
                <CircleCheck color="#067D62" size={150} />
              </AppFlexbox>
              <AppStack style={{ gap: 20 }}>
                <AppStack style={{ gap: 8, textAlign: 'center' }}>
                  <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                    Thank you!
                  </AppText>
                  <AppText style={{ fontSize: 16, color: '#666' }}>
                    A confirmation email has been sent to your email.
                  </AppText>
                </AppStack>

                {isAccumulating && (
                  <>
                    <AppStack style={{ gap: 5 }}>
                      <Button
                        color="dark"
                        component={!hasReachedEligibility ? null : Link}
                        disabled={!hasReachedEligibility}
                        to={`/merchant/group-orders/${pkEcomOrder}?modal=submit_order`}
                        variant="outline"
                      >
                        Submit group order
                      </Button>
                      {!hasReachedEligibility && (
                        <AppText
                          style={{
                            textAlign: 'center',
                            color: '#C40000',
                            fontSize: 14
                          }}
                        >
                          {singularPluralFormat(
                            remainingAmount,
                            'more item',
                            'more items'
                          )}{' '}
                          required to submit
                        </AppText>
                      )}
                    </AppStack>

                    <Divider label="OR" labelPosition="center" />
                  </>
                )}

                <Button
                  color="dark"
                  component={Link}
                  to={`/merchant/group-orders/${pkEcomOrder}`}
                  variant="filled"
                >
                  View group order
                </Button>
              </AppStack>
            </AppStack>
          ) : (
            <AppStack style={{ flex: 1, gap: 8 }}>
              <AppFlexbox style={{ flex: 1, justifyContent: 'center' }}>
                <CircleX color="#C40000" size={150} />
              </AppFlexbox>
              <AppStack style={{ gap: 20 }}>
                <AppStack style={{ gap: 8, textAlign: 'center' }}>
                  <AppText style={{ fontSize: 24, fontWeight: 700 }}>
                    Transaction cancelled
                  </AppText>
                  <AppText style={{ fontSize: 16, color: '#666' }}>
                    Your transaction has been successfully cancelled, and no
                    charges have been made to your account.
                  </AppText>
                </AppStack>

                <Button
                  color="dark"
                  component={Link}
                  to={`/merchant/group-orders/${pkEcomOrder}/purchase`}
                  variant="filled"
                >
                  Back to order
                </Button>
              </AppStack>
            </AppStack>
          )}
        </AppCard>
      </AppStack>
    </LoadableView>
  ) : (
    <StoreOrderPurchaseCreateView ecomOrder={ecomOrder} loading={loading} />
  );
};

StoreOrderPurchaseViews.propTypes = {
  ecomOrder: PropTypes.object,
  loading: PropTypes.bool
};

export default StoreOrderPurchaseViews;
