import React from 'react';
import PropTypes from 'prop-types';
import { Divider, NumberFormatter, Tooltip } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import AppFlexbox from '../../common/AppFlexbox';
import { singularPluralFormat } from '../../../helpers/format';

const OrderCancelProductSummary = ({
  title,
  description,
  selectedItemCount,
  selectedItemsTotalUnitCost,
  selectedItemsStoreProfit,
  selectedItemsRefundValue,
  platformFee,
  isFullyCancelled,
  isVendorView
}) => (
  <AppStack style={{ gap: 0 }}>
    <AppStack style={{ flex: 1, padding: 16, gap: 16 }}>
      {title && (
        <AppFlexbox
          style={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <AppText style={{ fontSize: 16, fontWeight: 500 }}>{title}</AppText>
        </AppFlexbox>
      )}

      {description && (
        <AppText style={{ fontSize: 14, color: '#666', textAlign: 'center' }}>
          {description}
        </AppText>
      )}

      {selectedItemCount === 0 && isFullyCancelled ? (
        <AppStack style={{ gap: 5 }}>
          <AppText style={{ fontSize: 14, color: '#666' }}>
            All products have already been cancelled.
          </AppText>
        </AppStack>
      ) : selectedItemCount === 0 ? (
        <AppStack style={{ gap: 5 }}>
          <AppText style={{ fontSize: 14 }}>No products selected.</AppText>
          {isVendorView ? (
            <AppText style={{ fontSize: 14, color: '#666' }}>
              Select products to cancel them.
            </AppText>
          ) : (
            <AppText style={{ fontSize: 14, color: '#666' }}>
              Select products to cancel them and free up funds from vendors.
            </AppText>
          )}
        </AppStack>
      ) : (
        <AppStack style={{ gap: 5 }}>
          <AppFlexbox style={{ justifyContent: 'space-between' }}>
            <AppStack style={{ gap: 0 }}>
              <AppText style={{ fontSize: 14 }}>
                Subtotal{' '}
                <span style={{ color: '#666' }}>
                  ({singularPluralFormat(selectedItemCount, 'item', 'items')})
                </span>
              </AppText>
            </AppStack>
            <NumberFormatter
              decimalScale={2}
              displayType="text"
              fixedDecimalScale
              prefix="$"
              thousandSeparator
              value={selectedItemsTotalUnitCost / 100}
            />
          </AppFlexbox>
          {isVendorView ? (
            <></>
          ) : (
            <>
              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Store profit
                </AppText>

                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  style={{ color: '#666' }}
                  thousandSeparator
                  value={(selectedItemsStoreProfit * -1) / 100}
                />
              </AppFlexbox>

              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <AppText style={{ fontSize: 14, color: '#666' }}>
                  Stripe fees
                </AppText>

                <NumberFormatter
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="$"
                  style={{ color: '#666' }}
                  thousandSeparator
                  value={
                    ((selectedItemsTotalUnitCost -
                      selectedItemsStoreProfit -
                      selectedItemsRefundValue) *
                      -1) /
                    100
                  }
                />
              </AppFlexbox>

              {platformFee > 0 && (
                <AppFlexbox style={{ justifyContent: 'space-between' }}>
                  <Tooltip
                    label="Adjusted to account for Stripe's $0.30 flat rate fee and any rounding-related fee adjustments."
                    multiline
                    w={250}
                    withArrow
                  >
                    <AppFlexbox style={{ gap: 5, alignItems: 'center' }}>
                      <AppText style={{ fontSize: 14, color: '#666' }}>
                        Platform fee
                      </AppText>
                      <InfoCircle color="dodgerblue" size={18} />
                    </AppFlexbox>
                  </Tooltip>

                  <NumberFormatter
                    decimalScale={2}
                    displayType="text"
                    fixedDecimalScale
                    prefix="+$"
                    style={{ color: '#666' }}
                    thousandSeparator
                    value={platformFee / 100}
                  />
                </AppFlexbox>
              )}

              <Divider />

              <AppFlexbox style={{ justifyContent: 'space-between' }}>
                <AppText style={{ fontSize: 14, fontWeight: 500 }}>
                  Fulfillment refund
                </AppText>

                <NumberFormatter
                  color="#067D62"
                  decimalScale={2}
                  displayType="text"
                  fixedDecimalScale
                  prefix="+$"
                  style={{ color: '#067D62' }}
                  thousandSeparator
                  value={(selectedItemsRefundValue + (platformFee || 0)) / 100}
                />
              </AppFlexbox>
            </>
          )}
        </AppStack>
      )}
    </AppStack>
  </AppStack>
);

OrderCancelProductSummary.propTypes = {
  description: PropTypes.string,
  isFullyCancelled: PropTypes.bool,
  isVendorView: PropTypes.bool,
  platformFee: PropTypes.number,
  selectedItemCount: PropTypes.number,
  selectedItemsRefundValue: PropTypes.number,
  selectedItemsStoreProfit: PropTypes.number,
  selectedItemsTotalUnitCost: PropTypes.number,
  title: PropTypes.string
};

export default OrderCancelProductSummary;
