import dayjs from 'dayjs';
import roundHalfEven from 'round-half-even';

const getInitialsFromName = (name) => {
  let initials = '';
  if (name?.length > 0) {
    const splitName = name.replace(/\s+/g, ' ').trim().split(' ');
    initials += splitName[0][0];
    if (splitName.length > 1) {
      initials += splitName[splitName.length - 1][0];
    }
  }
  return initials;
};

const isDarkText = (backgroundColor) => {
  if (!backgroundColor) {
    return false;
  }

  let color = backgroundColor.substring(1, backgroundColor.length);
  if (color.length === 3) {
    color += color;
  }

  const red = parseInt(color.substring(0, 2), 16);
  const green = parseInt(color.substring(2, 4), 16);
  const blue = parseInt(color.substring(4, 6), 16);
  return red * 0.299 + green * 0.587 + blue * 0.114 > 186;
};

const currencyFormat = (num) => {
  if (!Number.isNaN(parseFloat(num))) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    return formatter.format(roundHalfEven(parseFloat(num), 2));
  }
  return '';
};

const formatUtcDate = (date) =>
  !date || typeof date === 'object' || date?.split(' ').length > 2
    ? date
    : `${date.charAt(date.length - 1) === 'Z' ? date : `${date}Z`}`;

const formatUrl = (url) =>
  url.charAt(url.length - 1) === '/' ? url.substring(0, url.length - 1) : url;

const toTitleCase = (str) =>
  str?.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

const singularPluralFormat = (value, singular, plural) =>
  `${value} ${value === 1 ? singular : plural}`;

const changeListItemSortOrder = (
  list,
  startIndex,
  endIndex,
  dataSelector = null
) => {
  if (startIndex === endIndex) {
    return list;
  }

  const selector = dataSelector || 'sort';
  const isGreaterIndex = endIndex > startIndex;

  const listCopy = [...list];
  for (const element of listCopy) {
    if (element[selector] === startIndex) {
      element[selector] = endIndex;
    }
    else if (
      isGreaterIndex &&
      element[selector] >= startIndex &&
      element[selector] <= endIndex
    ) {
      element[selector] -= 1;
    }
    else if (element[selector] <= startIndex && element[selector] >= endIndex) {
      element[selector] += 1;
    }
  }

  return listCopy;
};

const roundUpToCentValue = (amount, centValue) => {
  if (centValue >= 0) {
    return Math.ceil(amount - centValue) + centValue;
  }
  return amount;
};

const sterilizeUrlHandle = (str) =>
  str
    ?.trim()
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^a-zA-Z0-9-_]/g, '')
    .replace(/(-)\1+/g, '-') ?? '';

const getDateRangeLabel = (start, end) =>
  start && end
    ? dayjs(start).year() === dayjs(end).year()
      ? `${dayjs(start).format('MMM DD')} - ${dayjs(end).format(
          'MMM DD, YYYY'
        )}`
      : `${dayjs(start).format('MMM DD, YYYY')} - ${dayjs(end).format(
          'MMM DD, YYYY'
        )}`
    : null;

const createGoogleMapsLink = ({
  address1,
  city,
  state,
  postalCode,
  country
}) => {
  let address = `${address1}`;
  address += `, ${city}, ${state} ${postalCode}, ${country}`;
  const formattedAddress = address.replace(/\s+/g, '+');
  const googleMapsLink = `https://www.google.com/maps/place/${formattedAddress}`;

  return googleMapsLink;
};

const isUrlString = (inputString) =>
  /^(https?:\/\/[^\s$.?#].[^\s]*)$/.test(inputString);

const formatDataTypeString = (value, dataType) => {
  if (value || value === 0) {
    switch (dataType) {
      case 'currency':
        return currencyFormat(value / 100);
      case 'percentage':
        return `${Number(value)?.toFixed(2)}%`;
      case 'date':
        if (value.includes('-')) {
          return value;
        }
        return dayjs(value).format('MMM DD, YYYY');
      case 'number':
      default:
        return value;
    }
  }
  return '-';
};

const getDateOnly = (date) => {
  if (!date) {
    return null;
  }
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

export {
  getInitialsFromName,
  isDarkText,
  currencyFormat,
  formatUtcDate,
  formatUrl,
  toTitleCase,
  singularPluralFormat,
  changeListItemSortOrder,
  roundUpToCentValue,
  sterilizeUrlHandle,
  getDateRangeLabel,
  createGoogleMapsLink,
  isUrlString,
  formatDataTypeString,
  getDateOnly
};
