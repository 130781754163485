import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Anchor,
  Badge,
  Button,
  ColorSwatch,
  Grid,
  Image,
  NumberFormatter,
  NumberInput,
  Select
} from '@mantine/core';
import { AlertCircle, ArrowRight } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import AppFlexbox from '../../common/AppFlexbox';
import AppStack from '../../common/AppStack';
import AppText from '../../common/AppText';
import { INPUT_CONTROL_TYPE_ENUM } from '../../../config/constants';
import ProductInputDisplay from './ProductInputDisplay';
import { getVariantOptions } from '../../../helpers/webstoreHelper';
import AppCard from '../../common/AppCard';

const SelectProductOptionsForm = ({
  isOpen,
  ecomStoreProduct,
  productInCartCount,
  productUrl,
  baseStoreUrl,
  mediaQueries,
  onNavigate,
  onAddProduct,
  formState,
  setFormState,
  ecomStoreCartProduct,
  children
}) => {
  const previewImage = ecomStoreProduct?.ecomVendorProduct.ecomVendorProductMedia.sort(
    (a, b) => a.sort - b.sort
  )[0]?.src;
  const { isTabletOrSmaller } = mediaQueries;

  const selectedVariant =
    ecomStoreCartProduct?.ecomStoreProductVariant ||
    ecomStoreProduct?.ecomStoreProductVariants.find(
      (v) =>
        formState.selectedOptions.length ===
          v.ecomVendorProductVariant.ecomVendorProductVariantOptions.length &&
        v.ecomVendorProductVariant.ecomVendorProductVariantOptions.every((op) =>
          formState.selectedOptions.some(
            (f) => f.name === op.name && f.value === op.value
          )
        )
    );
  const variantOptions = getVariantOptions(ecomStoreProduct);
  const quantityLeft = selectedVariant
    ? selectedVariant.ecomVendorProductVariant.quantity
    : null;

  const isUnavailable =
    ecomStoreProduct?.deleted ||
    ecomStoreProduct?.ecomVendorProduct.deleted ||
    selectedVariant?.deleted ||
    selectedVariant?.unavailable ||
    selectedVariant?.ecomVendorProductVariant.deleted;
  const isOutOfStock = quantityLeft !== null && quantityLeft <= 0;

  const maxQuantity = selectedVariant?.ecomVendorProductVariant.quantity
    ? selectedVariant.ecomVendorProductVariant.quantity -
      (productInCartCount ?? 0)
    : null;

  const firstSelectedOption = formState.selectedOptions.find(
    (op) => op.nameSort === 1
  );
  const secondSelectedOption = formState.selectedOptions.find(
    (op) => op.nameSort === 2
  );

  const secondVariantOptions =
    ecomStoreProduct?.ecomStoreProductVariants.filter((v) =>
      v.ecomVendorProductVariant.ecomVendorProductVariantOptions.some(
        (s) =>
          s.nameSort === firstSelectedOption?.nameSort &&
          s.name === firstSelectedOption?.name &&
          s.value === firstSelectedOption?.value
      )
    ) ?? [];
  const thirdVariantOptions =
    ecomStoreProduct?.ecomStoreProductVariants.filter((v) =>
      v.ecomVendorProductVariant.ecomVendorProductVariantOptions.some(
        (s) =>
          s.nameSort === secondSelectedOption?.nameSort &&
          s.name === secondSelectedOption?.name &&
          s.value === secondSelectedOption?.value
      )
    ) ?? [];

  useEffect(() => {
    if (isOpen && ecomStoreProduct) {
      setFormState({
        selectedOptions: variantOptions.map((op) => ({
          nameSort: op.sort,
          name: op.name,
          value: op.values[0].value
        })),
        ecomStoreCartProductInputAnswers:
          ecomStoreCartProduct?.ecomStoreCartProductInputAnswers.map((a) => ({
            ...a,
            isAutopopulated: ecomStoreProduct?.ecomStoreProductInputAnswers.some(
              (is) =>
                is.fkRegFormControlType === a.fkRegFormControlType &&
                (is.fkEcomVendorProductInput === a.fkEcomVendorProductInput ||
                  is.label.toLowerCase() === a.label.toLowerCase())
            )
          })) ??
          ecomStoreProduct.ecomStoreProductInputAnswers
            .map((a) => {
              const foundInput = ecomStoreProduct.ecomVendorProduct.ecomVendorProductInputs.find(
                (i) =>
                  i.fkRegFormControlType === a.fkRegFormControlType &&
                  (i.pkEcomVendorProductInput === a.fkEcomVendorProductInput ||
                    i.label.toLowerCase() === a.label.toLowerCase())
              );
              return foundInput
                ? {
                    isAutopopulated: true,
                    fkEcomVendorProductInput:
                      foundInput.pkEcomVendorProductInput,
                    fkRegFormControlType: foundInput.fkRegFormControlType,
                    file: null,
                    value: a.value
                  }
                : null;
            })
            .filter((f) => !!f),
        count: 1,
        optionErrors: []
      });
    }
  }, [isOpen]);

  return (
    <AppFlexbox
      style={{
        position: 'relative',
        flexDirection: isTabletOrSmaller ? 'column' : 'row'
      }}
    >
      <AppStack
        style={{
          width: '100%',
          maxWidth: isTabletOrSmaller ? 'unset' : '45%'
        }}
      >
        <AppStack
          style={{
            height: isTabletOrSmaller ? 250 : 'unset',
            width: '100%'
          }}
        >
          <Image
            fit="contain"
            h="100%"
            src={
              selectedVariant?.ecomVendorProductVariant.previewImage ??
              previewImage
            }
            w="100%"
          />
        </AppStack>
      </AppStack>
      <AppStack
        component={onAddProduct ? 'form' : null}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onAddProduct();
        }}
        style={{
          width: '100%',
          gap: 16,
          marginTop: isTabletOrSmaller ? 0 : 20,
          marginLeft: isTabletOrSmaller ? 0 : 40
        }}
      >
        <AppStack style={{ gap: 10 }}>
          {quantityLeft !== null && selectedVariant && (
            <AppFlexbox style={{ flex: 1, gap: 8, alignItems: 'center' }}>
              <ColorSwatch
                color={quantityLeft <= 25 ? '#eca70a' : '#067D62'}
                size={18}
              />
              <AppText style={{ fontSize: 16 }}>
                {quantityLeft <= 0
                  ? 'Out of stock'
                  : quantityLeft <= 25
                  ? `Low stock`
                  : `In stock`}
              </AppText>
            </AppFlexbox>
          )}

          <AppStack style={{ gap: 0 }}>
            <AppText style={{ fontSize: 14, color: '#999' }}>
              {ecomStoreProduct.ecomVendorProduct.vendorName}
            </AppText>

            <Anchor
              component={Link}
              onClick={onNavigate}
              style={{ color: '#000', fontSize: 28, fontWeight: 500 }}
              to={productUrl}
            >
              {ecomStoreProduct.ecomVendorProduct.name}
            </Anchor>
          </AppStack>

          {selectedVariant && (
            <AppFlexbox style={{ alignItems: 'end', gap: 10 }}>
              {selectedVariant.compareAtPrice > 0 && (
                <AppText
                  style={{
                    fontSize: 18,
                    fontWeight: 450,
                    color: '#999',
                    textDecoration: 'line-through'
                  }}
                >
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    suffix=" CAD"
                    thousandSeparator
                    value={selectedVariant.compareAtPrice / 100}
                  />
                </AppText>
              )}
              <AppFlexbox style={{ alignItems: 'center', gap: 10 }}>
                <AppText style={{ fontSize: 22, fontWeight: 450 }}>
                  <NumberFormatter
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                    suffix=" CAD"
                    thousandSeparator
                    value={selectedVariant.price / 100}
                  />
                </AppText>
                {selectedVariant.compareAtPrice > 0 && (
                  <Badge color="dark" size="lg" variant="filled">
                    Sale
                  </Badge>
                )}
              </AppFlexbox>
            </AppFlexbox>
          )}
        </AppStack>

        <AppStack style={{ gap: 10 }}>
          <Grid gutter="xs">
            {!ecomStoreCartProduct &&
              variantOptions.map((op) => (
                <Grid.Col
                  key={op.name}
                  span={isTabletOrSmaller ? 12 : op.values.length > 10 ? 6 : 12}
                  style={{ alignSelf: 'end' }}
                >
                  {op.values.length > 10 ? (
                    <Select
                      data={op.values
                        .sort((a, b) => a.sort - b.sort)
                        .map((v) => ({
                          label: v.value,
                          value: v.value
                        }))}
                      disabled={formState.loading}
                      label={op.name}
                      onChange={(value) =>
                        setFormState({
                          ...formState,
                          optionErrors: [],
                          selectedOptions: [
                            ...formState.selectedOptions.filter(
                              (f) => f.name !== op.name
                            ),
                            { nameSort: op.sort, name: op.name, value }
                          ]
                        })
                      }
                      radius={0}
                      value={
                        formState.selectedOptions.find(
                          (f) => f.name === op.name
                        )?.value
                      }
                    />
                  ) : (
                    <AppStack style={{ gap: 5 }}>
                      <AppText
                        style={{
                          fontSize: 14,
                          fontWeight: 500
                        }}
                      >
                        {op.name}
                      </AppText>
                      <AppFlexbox
                        style={{ gap: 8, flexWrap: 'wrap' }}
                        variant="outline"
                      >
                        {op.values
                          .sort((a, b) => a.sort - b.sort)
                          .map((v) => {
                            const isSelected = formState.selectedOptions.some(
                              (f) => f.name === op.name && f.value === v.value
                            );

                            const disabled =
                              op.sort === 2
                                ? !secondVariantOptions.some((s) =>
                                    s.ecomVendorProductVariant.ecomVendorProductVariantOptions.some(
                                      (so) =>
                                        so.nameSort === op.sort &&
                                        so.name === op.name &&
                                        so.value === v.value
                                    )
                                  )
                                : op.sort === 3 &&
                                  !thirdVariantOptions.some((s) =>
                                    s.ecomVendorProductVariant.ecomVendorProductVariantOptions.some(
                                      (so) =>
                                        so.nameSort === op.sort &&
                                        so.name === op.name &&
                                        so.value === v.value
                                    )
                                  );

                            return (
                              <Button
                                key={v.value}
                                color={isSelected ? 'dark' : 'gray'}
                                disabled={formState.loading || disabled}
                                onClick={() => {
                                  setFormState({
                                    ...formState,
                                    optionErrors: [],
                                    selectedOptions: [
                                      ...formState.selectedOptions.filter(
                                        (f) => f.name !== op.name
                                      ),
                                      {
                                        nameSort: op.sort,
                                        name: op.name,
                                        value: v.value
                                      }
                                    ]
                                  });
                                }}
                                radius={100}
                                size="compact-md"
                                style={{ fontSize: 12, minWidth: 25 }}
                                variant={isSelected ? 'filled' : 'outline'}
                              >
                                {v.value}
                              </Button>
                            );
                          })}
                      </AppFlexbox>
                    </AppStack>
                  )}
                </Grid.Col>
              ))}

            {ecomStoreProduct.ecomVendorProduct.ecomVendorProductInputs
              .sort(
                (a, b) =>
                  a.sort - b.sort &&
                  (a.ecomVendorProductVariants.length === 0 ||
                    a.ecomVendorProductVariants.some(
                      (pk) =>
                        pk ===
                        selectedVariant?.ecomVendorProductVariant
                          .pkEcomVendorProductVariant
                    ))
              )
              .map((p) => {
                const inputAnswer = formState.ecomStoreCartProductInputAnswers.find(
                  (a) =>
                    a.fkEcomVendorProductInput === p.pkEcomVendorProductInput
                );

                return (
                  <Grid.Col
                    key={p.pkEcomVendorProductInput}
                    span={{
                      base: 12,
                      sm:
                        p.fkRegFormControlType ===
                          INPUT_CONTROL_TYPE_ENUM.CHECKBOX ||
                        p.fkRegFormControlType ===
                          INPUT_CONTROL_TYPE_ENUM.FILE_UPLOAD
                          ? 12
                          : 6
                    }}
                    style={{
                      alignSelf: 'end',
                      marginTop:
                        p.fkRegFormControlType ===
                        INPUT_CONTROL_TYPE_ENUM.CHECKBOX
                          ? 5
                          : 0
                    }}
                  >
                    <ProductInputDisplay
                      key={p.pkEcomVendorProductInput}
                      baseStoreUrl={baseStoreUrl}
                      disabled={
                        inputAnswer?.isAutopopulated || formState.loading
                      }
                      ecomVendorProductInput={p}
                      onChange={(value, type) =>
                        setFormState({
                          ...formState,
                          optionErrors: [],
                          ecomStoreCartProductInputAnswers: [
                            ...formState.ecomStoreCartProductInputAnswers.filter(
                              (a) =>
                                a.fkEcomVendorProductInput !==
                                p.pkEcomVendorProductInput
                            ),
                            {
                              fkEcomVendorProductInput:
                                p.pkEcomVendorProductInput,
                              fkRegFormControlType: p.fkRegFormControlType,
                              file: type === 'file' ? value : null,
                              value: type === 'file' ? null : value
                            }
                          ]
                        })
                      }
                      onNavigate={onNavigate}
                      radius={0}
                      value={inputAnswer?.previewFile || inputAnswer?.value}
                    />
                  </Grid.Col>
                );
              })}
          </Grid>

          {!ecomStoreCartProduct && (
            <NumberInput
              disabled={formState.loading || isUnavailable || isOutOfStock}
              label={`Quantity ${
                productInCartCount ? `(${productInCartCount} in cart)` : ''
              }`}
              min={1}
              onChange={(value) => {
                let newQuantity =
                  quantityLeft !== null && value > quantityLeft
                    ? quantityLeft
                    : value;
                if (newQuantity <= 0) {
                  newQuantity = 1;
                }
                setFormState({
                  ...formState,
                  count: newQuantity,
                  optionErrors: []
                });
              }}
              radius={0}
              size="md"
              style={{ maxWidth: 200 }}
              styles={{
                label: { fontSize: 14, color: '#999', fontWeight: 'normal' }
              }}
              value={formState.count}
            />
          )}
        </AppStack>

        {formState.optionErrors && formState.optionErrors.length > 0 && (
          <AppCard
            radius={0}
            shadow="xs"
            style={{ padding: 0, marginTop: 10 }}
            withBorder
          >
            <AppFlexbox
              style={{
                padding: 8,
                gap: 8,
                backgroundColor: '#c40000',
                color: '#fff'
              }}
            >
              <AppStack>
                <AlertCircle size={18} />
              </AppStack>
              <AppText style={{ fontSize: 14 }}>
                {formState.optionErrors[0]}
              </AppText>
            </AppFlexbox>
          </AppCard>
        )}

        {onAddProduct && (
          <Button
            color="dark"
            disabled={isUnavailable || isOutOfStock || !selectedVariant}
            loading={formState.loading}
            onClick={() => {}}
            radius={0}
            size="lg"
            style={{ maxHeight: 46, marginTop: 5 }}
            type="submit"
            variant="outline"
          >
            {isOutOfStock
              ? 'Out of stock'
              : isUnavailable
              ? 'Unavailable'
              : 'Add to Cart'}
          </Button>
        )}

        <AppFlexbox style={{ cursor: 'pointer', gap: 8, alignItems: 'center' }}>
          <Anchor
            c="#000"
            component={Link}
            onClick={onNavigate}
            style={{ fontSize: 14 }}
            to={productUrl}
          >
            View full details
          </Anchor>
          <ArrowRight color="#000" size={16} />
        </AppFlexbox>

        {children}
      </AppStack>
    </AppFlexbox>
  );
};

SelectProductOptionsForm.propTypes = {
  baseStoreUrl: PropTypes.string,
  children: PropTypes.node,
  ecomStoreCartProduct: PropTypes.object,
  ecomStoreProduct: PropTypes.object,
  formState: PropTypes.object,
  isOpen: PropTypes.bool,
  mediaQueries: PropTypes.object,
  onAddProduct: PropTypes.func,
  onNavigate: PropTypes.func,
  productInCartCount: PropTypes.number,
  productUrl: PropTypes.string,
  setFormState: PropTypes.func
};

export default SelectProductOptionsForm;
